import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { CDSAccount } from '../../../models/cds/cds-account';
import { CDSApiService } from '../../../service/cds-api.service';

@Component({
  selector: 'app-cds-account-picker',
  templateUrl: './cds-account-picker.component.html',
  styleUrls: ['./cds-account-picker.component.scss']
})
export class CdsAccountPickerComponent implements OnInit {
  pickerControl = new FormControl();
  accountList: CDSAccount[];
  isLoading: boolean;
  accountIsLoading: boolean;
  selectedAccount: CDSAccount;

  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;
  
  @Input() label: string;
  @Input() value: string;

  constructor(private cdsApiService: CDSApiService) { }

  writeValue(stdAccountId: string): void {
    if(stdAccountId === null || stdAccountId === "")
      return;

    this.accountIsLoading = true;
    this.cdsApiService.getEntity('accounts',stdAccountId)
    .subscribe(
      (response:CDSAccount) => {
        this.selectedAccount = response;
        this.pickerControl.setValue(response);
      },
      (err: any) => console.error(err),
      () => this.accountIsLoading = false
    );
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setSelectdAccount(account: CDSAccount) {
    this.selectedAccount = account;
    this.onChange(account);
  }

  ngOnInit() {
    if(!!this.value) {
      this.writeValue(this.value);
      this.disabled = true;
    }

    

    this.pickerControl
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.cdsApiService.getEntitySearch('accounts','name',value)
        .pipe(
          finalize(() => this.isLoading = false),
        )
      )
    )
    .subscribe(
      response =>
      { 
        console.log(response.value); 
        return response.value
      }
    );
  }  
  
  displayFn(account: CDSAccount): string {
    return account && account.name ? account.name : '';
  }
}
