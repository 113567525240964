<div style="position: relative">
  <h1 mat-dialog-title>Projektplan erstellen</h1>
  <mat-icon style="position: absolute; top: 0px; right: 10px; cursor: pointer" color="primary" (click)="closeDialog()">
    close</mat-icon
  >
  <div mat-dialog-content *ngIf="!enableProgressSpinner">
    <mat-vertical-stepper #stepper>
      <mat-step [stepControl]="projectFormGroup">
        <ng-template matStepLabel>Ergänzende Informationen</ng-template>
        <!-- <h3>Bitte geben Sie eine Sollzeit der Montage an</h3> -->

        <form [formGroup]="projectFormGroup">
          <app-cds-contact-picker formControlName="_std_worker_projektleiter_stoebichid_value"
            >Projektleiter
          </app-cds-contact-picker>

          <mat-form-field class="full-width">
            <mat-label>Auftragsnummer</mat-label>
            <input
              matInput
              type="text"
              class="form-control"
              id="std_auftragsnummer"
              formControlName="std_auftragsnummer"
            />
          </mat-form-field>
          <!-- 
                <mat-slide-toggle style="margin: 12px 0" formControlName="generateProjectPlan">Projektplan anlegen?</mat-slide-toggle>       

                <mat-form-field class="full-width" *ngIf="projectFormGroup.value.generateProjectPlan">
                    <mat-label>Startdatum</mat-label>
                    <input matInput [matDatepicker]="picker" class="form-control" id="startDate" [required]="true"
                    formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker ></mat-datepicker>
                </mat-form-field> -->
        </form>

        <mat-divider></mat-divider>

        <div class="button-bar">
          <button mat-raised-button [disabled]="!projectFormGroup.valid" matStepperNext>Weiter</button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Absenden</ng-template>
        <!-- <pre>{{ data | json }}</pre> -->
        <table class="data-view-table">
          <tr>
            <td><strong>Auftragsnummer</strong></td>
            <td>{{ projectFormGroup.get('std_auftragsnummer').value }}</td>
          </tr>

          <tr>
            <td><strong>Verkaufschance</strong></td>
            <td>
              {{ cdsOpportunity.name | slice:0:87}} <br />
              <em>{{ cdsOpportunity?.std_verkaufschancen_kennung}}</em>
            </td>
          </tr>
          <!-- <tr>
                    <td><strong>Projektplan anlegen?</strong></td>
                    <td>
                        <span *ngIf="projectFormGroup.get('generateProjectPlan').value; else elseBlock">Ja</span>
                        <ng-template #elseBlock>Nein</ng-template>
                    </td>
                </tr>
                <tr *ngIf="projectFormGroup.get('generateProjectPlan').value">
                    <td><strong>Projektstart</strong></td>
                    <td>{{ projectFormGroup.get('startDate').value | date:'dd.MM.yyyy' }}</td>
                </tr> -->
        </table>
        <mat-divider></mat-divider>

        <div class="button-bar">
          <button mat-raised-button color="primary" (click)="btnGenerateProject()">Absenden</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
