import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CDSApiService } from '../../../service/cds-api.service';
    posFormGroup: FormGroup;
import { ActivatedRoute, Router } from '@angular/router';
import { SpsPos } from '../../../models/sps-pos';
import { ProjectApiService } from '../../../service/project-api.service';
import { CDSProject } from '../../../models/cds/cds-project';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { SpsPosType } from '../../../models/sps-pos-type';
import { CDSIncidentType } from '../../../models/cds/cds-incidenttype';
import { DialogGenerateWorkOrder } from '../position-list/dialog-generate-work-order.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SPSProject } from '../../../models/sps-project';
import { SpsApiResponse } from '../../../models/sps-api-response';

@Component({
  selector: 'app-position-detail',
  templateUrl: './position-detail.component.html',
  styleUrls: ['./position-detail.component.scss']
})
export class PositionDetailComponent implements OnInit {

  spsPos: SpsPos;
  cdsProject: CDSProject;
  spsProject: SPSProject;
  positionExternalLink: string;
  enableProgressBar: boolean;
  isNew: boolean;
  posFormGroup: FormGroup;
  spsPosTypeList: SpsPosType[];
  cdsIncidentTypeList: CDSIncidentType[];
  snackBarDuration: number = 3;

  constructor(
    private location: Location,
    private router: Router,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cdsApiService: CDSApiService,
    private snackBar: MatSnackBar,
    private projectApiService: ProjectApiService
  ) {
    this.spsPos = new SpsPos();
    this.cdsProject = new CDSProject();
    this.spsProject = new SPSProject();

    this.isNew = false;

    this.posFormGroup = this._formBuilder.group({
      posNumber: new FormControl('', Validators.required),
      //posNumberAlt: new FormControl(''),
      spsPosType: new FormControl('', Validators.required),
      cdsIncidentType: new FormControl('', Validators.required)
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.loadProjectProductTypeList();
    this.loadProjectPositionTypeList();

    if(!this.isNew) this.loadPosition();
  }

  btnClickNavigateBack() {
    this.location.back();
  }

  loadPosition(): void {
    this.route.paramMap.subscribe(params => {
      var id = params.get('id');
      this.projectApiService.getProjectPositionById(id)
      .subscribe(
        (response: SpsPos)   => {
          this.spsPos = response;
          this.loadProject();
        },
        (err: any) => console.error(err),
        () => {
          this.enableProgressBar = false;
          this.updateFormData();
        }
      )

      // this.positionExternalLink = this.cdsApiService.getExternalLink('std_auftrag',this.cdsProject.std_auftragid);

    });
  }

  loadProject(): void {
    this.projectApiService.getProjectById(this.spsPos.idSpsProject)
      .subscribe(
        (response: SPSProject) => {
          this.spsProject = response;

          this.cdsApiService.getEntity("std_auftrags",this.spsProject.idCDSStdAuftrag)
          .subscribe(
            (project: CDSProject) => {
              this.cdsProject = project
            },
            (err: any) => console.error(err)
          );
        },
        (err: any) => console.error(err)
      );
  }

  updateFormData() {
    this.posFormGroup.patchValue({
      posNumber: this.spsPos.posNumber,
      //posNumberAlt: this.spsPos.posNumberAlt,
      spsPosType: this.spsPos.spsPosType
    });
  }

    btnClickGenerateWorkOrder(pos: SpsPos): void {
    const dialogRef = this.dialog.open(DialogGenerateWorkOrder, {
      width: '500px',
      data: {
        pos: pos,
        project: this.spsProject
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  btnClickDeleteProjectPosition(): void {
    this.enableProgressBar = true;
    this.projectApiService.deleteProjectPosition(this.spsPos.id)
    .subscribe(
      (res: any) => {
        this.snackBar.open("gelöscht","ok",{
          duration: this.snackBarDuration * 1000,
        })
        this.router.navigate(['/sps/project', this.spsProject.idCDSStdAuftrag]);
      },
      (err: any) => console.error(err)
    );
  }

  btnClickSavePosition() {

  }

  loadProjectProductTypeList(): void {
    this.cdsApiService.getAllEntity('msdyn_incidenttypes?$select=msdyn_name&$filter=statuscode%20eq%201%20and%20_std_parentincidenttypeid_value%20ne%20null&$orderby=msdyn_name%20asc')
    .subscribe(
      (response: CDSApiResponse) =>
      {
        this.cdsIncidentTypeList = response.value // TK here to check msdyn_incidenttype and primaryIncident
        console.log(this.cdsIncidentTypeList);

      },
      (err: any) => console.error(err),
      () => this.enableProgressBar = false
    );
  }

  loadProjectPositionTypeList(): void {
    this.projectApiService.getProjectPositionType()
    .subscribe(
      (projectList: SpsApiResponse) => this.spsPosTypeList = projectList.value,
      (err: any) => console.error(err),
      () => this.enableProgressBar = false
    );
  }
}
