import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SpsPos } from '../../../models/sps-pos';
import { ProjectApiService } from '../../../service/project-api.service';
import { SPSProject } from '../../../models/sps-project';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogPositionDetail } from './dialog-position-detail.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogGenerateWorkOrder } from './dialog-generate-work-order.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CDSApiService } from '../../../service/cds-api.service';
import { forkJoin, Observable } from 'rxjs';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { ButtonConfirmComponent } from '../../helper/button-confirm/button-confirm.component';
import { SpsApiResponse } from '../../../models/sps-api-response';

@Component({
  selector: 'app-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class PositionListComponent implements OnInit {
  displayedColumns: string[] = [
    'posNumber',
    //'posNumberAlt',
    'idCDSIncidentTypeName',
    'progress'
  ];
  expandedElement: SpsPos | null;

  spsPosList = new MatTableDataSource<SpsPos>();
  selection = new SelectionModel<SpsPos>(true, []);
  snackBarDuration: number = 3;
  enableProgressBar: boolean;
  enableCalcProjectTimePlan: boolean;

  @Input() project: SPSProject;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  enableUpdateProjectPlan: boolean;

  constructor(
    private projectApiService: ProjectApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private cdsApiService: CDSApiService
  ) {
    this.enableProgressBar = false;
  }

  ngOnInit() {
    this.spsPosList.paginator = this.paginator;
    this.spsPosList.sort = this.sort;
    this.loadProjectPositionList();
  }

  btnClickAddProjectPosition(): void {
    const dialogRef = this.dialog.open(DialogPositionDetail, {
      width: '500px',
      data: {
          project: this.project,
          positions: this.spsPosList.data
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("===== Dialog closed ");
      this.enableCalcProjectTimePlan = true;

      console.log("===== Calc Timeplan ");
      this.projectApiService.calcProjectTimePlan(this.project.id).subscribe(
        (res: any) => {
          
          this.snackBar.open('Zeitplan erfolgreich berechnet', 'ok', {
            duration: this.snackBarDuration * 1000
          }
          );

          console.log("===== Calc Timeplan finished ");
          console.log("===== Refresh Positions ");
          this.loadProjectPositionList();
          console.log("===== Refresh Positions - finished ");
        },
        (err: any) => console.error(err),
        () => (this.enableCalcProjectTimePlan = false)
      );
    });
  }

  loadProjectPositionList(): void {
    console.log("===== loadProjectPositionList");
    this.enableProgressBar = true;
    if (this.project != undefined) {
      this.projectApiService.getProjectPositionByProject(this.project.id).subscribe(
        (projectList: SpsApiResponse) => {
          this.spsPosList.data = projectList.value;
        },
        (err: any) => console.error(err),
        () => (this.enableProgressBar = false)
      );
    } else {
      this.projectApiService.getProjectPosition().subscribe(
        (projectList: SpsApiResponse) => (this.spsPosList.data = projectList.value),
        (err: any) => console.error(err),
        () => (this.enableProgressBar = false)
      );
    }
  }

  applyFilter(filterValue: string) {
    this.spsPosList.filter = filterValue.trim().toLowerCase();
    if (this.spsPosList.paginator) {
      this.spsPosList.paginator.firstPage();
    }
  }

  btnClickDeleteProjectPosition(pos: SpsPos): void {
    this.enableProgressBar = true;
    this.projectApiService.deleteProjectPosition(pos.id).subscribe(
      (res: any) => {
        this.snackBar.open('gelöscht', 'ok', {
          duration: this.snackBarDuration * 1000
        });
        this.loadProjectPositionList();
      },
      (err: any) => console.error(err),
      () => (this.enableProgressBar = true)
    );
  }

  btnClickGenerateWorkOrder(pos: SpsPos): void {
    const dialogRef = this.dialog.open(DialogGenerateWorkOrder, {
      width: '500px',
      data: {
        pos: pos,
        project: this.project
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadProjectPositionList();
    });
  }

  btnClickCalcProjectTimePlan(): void {
    const dialogRef = this.dialog.open(ButtonConfirmComponent, {
      width: '500px',
      data: {
        title: 'Zeitplan neu berechnen',
        text: 'Wollen Sie den Projektplan wirklich neu berechnen? Alle händischen Änderungen werden überschrieben',
        btnNo: 'Nein',
        btnYes: 'Ja'
      }
    });

    dialogRef.afterClosed().subscribe((conf) => {
      if (conf) {
        this.enableCalcProjectTimePlan = true;
        this.projectApiService.calcProjectTimePlan(this.project.id).subscribe(
          (res: any) => {
            this.snackBar.open('Zeitplan erfolgreich berechnet', 'ok', {
              duration: this.snackBarDuration * 1000
            });
            
          },
          (err: any) => console.error(err),
          () => 
            {
              this.loadProjectPositionList();
              this.enableCalcProjectTimePlan = false;
            }
        );
      }
    });
  }

  btnClickUpdateProjectPlan(): void {
    this.enableUpdateProjectPlan = true;
    const observablesList: Observable<any>[] = [];
    this.cdsApiService
      .getEntityByFilter('msdyn_workorders', '_std_auftragid_value', this.project.idCDSStdAuftrag)
      .subscribe(
        (response: CDSApiResponse) => {
          response.value.forEach((cdsWorkorder) => {
            observablesList.push(
              this.projectApiService.updateProjectPlan(this.project.id, {
                workorder: [
                  {
                    idCDSWorkorder: cdsWorkorder.msdyn_workorderid,
                    std_fortschritt: cdsWorkorder.std_fortschritt
                  }
                ]
              })
            );
          });

          forkJoin(observablesList).subscribe((res) => {
            this.snackBar.open(res.length + ' Einträge aktualisiert', 'ok', {
              duration: this.snackBarDuration * 1000
            });

            this.enableUpdateProjectPlan = false;
          });
        },
        (err: any) => console.error(err)
      );
  }
}
