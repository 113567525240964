import { Component, Inject, OnInit } from '@angular/core';
import { SpsPos } from '../../../models/sps-pos';
import { ProjectApiService } from '../../../service/project-api.service';
import { SPSProject } from '../../../models/sps-project';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CDSApiService } from '../../../service/cds-api.service';
import { HttpResponse } from '@angular/common/http';
import { CDSProduct } from '../../../models/cds/cds-product';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';

@Component({
  selector: 'dialog-generate-work-order',
  templateUrl: 'dialog-generate-work-order.html'
})
export class DialogGenerateWorkOrder implements OnInit {
  posFormGroup: FormGroup;
  enableProgressSpinner: boolean;
  position: SpsPos;
  project: SPSProject;
  serviceProduct: CDSProduct;
  cdsProductList: CDSProduct[];
  snackBarDuration: number = 2;

  constructor(
    public dialogRef: MatDialogRef<DialogGenerateWorkOrder>,
    private _formBuilder: FormBuilder,
    private cdsApiService: CDSApiService,
    private projectApiService: ProjectApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.posFormGroup = this._formBuilder.group({
      systeminfo: new FormControl(''),
      startDateMech: new FormControl('', Validators.required),
      endDateMech: new FormControl('', Validators.required)
    });
    this.position = this.data.pos;
    this.project = this.data.project;
    console.log(this.project);
  }

  ngOnInit(): void {
    this.posFormGroup.patchValue({
      startDateMech: this.position.startDateMech,
      endDateMech: this.position.endDateMech
    });

    this.loadProductList();
  }

  btnGenerateWorkOrder(): void {
    this.enableProgressSpinner = true;
    this.position = Object.assign(this.position, this.posFormGroup.value);

    const workOrderName =
      this.position.workorder.length > 0
        ? this.project.orderNumber + '-' + this.position.posNumber + '.' + this.position.workorder.length
        : this.project.orderNumber + '-' + this.position.posNumber;

    console.log(
      'using incidenttypeid' + this.position.cdsIncidentType.msdyn_incidenttypeid + ' for workorder ' + workOrderName
    );
    this.cdsApiService
      .createEntity('msdyn_workorders', {
        statecode: +0,
        statuscode: +1,
        msdyn_name: workOrderName,
        'msdyn_serviceaccount@odata.bind': `/accounts(${this.project.customer.accountid})`,
        'std_auftragid@odata.bind': `/std_auftrags(${this.project.idCDSStdAuftrag})`,
        'msdyn_primaryincidenttype@odata.bind': `/msdyn_incidenttypes(${this.position.cdsIncidentType.msdyn_incidenttypeid})`,
        'msdyn_pricelist@odata.bind': `/pricelevels(${this.cdsApiService.getDefault('pricelevels')})`,
        'std_mandantid@odata.bind': `/cdm_companies(${this.cdsApiService.getDefault('cdm_companies')})`,
        'msdyn_workordertype@odata.bind': `/msdyn_workordertypes(${this.position.spsPosType.idCDWorkordertype})`,
        msdyn_datewindowend: this.position.endDateMech,
        msdyn_datewindowstart: this.position.startDateMech,
        std_systeminfos: this.position.systeminfo,
        msdyn_taxable: false,
        msdyn_systemstatus: 690970000
      })
      .subscribe(
        (response: HttpResponse<any>) => {
          let wo = {
            idSpsPos: this.position.id,
            idSpsProject: this.position.idSpsProject,
            idCDSWorkorder: this.cdsApiService.getResponseIdByHeader(response.headers.get('odata-entityid'))
          };
          this.projectApiService.createNewWorkorder(wo).subscribe(
            (res: SpsApiResponse) => {
                this.snackBar.open('Arbeitsauftrag erstellt', 'ok', {
                  duration: this.snackBarDuration * 1000
                });
                  
                this.dialogRef.close();
            },
            (err: any) => console.error(err)
          );
        },
        (err: any) => {
          this.snackBar.open(err, 'ok', {
            duration: this.snackBarDuration * 1000
          });
          this.dialogRef.close();
        }
      );
  }

  loadProductList(): void {
    this.enableProgressSpinner = true;
    this.cdsApiService
      .getAllEntity(
        'products?$select=name,productid,productnumber&$filter=std_pit_relevant%20eq%20true%20and%20msdyn_fieldserviceproducttype%20eq%20690970002'
      )
      .subscribe(
        (response: CDSApiResponse) => {
          this.cdsProductList = response.value;
          console.log(this.cdsProductList);
        },
        (err: any) => console.error(err),
        () => (this.enableProgressSpinner = false)
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
