import { Component, OnInit, OnChanges, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GraphUser } from '../../../models/graph-user';
import { GraphApiService } from '../../../service/graph-api.service';
import { debounceTime, tap, switchMap, finalize, map } from 'rxjs/operators';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSSystemUsers } from '../../../models/cds/cds-systemusers';
import { CDSStdWorker } from '../../../models/cds/cds-stdWorker';
import { HelpPopupComponent } from '../../project/project-detail/help-popup/help-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cds-contact-picker',
  templateUrl: './cds-contact-picker.component.html',
  styleUrls: ['./cds-contact-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CdsContactPickerComponent),
      multi: true
    }
  ]
})
export class CdsContactPickerComponent implements OnInit, ControlValueAccessor {
  pickerControl = new FormControl();
  userList: CDSStdWorker[];
  isLoading: boolean;
  userIsLoading: boolean;
  selectedUser: CDSStdWorker;
  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;

  @Input() label: string;
  @Input() email: boolean;
  @Input() projectExternalLink: string;
  constructor(private cdsApiService: CDSApiService, public dialog: MatDialog) {}

  writeValue(stdWorkerId: string): void {
    if (stdWorkerId === null || stdWorkerId === '') return;

    this.userIsLoading = true;
    this.cdsApiService.getEntity('std_workers', stdWorkerId).subscribe(
      (response: CDSStdWorker) => {
        this.selectedUser = response;
        this.pickerControl.setValue(response);
      },
      (err: any) => console.error(err),
      () => (this.userIsLoading = false)
    );
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ngOnChanges(changes: import("@angular/core").SimpleChanges) {
  //   if(this.value != null) {
  //     this.userIsLoading = true;
  //     this.cdsApiService.getEntity('std_workers',this.value)
  //     .subscribe(
  //       (response:CDSStdWorker) => {
  //         this.selectedUser = response;
  //         this.pickerControl.setValue(response);
  //         this.onChange(this.value);
  //       },
  //       (err: any) => console.error(err),
  //       () => this.userIsLoading = false
  //     );
  //   }
  // }

  setSelectdUser(user: CDSStdWorker) {
    this.selectedUser = user;
    this.onChange(user);
  }

  ngOnInit() {
    this.pickerControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (this.isLoading = true)),
        map((value) => {
          const filter = `startswith(std_suchbegriff,'${value}')and std_email ne null`;
          return filter;
        }),
        switchMap((value) =>
          this.cdsApiService
            .getEntitySearchWithFilters('std_workers', value)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe((response) => {
        const uniqueArr: CDSStdWorker[] = Object.values(
          response.value.reduce((acc: {}, item: CDSStdWorker) => {
            acc[item.std_email] = item;
            return acc;
          }, {})
        );
        this.userList = uniqueArr;
        // this.userList = response.value;
      });
  }

  displayFn(user: CDSStdWorker): string {
    return user && user.std_suchbegriff ? user.std_suchbegriff : '';
  }

  showHelpPopup(): void {
    const dialogRef = this.dialog.open(HelpPopupComponent, {
      data: this.projectExternalLink
    });
  }
}
