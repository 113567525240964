<!-- <div *ngIf="resourceRequirements | async"></div> -->
<div class="headline-wrapper">
  <h2>All Resource Requirements</h2>
  <table mat-table [dataSource]="resourceRequirements | async" multiTemplateDataRows matSort>
    <ng-container matColumnDef="requirementId">
      <th mat-header-cell *matHeaderCellDef width="35%">Resource Requirement ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.msdyn_resourcerequirementid }}
      </td>
    </ng-container>

    <ng-container matColumnDef="requirementName">
      <th mat-header-cell *matHeaderCellDef width="25%">Resource Requirement Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.msdyn_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="serviceTask">
      <th mat-header-cell *matHeaderCellDef width="30%">Service Task</th>
      <td mat-cell *matCellDef="let element">{{ element.servicetask_msdyn_name }}</td>
    </ng-container>

    <ng-container matColumnDef="deleteRel">
      <th mat-header-cell *matHeaderCellDef width="10%"></th>
      <td mat-cell *matCellDef="let element" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          (click)="delete(element.msdyn_resourcerequirementid, element.msdyn_workorderservicetaskid)"
          [disabled]="disableDelete"
        >
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="table-element-row"></tr>
  </table>
  <mat-spinner *ngIf="enableProgressSpinner" diameter="40" style="margin: auto; margin-top: 20px"></mat-spinner>
  <button mat-raised-button (click)="closeDialog()" class="buttonClose">Close</button>
</div>
