<div class="cds-output-field-{{type}}">
    <div class="cds-output-field-label">{{ label }}</div> 
    
    <span class="spacer"></span>
    <container-element [ngSwitch]="value">
        <span *ngSwitchCase="true">Ja</span>
        <span *ngSwitchCase="false">Nein</span>
        <span *ngSwitchCase="956410000">Ja</span>
        <span *ngSwitchCase="956410001">Nein</span>
        <span *ngSwitchCase="956410002">Nicht erforderlich</span>
        <span *ngSwitchDefault>{{value}}</span>
    </container-element>
</div>
