import { Component, OnInit, ViewChild } from '@angular/core';
import { LimesurveyApiClientService } from '../../service/limesurvey-api-client.service';
import { FormGroup, FormBuilder,Validators} from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { LimesurveySurvey } from '../../models/limesurvey-survey';
import { LimesurveyParticipant } from '../../models/limesurvey-participant';
import { CDSApiService } from '../../service/cds-api.service';
import { CDSApiResponse } from '../../models/cds/cds-api-response';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('selectedSurvey') private selectedSurvey: MatSelectionList;

  response: any;
  loginFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  loginStatus: string;
  isLinear: boolean;
  surveyFinished: boolean;
  surveyList: LimesurveySurvey[];
  participants: LimesurveyParticipant[];
  newParticipants: LimesurveyParticipant[];
  invitedParticipants: any;
  projectList: any;
  
  constructor(
    private limesurveyApiClientService: LimesurveyApiClientService,
    private cdsApiService: CDSApiService,
    private _formBuilder: FormBuilder, 
    private _snackBar: MatSnackBar,
  ) { 
    this.loginFormGroup = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.contactFormGroup = this._formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      ordernumber: ['', Validators.required],
      plName: ['', Validators.required],
      plMail: ['', Validators.required],
      plPhone: ['', Validators.required]
    });

    this.isLinear = true;
    this.surveyFinished = false;
    this.newParticipants = [];
  }

  ngOnInit() {
    this.btnLoginClick();
  }


  //STEP 1
  
  btnLoginClick(): void {
    this.limesurveyApiClientService.getSessionKey(environment.ls.user,environment.ls.password)
      .subscribe(
        (data: any) => this.login(data),      
        (err) => console.error(err)
      );
  }

  login(data: any): void {
    if(!!data.result.status)
        this._snackBar.open(data.result.status, "schließen", {
          duration: 2000,
        });
    else {
      this.limesurveyApiClientService.setSessionKey(data.result);
      this.limesurveyApiClientService.getSurveyList()      
        .subscribe(
          (data: any) => this.surveyList = data.result,      
          (err) => console.error(err)
        );
      this.stepper.next();
    }
  }

  //STEP 2

  btnLookUpProjectClick(): any {
    this.cdsApiService.getEntityByFilter('std_auftrags','std_auftragsnummer','%27' + this.contactFormGroup.get('ordernumber').value + '%27')
    .subscribe(
        (response: CDSApiResponse) => {
          this.lookUpProjectmanger(response.value[0]._std_projektleiter_stoebichid_value);
        },
        (err: any) => console.error(err)
    );
  }  
  
  lookUpProjectmanger(pm: string): any {
    this.cdsApiService.getEntity('systemusers',pm)
    .subscribe(
        (response: any) => {
          this.contactFormGroup.get('plName').setValue(response.fullname);
          this.contactFormGroup.get('plMail').setValue(response.internalemailaddress);
          this.contactFormGroup.get('plPhone').setValue(response.address1_telephone1);
        },
        (err: any) => console.error(err)
    );
  }

  btnAddParticipantClick(): void {
    this.newParticipants.push(
        {
          firstname: this.contactFormGroup.get('firstname').value,
          lastname: this.contactFormGroup.get('lastname').value,
          email: this.contactFormGroup.get('email').value,
          attribute_2: this.contactFormGroup.get('ordernumber').value,
          attribute_3: this.contactFormGroup.get('plName').value,
          attribute_5: this.contactFormGroup.get('plMail').value,
          attribute_4: this.contactFormGroup.get('plPhone').value
        }
    );
    this.contactFormGroup.reset();
  }

  btnNewParticipantsClick(): void {
    this.selectedSurvey.selectedOptions.selected.forEach(survey => {
      this.limesurveyApiClientService.addParticipants(
        survey.value.sid,
        this.newParticipants
        )
        .subscribe(
          (data: any) => this.setParticipant(data),      
          (err) => console.error(err)
      );
    });
  }

  btnSendSurveyClick(): void {
    let aTokenIds: string[] = [];
    this.participants.forEach(participant => {
      aTokenIds.push(participant.tid);
    });

    this.selectedSurvey.selectedOptions.selected.forEach(survey => {
      this.limesurveyApiClientService.inviteParticipants(
        survey.value.sid,
        aTokenIds,
        true
        )
        .subscribe(
          (data: any) => this.inviteDone(data),      
          (err) => console.error(err)
      );
    });
  }

  inviteDone(data: any): void {
    this.invitedParticipants = data.result;
    this.surveyFinished = true;
  }

  setParticipant(data: any): void {
    if(!!data.result.status)
      this._snackBar.open(data.result.status, "schließen", {
        duration: 2000,
      });
    else {
      this.participants = data.result;
      this.stepper.next();
    }
  }

  get surveySelected(): boolean { return !(!!this.selectedSurvey && this.selectedSurvey.selectedOptions.selected.length > 0); }
}
