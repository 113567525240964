import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-button-confirm',
  templateUrl: './button-confirm.component.html',
  styleUrls: ['./button-confirm.component.scss']
})
export class ButtonConfirmComponent implements OnInit {

  private res:boolean;

  constructor(
    public dialogRef: MatDialogRef<ButtonConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.res = false;
  }
  
  onConfirm(): void {
    this.dialogRef.close(!this.res)
  }
  
  onNoClick(): void {
    this.dialogRef.close(this.res)
  }

}