import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdsContactListComponent } from '../../../cds/cds-contact-list/cds-contact-list.component';

@Component({
  selector: 'shild-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss']
})
export class HelpPopupComponent implements OnInit {
  enableProgressSpinner: boolean = false;
  projectExternalLink: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: string,  public dialogRef: MatDialogRef<CdsContactListComponent>) {
    this.projectExternalLink = data;
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
}
