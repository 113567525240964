<mat-list>
  <!-- <div mat-subheader></div> -->
  <mat-list-item *ngFor="let spsWorkorderService of spsWorkorderServiceList">
    <span mat-list-icon>{{ spsWorkorderService.std_positionsnummer }}</span>
    <div mat-line *ngIf="!spsWorkorderService.std_default">
      <strong>{{ spsWorkorderService.msdyn_name }}</strong>
    </div>
    <div mat-line *ngIf="!!spsWorkorderService.std_default"><strong>Vorgabe</strong></div>
    <div mat-line>Erstellt am: {{ spsWorkorderService.createdon | date: 'dd.MM.yyyy' }}</div>
    <div mat-line>
      <div class="icon-list">
        <div class="icon-list-element">
          <mat-icon mat-list-icon>access_time</mat-icon>
          <span>
            {{ +spsWorkorderService.std_dauer }}
          </span>
        </div>
        <div class="icon-list-element">
          <mat-icon mat-list-icon>people</mat-icon> {{ spsWorkorderService.std_anzahl_der_ressourcen }}
        </div>
        <div class="icon-list-element" *ngIf="!spsWorkorderService.std_default">
          <mat-icon mat-list-icon>assignment_turned_in</mat-icon>
          {{ spsWorkorderService.std_prozentualer_fortschritt }} %
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>
