import { Component, Input, OnInit } from '@angular/core';
import { CDSApiService } from '../../../service/cds-api.service';

@Component({
  selector: 'shild-resource-requirement-list',
  templateUrl: './resource-requirement-list.component.html',
  styleUrls: ['./resource-requirement-list.component.scss']
})
export class ResourceRequirementListComponent implements OnInit {
  spsWorkorderServiceList: any[];

  enableProgressSpinner: boolean;
    asyncProblem = false;
  @Input() dataSource: any[];
  fullTime: number = 0;

  constructor(public cdsApiService: CDSApiService) {
    this.spsWorkorderServiceList = [];
  }

  ngOnInit(): void {
    this.initWorkorderList();
  }

  initWorkorderList(): void {
    this.dataSource.forEach((el) => {
      this.fullTime += el.std_dauer_stunden;
      this.spsWorkorderServiceList.push(el);
    });
      if (this.spsWorkorderServiceList.length === 1 && this.fullTime === 0) {
          this.asyncProblem = true
      }
    this.enableProgressSpinner = true;
  }
}
