<div class="wrapper">  
  
  <mat-tab-group>
    <mat-tab label="Produkte">
      <div class="mat-tab-wrapper">  
        <mat-toolbar>
          <mat-form-field>
            <!-- <mat-label>Sprache</mat-label> -->
            <mat-select [(value)]="databoxApiClientService.lang" (selectionChange)="this.reloadPDF(currentProduct)" >
              <mat-option *ngFor="let lang of languages" [value]="lang.id">
                {{lang.language}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-toolbar>
        <mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>
          <div class="flex-layout">

            <div class="flex-box sidebar">
              <div class="scrollbox">    
                  <app-databox-product-picker *ngIf="isLoaded" [lang]="databoxApiClientService.lang" (change)="reloadPDF($event)"></app-databox-product-picker>
              </div>
            </div>

            <div class="flex-box pdf-viewer">
                <!-- {{currentPdf}} -->
                <ngx-extended-pdf-viewer 
                    [src]="currentPdf"
                    [useBrowserLocale] = "true"
                    [showSidebarOnLoad] = "true" 
                    (pagesLoaded)="enableProgressBar = false"
                    [showBookmarkButton]="false">
                </ngx-extended-pdf-viewer>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Weitere Dokumente">
        <mat-list>
          <a href="./assets/pdf/SBS_Montageblatt_FAA.pdf">
            <mat-list-item>
                <mat-icon mat-list-icon>note</mat-icon>
                <h4 mat-line>Montagedatenblatt FAA</h4>
                <p mat-line>Förderanlagenabschluss mit Netzersatzanlage und Freifahreinrichtung</p>
            </mat-list-item>
          </a>
            <!-- <mat-divider></mat-divider>
            <h3 mat-subheader>Notes</h3>
            <mat-list-item *ngFor="let note of notes">
              <mat-icon mat-list-icon>note</mat-icon>
              <h4 mat-line>{{note.name}}</h4>
              <p mat-line> {{note.updated | date}} </p>
            </mat-list-item> -->
          </mat-list>
      </mat-tab>
    </mat-tab-group>
</div>