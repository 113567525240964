<div *ngIf="dataSource.length === 0">
  <p>Keine Arbeitsaufträge vorhanden!</p>
</div>

<div class="workorder-list">
  <mat-card class="workorder-card" *ngFor="let workorder of cdsWorkorderList">
    <mat-card-header>
      <div mat-card-avatar><mat-icon mat-card-avatar>content_paste</mat-icon></div>
      <mat-card-title
        >{{ workorder.msdyn_name }}
        <div class="cardButtons">
          <button color="primary" mat-button (click)="btnClickGenerateResourceRequirement(workorder)">
            Ressourcenanforderung erstellen
          </button>
          <button mat-button target="_blank" (click)="onClickProjectExternalLink(workorder.externalLink)">
            in Field Service anzeigen
          </button>
        </div>
      </mat-card-title>
      <mat-card-subtitle>{{ workorder.std_fortschritt }} %</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-progress-bar [value]="workorder.std_fortschritt"> </mat-progress-bar>

      <!-- <app-workorder-service-list [dataSource]="workorder.workorderService"></app-workorder-service-list> -->
      <shild-resource-requirement-list [dataSource]="workorder.workorderService"></shild-resource-requirement-list>
    </mat-card-content>
    <mat-card-actions align="end">
      <button
        mat-button
        *ngIf="(workorder.workorderService.length > 1 || (workorder.workorderService.length === 1 && workorder.workorderService[0].std_dauer_stunden > 0) )"
        (click)="onViewResourceRequirements(workorder)"
        color="primary"
      >
        View resource requirements
      </button>
    </mat-card-actions>
  </mat-card>
</div>
