import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CDSWorkorder } from 'apps/databox-client/src/app/models/cds/cds-workorder';
import { CDSApiService } from 'apps/databox-client/src/app/service/cds-api.service';
import { Observable, map, switchMap, zip, of, mergeMap, from, merge, concat, toArray, forkJoin } from 'rxjs';
import { DialogResourceRequirement } from '../dialog-generate-resource-requirement.component';

@Component({
  selector: 'shild-dialog-view-resource-requirement',
  templateUrl: './dialog-view-resource-requirement.component.html',
  styleUrls: ['./dialog-view-resource-requirement.component.scss']
})
export class DialogViewResourceRequirementComponent implements OnInit {
  resourceRequirements: Observable<any>;
  displayedColumns: string[] = ['requirementId', 'requirementName', 'serviceTask', 'deleteRel'];
  workorder: CDSWorkorder;
  relationData: any = [];
  datas = [];
    enableProgressSpinner = true;
    disableDelete = false;

  constructor(private cdsApiService: CDSApiService, @Inject(MAT_DIALOG_DATA) public data: any,  public dialogRef: MatDialogRef<DialogResourceRequirement>,) {
    this.workorder = data.workorder;
    this.intiData();
  }

  intiData() {
    this.datas = [];
    this.resourceRequirements = this.cdsApiService
      .getAllEntity(
        `msdyn_resourcerequirements?$filter=_msdyn_workorder_value%20eq%20${this.workorder.msdyn_workorderid}`
      )
      .pipe(
          map((res) => {
              if (res.value.length === 0) {
                this.dialogRef.close();
            }
          return res.value;
        }),
        mergeMap((resources) =>
          forkJoin(
            resources.map((c, index) =>
              this.cdsApiService
                .getAllEntity(
                  `std_workorderservicetask_resourcerequset?$filter=msdyn_resourcerequirementid%20eq%20${c.msdyn_resourcerequirementid}`
                )
                .pipe(
                  map((res) => {
                    if (res.value.length > 0) {
                      res.value.forEach((element) => {
                        let a = resources.filter(
                          (el) => el.msdyn_resourcerequirementid === element.msdyn_resourcerequirementid
                        );
                        this.datas.push({
                          msdyn_name: a[0].msdyn_name,
                          msdyn_resourcerequirementid: a[0].msdyn_resourcerequirementid,
                          msdyn_workorderservicetaskid: element.msdyn_workorderservicetaskid,
                          servicetask_msdyn_name: ''
                        });
                      });
                    }
                    return res.value;
                  })
                )
            )
          )
        ),
        map((res: any) => {
          this.datas.forEach((el, i) => {
            if (el.msdyn_workorderservicetaskid.length === 0) {
              this.datas.splice(i, 1);
            }
          });
          return this.datas;
        }),
        mergeMap((ref: any) =>
          forkJoin(
            ref.map((c, index) =>
              this.cdsApiService
                .getAllEntity(
                  `msdyn_workorderservicetasks?$filter=msdyn_workorderservicetaskid%20eq%20${c.msdyn_workorderservicetaskid}`
                )
                .pipe(
                  map((res) => {
                    this.datas[index].servicetask_msdyn_name = res.value[0].msdyn_name;
                    this.enableProgressSpinner = false;
                    return res.value;
                  })
                )
            )
          )
        ),
        map((res) => {
          return this.datas;
        })
      );
  }

  ngOnInit(): void {}

  delete(msdyn_resourcerequirementId, msdyn_workorderservicetaskid) {
      this.enableProgressSpinner = true;
      this.disableDelete = true;
    let sameResourceRequirements = this.datas.filter(
      (el) => el.msdyn_resourcerequirementid === msdyn_resourcerequirementId
    );
    this.cdsApiService
      .deleteRelationTable(
        `msdyn_workorderservicetasks(${msdyn_workorderservicetaskid})/std_msdyn_workorderservicetask_msdyn_resource(${msdyn_resourcerequirementId})/$ref`
      )
      .subscribe((res) => {
        if (sameResourceRequirements.length === 1) {
          this.cdsApiService
            .deleteEntity('msdyn_resourcerequirements', msdyn_resourcerequirementId)
            .subscribe((res) => {
                this.intiData();
                this.disableDelete = false;
            });
        } else {
            this.intiData();
            this.disableDelete = false;
        }
      });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
