import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CDSOpportunity } from '../../../models/cds/cds-opportunity';
import { CDSProject } from '../../../models/cds/cds-project';
import { ProjectApiService } from '../../../service/project-api.service';

@Component({
  selector: 'dialog-generate-project-plan',
  templateUrl: 'dialog-generate-project-plan.html'
})
export class DialogGenerateProjectPlan {
  projectFormGroup: FormGroup;
  enableProgressSpinner: boolean;
  snackBarDuration: number = 5;
  public cdsProject: CDSProject;
  public opportunity: CDSOpportunity;

  constructor(
    public dialogRef: MatDialogRef<DialogGenerateProjectPlan>,
    private _formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private snackBar: MatSnackBar,
    private projectApiService: ProjectApiService,
    @Inject(MAT_DIALOG_DATA) data: { data: CDSProject; opportunity: CDSOpportunity }
  ) {
    this.projectFormGroup = this._formBuilder.group({
      startDate: new FormControl('', Validators.required)
    });
    this.dateAdapter.setLocale('de');

    this.cdsProject = data.data;
    this.opportunity = data.opportunity;
    console.log('##### DialogGenerateProjectPlan #####');
  }

  btnGenerateWorkOrder(): void {
    let smartsheetfoldermatch = '';
    if (this.opportunity?.std_gebietid?.name.includes('FAA')) {
      smartsheetfoldermatch = 'FAA';
    } else if (this.opportunity?.std_gebietid?.name.includes('FLEX')) {
      smartsheetfoldermatch = 'FLEX';
    }

    this.enableProgressSpinner = true;
    console.log('idCDSStdAuftrag: ' + this.cdsProject.std_auftragid);
    console.log('orderNumber:' + this.cdsProject.std_auftragsnummer);
    console.log('startDate:' + this.projectFormGroup.get('startDate').value);
    console.log('projectmanager:' + this.cdsProject.std_worker_projektleiter_stoebichid.std_benutzerid);
    console.log('smartsheetfoldermatch: ' + smartsheetfoldermatch);

    //must be something from opportunity
    this.projectApiService
      .createNewProject({
        idCDSStdAuftrag: this.cdsProject.std_auftragid,
        orderNumber: this.cdsProject.std_auftragsnummer,
        startDate: this.projectFormGroup.get('startDate').value,
        smartsheetfoldermatch: smartsheetfoldermatch,
        projectmanager: this.cdsProject.std_worker_projektleiter_stoebichid.std_benutzerid
      })
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        (err: any) => {
          console.error(err);
          this.snackBar.open(err, 'ok', {
            duration: this.snackBarDuration * 1000
          });
          this.enableProgressSpinner = false;
        }
      );
  }
}
