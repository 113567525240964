<mat-divider></mat-divider>
<mat-toolbar>
  <button mat-icon-button (click)="btnClickNavigateBack()"><mat-icon>arrow_back</mat-icon></button>
  <mat-divider [vertical]="true"></mat-divider>
  <button mat-icon-button color="primary" (click)="btnClickSaveProject()"><mat-icon>save</mat-icon></button>
  <button mat-icon-button [disabled]="isNew || spsProject.status > 0" (click)="btnClickDeleteProject()">
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-icon-button [disabled]="isNew" (click)="loadProject()"><mat-icon>autorenew</mat-icon></button>
  <span class="spacer"></span>
  <!-- [disabled]="cdsProject.std_worker_projektleiter_stoebichid?._std_benutzerid_value === null" *ngIf="!spsProject.permalink"  -->
  <!-- <button mat-button  color="primary" (click)="btnClickCreateProjectPlan()">Projektplan erstellen</button> -->
  <button
    [disabled]="
      buttonDisabled ||
      spsProject.permalink ||
      spsProject.IdSmartsheetSheet ||
      !this.cdsProject.std_worker_projektleiter_stoebichid.std_benutzerid
    "
    mat-button
    color="primary"
    (click)="btnClickCreateProjectPlan()"
  >
    Projektplan erstellen
  </button>
  <button mat-button (click)="onClickProjectExternalLink()">in Field Service anzeigen</button>
  <button mat-button (click)="onClickDocumentExternalLink()">D3 Dokumente anzeigen</button>
  <button *ngIf="!!spsProject.permalink" mat-button (click)="onClickSmartsheet(spsProject.permalink)">
    in Smartsheet anzeigen
  </button>

  <!-- <a *ngIf="!!spsProject.permalink" target="_blank" mat-button href="{{ spsProject.permalink }}"
    ><mat-icon matSuffix>open_in_browser</mat-icon></a
  > -->
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="wrapper">
  <!-- <div>{{cdsProject.std_worker_projektleiter_stoebichid | json }}</div> -->
  <div>
    <h2>
      {{ cdsProject.std_auftrag }}
      <small *ngIf="!!cdsProject.std_bauvorhabenid?.std_name"
        ><span
          >(<a [routerLink]="['/sps/buildingProject/', cdsProject.std_bauvorhabenid.std_bauvorhabenid]">{{
            cdsProject.std_bauvorhabenid.std_name
          }}</a
          >)</span
        ><br />
        Projekt</small
      >
    </h2>
  </div>

  <mat-tab-group *ngIf="!enableProgressBar">
    <mat-tab label="Allgemein">
      <div class="mat-tab-wrapper">
        <div
          class="container"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="0.5%"
          fxLayoutAlign="center"
        >
          <div fxFlex="33%">
            <mat-card>
              <mat-card-content>
                <form [formGroup]="projectFormGroup">
                  <mat-form-field class="full-width">
                    <mat-label>Name des Projekts</mat-label>
                    <input matInput type="text" class="form-control" id="name" formControlName="name" readonly />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Auftragsnummer</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="orderNumber"
                      formControlName="orderNumber"
                      readonly
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Kunde</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="customer"
                      formControlName="customer"
                      readonly
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Smartsheet ID</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="IdSmartsheetSheet"
                      formControlName="IdSmartsheetSheet"
                      readonly
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Smartsheet Link</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="permalink"
                      formControlName="permalink"
                      readonly
                    />
                  </mat-form-field>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="33%">
            <mat-card>
              <mat-card-content>
                <mat-card-title>Projektteam</mat-card-title>
                <mat-card-subtitle>
                  {{ spsProject.generalStartDate | date: 'dd.MM.yyyy' }} -
                  {{ spsProject.generalEndDate | date: 'dd.MM.yyyy' }}
                </mat-card-subtitle>
                <form [formGroup]="projectTeamFormGroup">
                  <app-cds-contact-picker
                    [email]="true"
                    [projectExternalLink]="projectExternalLink"
                    formControlName="_std_worker_projektleiter_stoebichid_value"
                    >Projektleiter</app-cds-contact-picker
                  >

                  <app-cds-contact-picker formControlName="_std_worker_konstrukteur_mechid_value"
                    >Mech. Konstrukteur</app-cds-contact-picker
                  >
                  <app-cds-contact-picker formControlName="_std_worker_konstrukteur_elektid_value"
                    >Elt. Konstrukteur</app-cds-contact-picker
                  >
                  <app-cds-contact-picker formControlName="_std_worker_kaufmaennisch_stoebich_value"
                    >Kaufm. Abwicklung</app-cds-contact-picker
                  >
                </form>
                <!-- {{ projectTeamFormGroup.value | json }} -->
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="33%">
            <mat-card>
              <mat-card-content>
                <mat-card-title>Montage</mat-card-title>
                <mat-card-subtitle>
                  {{ spsProject.installationStartDate | date: 'dd.MM.yyyy' }} -
                  {{ spsProject.installationEndDate | date: 'dd.MM.yyyy' }}
                </mat-card-subtitle>
                <form [formGroup]="projectInstallationFormGroup">
                  <app-cds-contact-picker formControlName="_std_worker_montageleiter_stoebich_value"
                    >Montageleiter</app-cds-contact-picker
                  >
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <pre></pre>
    </mat-tab>
    <mat-tab label="Details">
      <div class="mat-tab-wrapper">
        <app-project-info
          *ngFor="let cdsOpportunity of cdsOpportunityList"
          [dataSource]="cdsOpportunity.opportunityid"
          [std_std_projektbeteiligte_std_auftrag]="cdsProject.std_std_projektbeteiligte_std_auftrag"
        ></app-project-info>
      </div>
    </mat-tab>
    <mat-tab label="Positionen" *ngIf="!!spsProject.permalink && canLoadPositions">
      <div class="mat-tab-wrapper">
        <app-position-list [project]="spsProject"></app-position-list>
      </div>
    </mat-tab>
    <mat-tab label="Dokumente">
      <iframe class="iframe" [src]="documentExternalLink | safe"></iframe>
    </mat-tab>
    <!-- <mat-tab label="DEV">
            <div class="mat-tab-wrapper">
                SPS Projekt
                <pre>{{spsProject | json}}</pre>
                Bauvorhaben
                <pre>{{buildingProject | json}}</pre>
                CDS Projekt
                <pre>{{cdsProject | json}}</pre>

            </div>
        </mat-tab> -->
  </mat-tab-group>
</div>
