<div class="app-content-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="login-box">
      <mat-card-header>
        <mat-card-title>Anmelden</mat-card-title>
      </mat-card-header>
      
      <mat-card-actions>
        <button mat-stroked-button color="accent" (click)="btnClickLogin()" class="btn-block">Fortfahren</button>
      </mat-card-actions>
    </mat-card>
  
  </div>