<!-- <pre>{{ response | json }}</pre> -->

<div class="wrapper">
  
  <mat-tab-group>
    <mat-tab label="Projekte">
      <div class="mat-tab-wrapper">  

        <app-project-list></app-project-list>

      </div> 
    </mat-tab>
    <mat-tab label="Bauvorhaben">
      <div class="mat-tab-wrapper">

        <app-building-project-list></app-building-project-list>

      </div>  
    </mat-tab> 
  </mat-tab-group>

    <!-- <pre>{{debugData | json}}</pre> -->
</div>