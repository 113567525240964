import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSWorkorder } from '../../../models/cds/cds-workorder';
import { SpsPos } from '../../../models/sps-pos';
import { SPSProject } from '../../../models/sps-project';
import { SpsWorkorder } from '../../../models/sps-workorder';
import { CDSApiService } from '../../../service/cds-api.service';
import { ProjectApiService } from '../../../service/project-api.service';
import { DialogResourceRequirement } from './dialog-generate-resource-requirement.component';
import { DialogViewResourceRequirementComponent } from './dialog-view-resource-requirement/dialog-view-resource-requirement.component';

@Component({
  selector: 'app-workorder-list',
  templateUrl: './workorder-list.component.html',
  styleUrls: ['./workorder-list.component.scss']
})
export class WorkorderListComponent implements OnInit {
  cdsWorkorderList: any[];

  @Input() dataSource: SpsWorkorder[];
  @Input() position: SpsPos;
  @Input() project: SPSProject;
  enableProgressSpinner: boolean;

  constructor(public cdsApiService: CDSApiService, public dialog: MatDialog, public workorderApi: ProjectApiService) {
    this.cdsWorkorderList = [];
  }

  ngOnInit(): void {
    this.initWorkorderList();
  }

  initWorkorderList(): void {
    this.enableProgressSpinner = true;
    this.cdsWorkorderList = [];
    this.dataSource.forEach((spsWorkorder) => {
      this.cdsApiService
        .getEntity('msdyn_workorders', spsWorkorder.idCDSWorkorder)
        .pipe(
          catchError((err) => {
            return throwError(() => new Error(err));
          })
        )
        .subscribe(
          (workorderResponse: CDSWorkorder) => {
            workorderResponse.externalLink = this.cdsApiService.getExternalLink(
              'msdyn_workorder',
              workorderResponse.msdyn_workorderid
            );
            this.cdsApiService
              .getEntityByFilter(
                'msdyn_resourcerequirements',
                '_msdyn_workorder_value',
                workorderResponse.msdyn_workorderid
              )
              .subscribe(
                (response: CDSApiResponse) => {
                  if (response) {
                    workorderResponse.workorderService = response.value;
                    this.cdsWorkorderList.push(workorderResponse);
                  }
                  if (this.cdsWorkorderList.length > 1) {
                    this.cdsWorkorderList = [this.cdsWorkorderList[0]];
                  }
                },
                (err: any) => console.error(err)
              );
          },
          () => (this.enableProgressSpinner = false)
        );
    });
  }

  btnClickGenerateResourceRequirement(workorder: CDSWorkorder): void {
    const dialogRef = this.dialog.open(DialogResourceRequirement, {
      width: '500px',
      data: {
        workorder: workorder,
        position: this.position,
        project: this.project
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.initWorkorderList();
    });
  }
  onClickProjectExternalLink(link: string) {
    window.open(link, '_blank');
  }

  onViewResourceRequirements(workorder) {
    this.cdsApiService
      .getAllEntity(`msdyn_resourcerequirements?$filter=_msdyn_workorder_value%20eq%20${workorder.msdyn_workorderid}`)
      .subscribe((res) => {
        let data = [];
        res.value.forEach((element) => {
          this.cdsApiService
            .getAllEntity(
              `std_workorderservicetask_resourcerequset?$filter=msdyn_resourcerequirementid%20eq%20${element.msdyn_resourcerequirementid}`
            )
            .subscribe((res) => {
              let a = { name: element.msdyn_name, serviceTask: res.value[0]?.msdyn_resourcerequirementid };
              data.push(a);
            });
        });
      });

    const dialogRef = this.dialog.open(DialogViewResourceRequirementComponent, {
      width: '1000px',
      height: '80%',
      data: {
        workorder: workorder
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.initWorkorderList();
    });
  }
}
