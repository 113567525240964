<h1 mat-dialog-title>Projektplan erstellen</h1>
<div mat-dialog-content *ngIf="!enableProgressSpinner">
    <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="projectFormGroup">
            <ng-template matStepLabel>Ergänzende Informationen</ng-template>
            <!-- <h3>Bitte geben Sie eine Sollzeit der Montage an</h3> -->

            <form [formGroup]="projectFormGroup">
                <mat-form-field class="full-width">
                    <mat-label>Startdatum</mat-label>
                    <input matInput [matDatepicker]="picker" class="form-control" id="startDate" [required]="true"
                    formControlName="startDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker ></mat-datepicker>
                </mat-form-field>
            </form>

            <mat-divider></mat-divider>

            <div class="button-bar">
                <button mat-raised-button [disabled]="!projectFormGroup.valid" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Absenden</ng-template>
            <!-- <pre>{{ data | json }}</pre> -->
            <p>Projektplan für das Projekt <strong>{{ cdsProject.std_auftrag }}</strong> jetzt erstellen</p>
            <mat-divider></mat-divider>

            <div class="button-bar">
                <button mat-raised-button color="primary" (click)="btnGenerateWorkOrder()">Absenden</button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
