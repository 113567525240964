<div
  *ngIf="!enableProgressSpinner"
  class="container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="0.5%"
  fxLayoutAlign="center"
>
  <div fxFlex="33%">
    <mat-card *ngIf="!!cdsOpportunity.opportunity_quotes">
      <mat-card-header>
        <div mat-card-avatar><mat-icon mat-card-avatar>request_quote</mat-icon></div>
        <mat-card-title>Angebot</mat-card-title>
        <mat-card-subtitle>#{{ cdsOpportunity.opportunity_quotes[0]?.msdyn_quotenumber }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h3>Allgemein</h3>
        <app-cds-output-field
          [label]="'Name'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.name"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Angebotssumme'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_angebotsbetrag_b7 + ' €'"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Auftragssumme'"
          [value]="cdsOpportunity.opportunity_OpportunityCloses[0]?.actualrevenue + ' €'"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Auftragsnummer'"
          [value]="cdsOpportunity.opportunity_OpportunityCloses[0]?.description"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Info'"
          [value]="cdsOpportunity.std_infofuerdieprojektabwicklung_multiline"
          [type]="'area'"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Beauftragte Produkte'"
          [value]="cdsOpportunity.opportunity_OpportunityCloses[0]?.std_beauftr_produkte"
          [type]="'area'"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Neukunde: Debitoren-Stammdatenblatt verschickt?'"
          [value]="cdsOpportunity.std_datenfuerdebitorenanlage"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Nebenkosten'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_baunebenkosten"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Nachlass'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_nachlass"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Bestellnummer'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_bestellnummer"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Bestelldatum'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_bestelldatum | date: 'dd.MM.yyyy'"
        ></app-cds-output-field>
        <h3>Verwendbarkeitsnachweis</h3>
        <app-cds-output-field
          [label]="'Verwendbarkeitsnachweis'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_verwendbarkeitsnachweis"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Sanktionsprüfung durchgeführt'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_sanktionsprufung_durchgefuhrtcode"
        ></app-cds-output-field>
        <app-cds-output-field [label]="'Bemerkungen'" [value]="cdsOpportunity.opportunity_quotes[0]?.std_bemerkungen">
        </app-cds-output-field>
        <h3>Sonderfreigabe</h3>
        <app-cds-output-field
          [label]="'Sonderfreigabe'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_sonderfreigabecode"
        >
        </app-cds-output-field>
        <ng-container *ngIf="cdsOpportunity.opportunity_quotes[0]?.std_sonderfreigabecode">
          <app-cds-output-field
            [label]="'Ticketnummer'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_ticketnummer"
          >
          </app-cds-output-field>
        </ng-container>
        <h3>Inhaltsübersicht / Beauftragte Produkte</h3>
        <app-cds-output-field
          [label]="'Beauftragte Produktgruppe'"
          [value]="cdsOpportunity.opportunity_quotes[0]['std_beauftragte_produktgruppecode@OData.Community.Display.V1.FormattedValue']"
        ></app-cds-output-field>
        <ng-container *ngIf="cdsOpportunity.opportunity_quotes[0]['std_beauftragte_produktgruppecode@OData.Community.Display.V1.FormattedValue']?.includes('FAA')">
          <app-cds-output-field
            [label]="'FAA-Mechanik'"
            [value]="
              cdsOpportunity.opportunity_quotes[0]['std_faa_mechanikcode@OData.Community.Display.V1.FormattedValue']
            "
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'FAA-Elektrik'"
            [value]="
              cdsOpportunity.opportunity_quotes[0]['std_faa_elektrikcode@OData.Community.Display.V1.FormattedValue']
            "
          ></app-cds-output-field>
        </ng-container>
        <ng-container *ngIf="cdsOpportunity.opportunity_quotes[0]['std_beauftragte_produktgruppecode@OData.Community.Display.V1.FormattedValue']?.includes('Flex')">
          <app-cds-output-field
            [label]="'Flex-Mechanik'"
            [value]="
              cdsOpportunity.opportunity_quotes[0]['std_flex_mechanikcode@OData.Community.Display.V1.FormattedValue']
            "
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Flex-Elektrik'"
            [value]="
              cdsOpportunity.opportunity_quotes[0]['std_flex_elektrikcode@OData.Community.Display.V1.FormattedValue']
            "
          ></app-cds-output-field>
        </ng-container>

        <h3>Checklisten</h3>
        <app-cds-output-field
          [label]="'Auslandsprojekt'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_auslandsprojektcode"
        ></app-cds-output-field>
        <ng-container *ngIf="cdsOpportunity.opportunity_quotes[0]?.std_auslandsprojektcode">
          <app-cds-output-field
            [label]="'Übernahme durch ALG'"
            [value]="
              cdsOpportunity.opportunity_quotes[0][
                'std_ubernahme_durch_algcode@OData.Community.Display.V1.FormattedValue'
              ]
            "
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Übernahme durch Partner'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_ubernahme_durch_partner"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Ansprechpartner'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_ansprechpartner"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Registrierung im Einbauland'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_registrierung_im_einbaulandcode"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Registrierung'"
            [value]="
              cdsOpportunity.opportunity_quotes[0]['std_registrierungcode@OData.Community.Display.V1.FormattedValue']
            "
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'UST-ID Kunde'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_ustid_kunde"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Benötigte Zolldokumente'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_benotigte_zolldokumente"
          >
          </app-cds-output-field>
        </ng-container>
        <h3>Unterlagen</h3>
        <app-cds-output-field
          [label]="'Planungsunterlagen vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_planungsunterlagen_vorhandencode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Layout Fördertechnik vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_layout_foerdertechnik_vorhandencode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Anlagelayout vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_anlagelayout_vorhandencode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Schaltplanauszüge vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_schaltplanauszuege_vorhandencode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Betriebsmittelvorschfriften vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_betriebsmittelvorschfriften_vorhandencode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Brandschutzkonzept Genehmigung vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_brandschutrzkonzept_genehmigungcode"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Anwendersprache'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_anwendersprache"
        ></app-cds-output-field>
        <h3>Kaufmännische Angaben</h3>
        <app-cds-output-field
          [label]="'Incoterms'"
          [value]="cdsOpportunity.opportunity_quotes[0]['std_incotermscode@OData.Community.Display.V1.FormattedValue']"
        >
        </app-cds-output-field>
        <app-cds-output-field
          [label]="'Zahlungsplan'"
          [value]="
            cdsOpportunity.opportunity_quotes[0]['std_zahlungsplancode@OData.Community.Display.V1.FormattedValue']
          "
        >
        </app-cds-output-field>
        <app-cds-output-field
          [label]="'Vertragsstrafen/Pönalen'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_vertragsstrafen_ponalen"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Anzahlungsbürgschaft vorhanden?'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_buergschaftenvorhanden"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Vertragserfüllungsbürgschaft'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_vertragserfuellungsbuergschaft"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Gewährleistungsbürgschaft'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_gewaehrleistungsbuergschaft"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Gewährleistung/Wartung'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_gewaehrleistungwartung"
        ></app-cds-output-field>
        <!-- ?? this is not the right field -->
        <!-- <app-cds-output-field
          [label]="'Zahlungsbedingungen'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.msdyn_paymentterms?.__DisplayName__"
        ></app-cds-output-field> -->
        <app-cds-output-field
          [label]="'Zahlungsbedingungen'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_zahlungsbedingungencode"
        ></app-cds-output-field>
        <h3>Abschluss Fields</h3>
        <app-cds-output-field
          [label]="'Sachmerkmale gepflegt'"
          [value]="cdsOpportunity.opportunity_quotes[0]?.std_sachmerkmalegepflegtcode"
        >
        </app-cds-output-field>
        <ng-container *ngIf="cdsOpportunity.opportunity_quotes[0]?.std_sachmerkmalegepflegtcode">
          <app-cds-output-field
            [label]="'Begründung'"
            [value]="cdsOpportunity.opportunity_quotes[0]?.std_beschreibung_sachmerkmale"
          >
          </app-cds-output-field>
        </ng-container>
        <h3>Auftraggeber</h3>
        <app-cds-account-picker
          [label]="'Kundennummer'"
          [value]="cdsOpportunity.opportunity_quotes[0]?._std_auftraggeberid_value"
        ></app-cds-account-picker>
        <h3>Rechnungsempfänger</h3>
        <app-cds-account-picker
          [label]="'Kundennummer'"
          [value]="cdsOpportunity.opportunity_quotes[0]?._msdyn_invoicecustomerid_value"
        ></app-cds-account-picker>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="33%">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><mat-icon mat-card-avatar>construction</mat-icon></div>
        <mat-card-title>Bauvorhaben</mat-card-title>
        <mat-card-subtitle>#{{ cdsOpportunity.std_bauvorhabenid?.std_nummer }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h3>Allgemein</h3>
        <app-cds-output-field [label]="'Name'" [value]="cdsOpportunity?.std_name"></app-cds-output-field>
        <app-cds-output-field
          [label]="'Empfänger Lieferung'"
          [value]="cdsOpportunity?.std_empfaenger_lieferung"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Beschreibung'"
          [value]="cdsOpportunity.std_bauvorhabenid?.std_name"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Straße'"
          [value]="cdsOpportunity.std_bauvorhabenid?.std_adresse1_strasse1"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'PLZ'"
          [value]="cdsOpportunity.std_bauvorhabenid?.std_addresse1_postleitzahl"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Stadt'"
          [value]="cdsOpportunity.std_bauvorhabenid?.std_adresse1_ort"
        ></app-cds-output-field>
        <h3>Einbauort</h3>
        <app-cds-output-field [label]="'Name'" [value]="cdsOpportunity.currentsituation"></app-cds-output-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><mat-icon mat-card-avatar>access_time</mat-icon></div>
        <mat-card-title>Terminkette</mat-card-title>
        <mat-card-subtitle>Abgestimmte Termine vom Vertrieb mit dem Auftraggeber</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h3>Allgemein</h3>
        <app-cds-output-field [label]="'Terminschiene vorgegeben'" [value]="cdsOpportunity.std_terminschienevorgegeben">
        </app-cds-output-field>
        <app-cds-output-field
          *ngIf="!cdsOpportunity.std_terminschienevorgegeben"
          [label]="'Nach Absprache mit dem Kunden'"
          [value]="cdsOpportunity.std_nach_absprache_mit_dem_kundencode"
        >
        </app-cds-output-field>
        <ng-container *ngIf="cdsOpportunity.std_terminschienevorgegeben">
          <app-cds-output-field
            [label]="'Termin Pönale'"
            [value]="cdsOpportunity.std_terminpoenale_date | date: 'dd.MM.yyyy':'UTC'"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Liefertermin'"
            [value]="cdsOpportunity.std_liefertermin | date: 'dd.MM.yyyy':'UTC'"
          >
          </app-cds-output-field>
          <h3>Planung</h3>
          <app-cds-output-field
            [label]="'Termin Maßaufnahme'"
            [value]="cdsOpportunity.std_terminmassaufnahme_date | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Termin W&M-Planung'"
            [value]="cdsOpportunity.std_terminwmplanung_date | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Termin Elektroplanung'"
            [value]="cdsOpportunity.std_terminelektroplanung | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <h3>Montage</h3>
          <app-cds-output-field
            [label]="'Startdatum Ausführung'"
            [value]="cdsOpportunity.std_startdatumausfhuerung_date | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Enddatum Ausführung'"
            [value]="cdsOpportunity.std_enddatumausfuhrungprojektdate | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Termin Abnahme'"
            [value]="cdsOpportunity.std_terminabnahme_date | date: 'dd.MM.yyyy':'UTC'"
          >
          </app-cds-output-field>
          <app-cds-output-field
            [label]="'Termin Inbetriebnahme'"
            [value]="cdsOpportunity.std_inbetriebnahme_date | date: 'dd.MM.yyyy':'UTC'"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Termin Endabnahme Kunde'"
            [value]="cdsOpportunity.std_termin_endabnahme_kunde | date: 'dd.MM.yyyy':'UTC'"
          >
          </app-cds-output-field>
        </ng-container>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon mat-card-avatar>build</mat-icon>
        </div>
        <mat-card-title>Montage/Abladung</mat-card-title>
        <mat-card-subtitle>#</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h3>Allgemein</h3>
        <app-cds-output-field
          [label]="'Montagehilfe durch'"
          [value]="cdsOpportunity['std_abladung_durchcode@OData.Community.Display.V1.FormattedValue']"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Art der Montagehilfe'"
          [value]="cdsOpportunity['std_art_der_montagehilfecode@OData.Community.Display.V1.FormattedValue']"
        >
        </app-cds-output-field>
        <app-cds-output-field
          [label]="'Abladung durch'"
          [value]="cdsOpportunity['std_abladung_durchcode@OData.Community.Display.V1.FormattedValue']"
        >
        </app-cds-output-field>
        <app-cds-output-field
          [label]="'Art der Abladung'"
          [value]="cdsOpportunity['std_art_der_abladungcode@OData.Community.Display.V1.FormattedValue']"
        >
        </app-cds-output-field>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="33%">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><mat-icon mat-card-avatar>contacts</mat-icon></div>
        <mat-card-title>Externe Kontakte</mat-card-title>
        <mat-card-subtitle>Projektbeteiligte</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let cdsProjektbeteiligte of cdsProjektbeteiligteList">
          <h3 *ngIf="!!cdsProjektbeteiligte.std_rolleid?.std_rollenbezeichnung">
            {{ cdsProjektbeteiligte.std_rolleid.std_rollenbezeichnung }}
          </h3>
          <h3 *ngIf="!cdsProjektbeteiligte.std_rolleid?.std_rollenbezeichnung">Sonstige</h3>
          <app-cds-output-field
            [label]="'Name'"
            [value]="cdsProjektbeteiligte.std_kontaktid?.fullname"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'E-Mail'"
            [value]="cdsProjektbeteiligte.std_kontaktid?.emailaddress1"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Mobil'"
            [value]="cdsProjektbeteiligte.std_kontaktid?.mobilephone"
          ></app-cds-output-field>
          <app-cds-output-field
            [label]="'Telefon'"
            [value]="cdsProjektbeteiligte.std_kontaktid?.telephone1"
          ></app-cds-output-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><mat-icon mat-card-avatar>supervisor_account</mat-icon></div>
        <mat-card-title>Vertriebsteam</mat-card-title>
        <mat-card-subtitle>{{ cdsOpportunity.std_vertriebsteamid?.std_name }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h3>Vertriebsaußendienst</h3>
        <app-cds-output-field
          [label]="'Name'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_benutzerid.fullname"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'E-Mail'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_benutzerid.internalemailaddress"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Mobil'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_benutzerid.mobilephone"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Telefon'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_benutzerid.address1_fax"
        ></app-cds-output-field>
        <h3>Vertriebsinnendienst</h3>
        <app-cds-output-field
          [label]="'Name'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_vertriebsinnendienstid.fullname"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'E-Mail'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_vertriebsinnendienstid.internalemailaddress"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Mobil'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_vertriebsinnendienstid.mobilephone"
        ></app-cds-output-field>
        <app-cds-output-field
          [label]="'Telefon'"
          [value]="cdsOpportunity.std_vertriebsteamid?.std_vertriebsinnendienstid.address1_fax"
        ></app-cds-output-field>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
