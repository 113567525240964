import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SPSProject } from '../../../models/sps-project';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectApiService } from '../../../service/project-api.service';
import { FormBuilder, Validators, FormGroup, UntypedFormControl } from '@angular/forms';
import { GraphUser } from '../../../models/graph-user';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSBuildingProject } from '../../../models/cds/cds-building-project';
import { CDSProject } from '../../../models/cds/cds-project';
import { ButtonConfirmComponent } from '../../helper/button-confirm/button-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogGenerateProjectPlan } from './dialog-generate-project-plan';
import { CDSOpportunity } from '../../../models/cds/cds-opportunity';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  buildingProject: CDSBuildingProject;
  cdsProject: CDSProject;
  spsProject: SPSProject;
  isNew: boolean;
  enableProgressBar: boolean = false;
  buttonDisabled: boolean = true;

  snackBarDuration: number = 3;
  buildingProjectList: CDSBuildingProject[];
  cdsOpportunityList: CDSOpportunity[];
  canLoadPositions: boolean = false;
  projectFormGroup: FormGroup;
  projectTeamFormGroup: FormGroup;
  projectInstallationFormGroup: FormGroup;
  projectExternalLink: string;
  documentExternalLink: string;
  installationStartTime: any;
  installationEndTime: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public projectApiService: ProjectApiService,
    private cdsApiService: CDSApiService,
    private location: Location,
    private dateAdapter: DateAdapter<Date>,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.cdsProject = new CDSProject();
    this.buildingProject = new CDSBuildingProject();
    this.spsProject = new SPSProject();

    this.isNew = false;
    this.dateAdapter.setLocale('de');

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.projectFormGroup = this._formBuilder.group({
      name: new UntypedFormControl({ value: '' }),
      orderNumber: new UntypedFormControl({ value: '' }),
      customer: new UntypedFormControl({ value: '' }),
      IdSmartsheetSheet: new UntypedFormControl({ value: '' }),
      permalink: new UntypedFormControl({ value: '' })
    });

    this.projectTeamFormGroup = this._formBuilder.group({
      _std_worker_projektleiter_stoebichid_value: new UntypedFormControl({ value: null, disabled: true }),
      _std_worker_konstrukteur_mechid_value: new UntypedFormControl({ value: null, disabled: true }),
      _std_worker_konstrukteur_elektid_value: new UntypedFormControl({ value: null, disabled: true }),
      _std_worker_kaufmaennisch_stoebich_value: new UntypedFormControl({ value: null, disabled: true })
    });

    this.projectInstallationFormGroup = this._formBuilder.group({
      _std_worker_montageleiter_stoebich_value: new UntypedFormControl({ value: null, disabled: true })
    });

    this.loadProject();
  }

  btnClickNavigateBack() {
    this.location.back();
  }

  loadProject() {
    this.enableProgressBar = true;

    this.route.paramMap.subscribe((params) => {
      console.log('##### loadProject #####');

      this.cdsApiService
        .getEntity(
          'std_auftrags',
          params.get('id'),
          'std_worker_projektleiter_stoebichid($expand=std_benutzerid),std_kundeid,std_bauvorhabenid,std_std_projektbeteiligte_std_auftrag'
        )
        .subscribe(
          (project: CDSProject) => {
            this.cdsProject = project;

            this.cdsApiService
              .getAllEntity(
                `opportunities?$filter=std_auftragid/std_auftragid%20eq%20${project.std_auftragid}&$expand=std_gebietid($select=name)`
              )
              .subscribe(
                (response: CDSApiResponse) => {
                  this.cdsOpportunityList = response.value;
                },
                (err: any) => console.error(err),
                () => (this.enableProgressBar = false)
              );

            this.projectApiService.getProjectByCDSId(project.std_auftragid).subscribe(
              (response: SPSProject) => {
                this.buttonDisabled = false;
                this.spsProject = response;
                this.spsProject.orderNumber = this.cdsProject.std_auftragsnummer;
                this.spsProject.customer = this.cdsProject.std_kundeid;

                this.canLoadPositions = true;
                this.enableProgressBar = false;
                this.updateFormData();
              },
              () => {
                this.enableProgressBar = false;
                this.updateFormData();
              }
            );
            this.projectExternalLink = this.cdsApiService.getExternalLink('std_auftrag', this.cdsProject.std_auftragid);
            this.documentExternalLink = `${environment.d3.host}/dms/r/a05d2756-870c-58a3-a07b-95f084e3b748/sr/?objectdefinitionids=%5B%22DAUF%22%5D&properties=%7B%2239%22%3A%5B%22${this.cdsProject.std_auftragsnummer}%22%5D%7D&propertysort=property_last_modified_date&ascending=false&showdetails=true&usecache=true`;
          },
          (err: any) => console.error(err)
        );
    });
  }

  setContact(user: GraphUser, index: string): void {
    this.spsProject[index] = user.id;
  }

  updateFormData() {
    this.projectFormGroup.patchValue({
      name: this.cdsProject.std_auftrag,
      orderNumber: this.cdsProject.std_auftragsnummer,
      _std_bauvorhabenid_value: this.cdsProject._std_bauvorhabenid_value,
      customer: this.cdsProject.std_kundeid?.name,
      IdSmartsheetSheet: this.spsProject.IdSmartsheetSheet,
      permalink: this.spsProject.permalink
    });

    this.projectTeamFormGroup.patchValue({
      _std_worker_projektleiter_stoebichid_value: this.cdsProject._std_worker_projektleiter_stoebichid_value,
      _std_worker_konstrukteur_mechid_value: this.cdsProject._std_worker_konstrukteur_mechid_value,
      _std_worker_konstrukteur_elektid_value: this.cdsProject._std_worker_konstrukteur_elektid_value,
      _std_worker_kaufmaennisch_stoebich_value: this.cdsProject._std_worker_kaufmaennisch_stoebich_value
    });

    this.projectInstallationFormGroup.patchValue({
      _std_worker_montageleiter_stoebich_value: this.cdsProject._std_worker_montageleiter_stoebich_value
    });
  }

  btnClickSaveProject() {
    if (this.projectFormGroup.valid) {
      localStorage.removeItem('activeBuildingProject');
      this.enableProgressBar = true;
      this.cdsProject = Object.assign(this.cdsProject, this.projectFormGroup.value);

      this.projectApiService.updateProject(this.spsProject).subscribe(
        (res: SpsApiResponse) =>
          this.snackBar.open('gespeichert', 'ok', {
            duration: this.snackBarDuration * 1000
          }),
        (err: any) => {
          console.error(err);
          this.snackBar.open('error:' + err, 'ok', {
            duration: this.snackBarDuration * 1000
          });
        },
        () => (this.enableProgressBar = false)
      );
    }
  }

  btnClickDeleteProject() {
    const dialogRef = this.dialog.open(ButtonConfirmComponent, {
      width: '500px',
      data: {
        title: 'Projekt löschen',
        text: 'wollen Sie das Projekt löschen?',
        btnNo: 'Nein',
        btnYes: 'Ja'
      }
    });

    dialogRef.afterClosed().subscribe((conf) => {
      if (conf) {
        this.projectApiService.deleteProject(this.spsProject.id).subscribe(
          (res: SpsApiResponse) => {
            this.router.navigate(['/sps/buildingProject', this.buildingProject.std_bauvorhabenid]);
            this.snackBar.open('gelöscht', 'ok', {
              duration: this.snackBarDuration * 1000
            });
          },
          (err: any) => console.error(err),
          () => (this.enableProgressBar = false)
        );
      }
    });
  }

  btnClickCreateProjectPlan(): void {
    console.log('#####cds Opportunity ######');
    //console.log(this.cdsOpportunityList[0]);

    const dialogRef = this.dialog.open(DialogGenerateProjectPlan, {
      width: '500px',
      data: { data: this.cdsProject, opportunity: this.cdsOpportunityList[0] }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadProject();
    });
  }

  onClickProjectExternalLink() {
    window.open(this.projectExternalLink, '_blank');
  }

  onClickDocumentExternalLink() {
    window.open(this.documentExternalLink, '_blank');
  }
  onClickSmartsheet(link) {
    window.open(link, '_blank');
  }
}
