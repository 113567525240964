<h1 mat-dialog-title>Neue Position</h1>
<div mat-dialog-content *ngIf="!enableProgressSpinner">
  <form [formGroup]="posFormGroup">
    <mat-form-field class="full-width">
      <mat-label>Positionnummer</mat-label>
      <input matInput type="text" class="form-control" id="posNumber" [required]="true" formControlName="posNumber" />
    </mat-form-field>

    <!--
        <mat-form-field class="full-width">
            <mat-label>Montageposition</mat-label>
            <input matInput type="text" class="form-control" id="posNumberAlt" [required]="true" formControlName="posNumberAlt">
        </mat-form-field>
        -->
    <mat-form-field class="full-width">
      <mat-label>Arbeitsauftragstyp</mat-label>
      <mat-select [(value)]="spsPos.spsPosType" formControlName="spsPosType">
        <mat-option *ngFor="let spsPosType of spsPosTypeList" [value]="spsPosType">
          {{spsPosType.posTitle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Produkttyp</mat-label>
      <input type="text" placeholder="Produkttyp" matInput formControlName="cdsIncidentType" [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayName">
        <mat-option *ngFor="let cdsIncidentType of  filteredOptions | async;" [value]="cdsIncidentType">
          {{cdsIncidentType.msdyn_name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <mat-divider></mat-divider>

  <div class="button-bar">
    <button mat-button (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button [disabled]="!posFormGroup.valid" color="primary" (click)="btnClickAddProjectPosition()">
      Position hinzufügen
    </button>
  </div>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
