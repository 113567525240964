import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { DataboxApiConfig } from '../models/databox-api-config';

@Injectable({
  providedIn: 'root'
})
export class DataboxApiClientService {
  HOST: string = 'https://api.stoebich.com';

  config: DataboxApiConfig;
  lang: string = "1";
  header: any;

  constructor(private http: HttpClient) {}
  
  setConfig(cataboxApiConfig: DataboxApiConfig): void {
    this.config = cataboxApiConfig;
    this.setHeader(cataboxApiConfig);
  }

  getToken() : Observable<DataboxApiConfig> {
    return this.http.get<DataboxApiConfig>(this.HOST + "/APILogin")
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getProductGroup(lang?: string) {
    let lang1 = (lang != undefined) ? lang : Number(1);
    return this.http.get<any>(this.HOST + "/v1/" + lang1 + "/productGroup",this.header)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getProduct(id: number, lang?: number) {
    lang = (lang != undefined) ? lang : Number(this.lang);
    return this.http.get<any>(this.HOST + "/v1/" + lang + "/product/" + id , this.header)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getLanguages() {
    return this.http.get<any>(this.HOST + "/v1/" + "languages", this.header)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getPDF(id: number): string {
    return this.HOST + "/public/pdf/" + this.lang + "/product/" + id;
  }
  
  setHeader(cataboxApiConfig: DataboxApiConfig): void {
    this.header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + cataboxApiConfig.token
      })
    };
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      '[Error] Databox connection faild; please try again later.');
  };
}
