<mat-form-field  class="full-width">
    <mat-label><ng-content></ng-content></mat-label>
    <input *ngIf="disabled" type="text" matInput [formControl]="pickerControl" readonly [matAutocomplete]="auto">
    <input *ngIf="!disabled" type="text" matInput [formControl]="pickerControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='setSelectdAccount($event.option.value)' [displayWith]="displayFn">
        <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>
        <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let account of accountList" [value]="account">
                {{account.name}}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>

<mat-option *ngIf="accountIsLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>

<div class="graph-account-details" *ngIf="!!selectedAccount">
    <mat-list>
        <app-cds-output-field  [label]="'Kundennummer'" [value]="selectedAccount?.accountnumber"></app-cds-output-field>
        <app-cds-output-field  [label]="'Straße'" [value]="selectedAccount?.address1_line1"></app-cds-output-field>
        <app-cds-output-field  [label]="'PLZ'" [value]="selectedAccount?.address1_postalcode"></app-cds-output-field>
        <app-cds-output-field  [label]="'Stadt'" [value]="selectedAccount?.address1_city"></app-cds-output-field>
        <app-cds-output-field  [label]="'Telefon'" [value]="selectedAccount?.telephone1"></app-cds-output-field>
        <app-cds-output-field  [label]="'E-Mail'" [value]="selectedAccount?.emailaddress1"></app-cds-output-field>
    </mat-list>
</div>