<div class="main">
  <mat-icon color="primary" (click)="closeDialog()">
    close</mat-icon
  >
  <h1 mat-dialog-title>Please add your email in Field Service</h1>
  <div mat-dialog-content *ngIf="!enableProgressSpinner">
    <span>
      <a target="_blank" [href]="projectExternalLink">Click here</a>
    </span>
    <hr />
    <img src="/assets/img/email.png" alt="image" />
  </div>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
