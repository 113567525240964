import { CDSAccount } from './cds/cds-account';
import { CDSSystemUsers } from './cds/cds-systemusers';
import { SpsPos } from './sps-pos';

export class SPSProject {
    idCDSStdAuftrag: string;
    orderNumber: string;
    status?: number;
    customer?: CDSAccount;
    startDate: string;

    smartsheetfoldermatch?: string;
    id?: number;
    IdSmartsheetSheet?: number;
    permalink?: string;
    spsPos?: SpsPos[];
    idSpsContactMechConstruction?: string;
    projectStartDate?: string;
    projectEndDate?: string;
    generalStartDate?: string;
    generalEndDate?: string;
    installationStartDate?: string;
    installationEndDate?: string;
    projectmanager?: CDSSystemUsers;
}