import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatasheetComponent } from './components/datasheet/datasheet.component';
import { SurveyComponent } from './components/survey/survey.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProjectComponent } from './components/project/project.component';
import { BuildingProjectListComponent } from './components/project/building-project-list/building-project-list.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { BuildingProjectDetailComponent } from './components/project/building-project-detail/building-project-detail.component';
import { ProjectDetailComponent } from './components/project/project-detail/project-detail.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './components/login/login.component';
import { RoleGuard } from './role.gard';
import { PositionDetailComponent } from './components/project/position-detail/position-detail.component';
import { OpportunityListComponent } from './components/project/opportunity-list/opportunity-list.component';


export const routes: Routes = [
  { 
    path: '', 
    pathMatch: 'full',
    component: DashboardComponent 
  },
  { 
    path: 'login', 
    component: LoginComponent 
  },
  { 
    path: 'datasheet', 
    data: {
      title: "Dashboard",
      disabled: false,
      icon: "dashboard"
    },
    // canActivate : [MsalGuard],
    component: DatasheetComponent 
  },
  { 
    path: 'survey', 
    data: {
      roles: [ 'administrator','surveymanager' ],
      title: "Umfragen",
      disabled: false,
      icon: "thumbs_up_down"
    },
    canActivate : [MsalGuard, RoleGuard],
    component: SurveyComponent 
  },
  { 
    path: 'sps/opportunity', 
    data: {      
      roles: [ 'administrator','projectmanager'],
      title: "Verkaufschancen",
      altPath: "sps/opportunity",
      disabled: false,
      icon: "assignment_late"
    },
    canActivate : [MsalGuard,RoleGuard],
    children: [
      { 
        path: '',
        pathMatch: 'full',
        component: OpportunityListComponent  
      }
    ]
  },
  { 
    path: 'sps/buildingProject', 
    data: {
      roles: [ 'administrator','projectmanager','projectteam'],
      title: "Bauvorhaben",
      altPath: "sps/buildingProject",
      disabled: false,
      icon: "construction"
    },
    canActivate : [MsalGuard,RoleGuard],
    children: [
      { 
        path: '',
        pathMatch: 'full',
        component: BuildingProjectListComponent  
      },
      { 
        path: ':id',
        pathMatch: 'full',
        component: BuildingProjectDetailComponent  
      }
    ]
  },
  { 
    path: 'sps/project', 
    data: {
      roles: [ 'administrator','projectmanager','projectteam'],
      title: "Projekte",
      altPath: "sps/project",
      disabled: false,
      icon: "inventory_2"
    },
    canActivate : [MsalGuard,RoleGuard],
    children: [
      { 
        path: '',
        pathMatch: 'full',
        component: ProjectListComponent  
      },
      { 
        path: ':id',
        pathMatch: 'full',
        component: ProjectDetailComponent  
      }
    ]
  }
  //{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
