import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { GraphApiService } from '../../service/graph-api.service';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { GraphUser } from '../../models/graph-user';

@Component({
  selector: 'app-graph-user-picker',
  templateUrl: './graph-user-picker.component.html',
  styleUrls: ['./graph-user-picker.component.scss']
})
export class GraphUserPickerComponent implements OnInit, OnChanges {
  pickerControl = new FormControl();
  userList: GraphUser[];
  isLoading: boolean;
  userIsLoading: boolean;
  selectedUser: GraphUser;
  
  @Input() label: string;
  @Input() value: string;
  @Output() change = new EventEmitter();

  constructor(private graphApiService: GraphApiService) { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges) {
    if(this.value != null) {
      this.userIsLoading = true;
      this.graphApiService.getUser(this.value)
      .subscribe(
        response => {
          this.selectedUser = response;
          this.pickerControl.setValue(response);
        },
        (err: any) => console.error(err),
        () => this.userIsLoading = false
      );
    }
  }

  ngOnInit() {

    this.pickerControl
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.graphApiService.searchUser(value)
        .pipe(
          finalize(() => this.isLoading = false),
        )
      )
    )
    .subscribe(
      response => this.userList = response.value,
    );
  }  

  setSelectdUser(user: GraphUser) {
    this.change.emit(user);
    this.selectedUser = user;
  }
  
  displayFn(user: GraphUser): string {
    return user && user.displayName ? user.displayName : '';
  }
}
