import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cds-output-field',
  templateUrl: './cds-output-field.component.html',
  styleUrls: ['./cds-output-field.component.scss']
})
export class CdsOutputFieldComponent implements OnInit {
  
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() value: string;
  @Input() type: string;

  constructor() { 
    this.type = (!!this.type) ? this.type : "default";
  }

  ngOnInit(): void {
  }

}
