import { Injectable } from '@angular/core';
import { CDSApiResponse } from '../models/cds/cds-api-response';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CDSEntity } from '../models/cds/cds-entity';
import { environment } from './../../environments/environment';
import { Http } from '@shild/data-access/odata/core';

@Injectable({
  providedIn: 'root'
})
export class CDSApiService {
  BASE_URL: string = environment.cds.host;
  APP_ID: string = environment.cds.app.fieldservice;
  VERSION: string = environment.cds.version;
  PAGE_TYPE: string = 'entityrecord';
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'OData-MaxVersion': '4.0',
    'OData-Version': '4.0',
    Prefer: 'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
  });

  constructor(private http: HttpClient) {}

  getAllEntity(entity: string, expand = null): Observable<CDSApiResponse> {
    const collapseString = !!expand ? `?$expand=${expand}` : '';

    let endpointUrl = `${this.BASE_URL}/api/data/${this.VERSION}/${entity}${collapseString}`;
    return this.http.get<CDSApiResponse>(endpointUrl).pipe(retry(3), catchError(this.handleError));
  }

  getEntity(entity: string, id: string, expand = null): Observable<CDSEntity> {
    const collapseString = !!expand ? `?$expand=${expand}` : '';
    let endpointUrl = `${this.BASE_URL}/api/data/${this.VERSION}/${entity}(${id})${collapseString}`;
    return this.http.get<CDSEntity>(endpointUrl).pipe(retry(3), catchError(this.handleError));
  }

  getEntityWithHeaders(entity: string, id: string, expand = null): Observable<CDSEntity> {
    const options = { headers: this.headers };
    const collapseString = !!expand ? `?$expand=${expand}` : '';
    let endpointUrl = `${this.BASE_URL}/api/data/${this.VERSION}/${entity}(${id})${collapseString}`;
    return this.http.get<CDSEntity>(endpointUrl, options).pipe(retry(3), catchError(this.handleError));
  }

  getEntityByFilter(entity: string, param: string, id: string, expand = null): Observable<CDSApiResponse> {
    const collapseString = !!expand ? `&$expand=${expand}` : '';
    return this.http
      .get<CDSApiResponse>(
        `${this.BASE_URL}/api/data/${this.VERSION}/${entity}?$filter=${param}%20eq%20${id}${collapseString}`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  getEntitiesByRelation(entity: string, id: string, relEntity: string): Observable<CDSApiResponse> {
    return this.http
      .get<CDSApiResponse>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}(${id})/${relEntity}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  getEntitySearch(entity: string, field: string, value: string): Observable<CDSApiResponse> {
    return this.http
      .get<CDSApiResponse>(
        `${this.BASE_URL}/api/data/${this.VERSION}/${entity}?$filter=startswith(${field},'${value}')`
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  getEntitySearchWithFilters(entity: string, filter: string): Observable<CDSApiResponse> {
    return this.http
      .get<CDSApiResponse>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}?$filter=${filter}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  createEntity(entity: string, data: any): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}`, data, { observe: 'response' as 'body' })
      .pipe(retry(3), catchError(this.handleError));
  }

  createEntityNew(entity: string, data: any): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}`, data, {
        headers: { prefer: 'return=representation' },
        observe: 'response' as 'body'
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  createRelationTable(entity, data): Observable<any> {
    return this.http
      .post<any>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}`, data, {
        headers: {
          Accept: 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0'
        }
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  updateEntity(entity: string, id: string, data: any): Observable<any> {
    return this.http
      .patch<any>(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}(${id})`, data, { observe: 'response' as 'body' })
      .pipe(retry(3), catchError(this.handleError));
  }

  deleteEntity(entity: string, id: string): Observable<any> {
    return this.http
      .delete(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}(${id})`)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteRelationTable(entity: string): Observable<any> {
    return this.http
      .delete(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  getDefault(field: string): any {
    return environment.cds.default[field];
  }

  getBaseLink(): string {
    return `${this.BASE_URL}/main.aspx?appid=${this.APP_ID}&pagetype=${this.PAGE_TYPE}&`;
  }

  getExternalLink(etn: string, id: string): string {
    return this.getBaseLink() + `etn=${etn}&id=${id}`;
  }

  getResponseIdByHeader(headerString: string): string {
    if (!headerString) throw new Error('odata-entityid is undefined');

    console.log(headerString.match(/\(([a-z|0-9|-]*)\)/));
    return headerString.match(/\(([a-z|0-9|-]*)\)/)[1];
  }

  getWorkorderServiceTasks(entity: string, id: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/api/data/${this.VERSION}/${entity}${id}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      //   console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(`[Error] ${error.error.error.message}`);
  }
}
