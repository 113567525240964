<mat-divider></mat-divider>
<mat-toolbar>
  <!-- <button mat-icon-button color="primary"  (click)="btnClickSaveBuildingProject()"><mat-icon>save</mat-icon></button> -->
  <button mat-icon-button (click)="btnClickNavigateBack()"><mat-icon>arrow_back</mat-icon></button>
  <button mat-icon-button [disabled]="isNew" (click)="loadBuildingProject()"><mat-icon>autorenew</mat-icon></button>

  <span class="spacer"></span>
  <button mat-button target="_blank" (click)="onBuildingProjectExternalLink(buildingProjectExternalLink)">
    in Field Service anzeigen
  </button>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="wrapper">
  <div [hidden]="isNew">
    <h2>
      {{ buildingProject.std_name }}<br />
      <small>Bauvorhaben</small>
    </h2>
  </div>

  <div [hidden]="!isNew">
    <h2>Neues Bauvorhaben</h2>
  </div>

  <mat-tab-group *ngIf="!enableProgressBar">
    <mat-tab label="Allgemein">
      <div class="mat-tab-wrapper">
        <div
          class="container"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="0.5%"
          fxLayoutAlign="center"
        >
          <div fxFlex="33%">
            <mat-card>
              <mat-card-content>
                <form [formGroup]="buildingProjectFormGroup">
                  <mat-form-field class="full-width">
                    <mat-label>Name des Bauvorhabens</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="std_name"
                      [required]="true"
                      formControlName="std_name"
                      readonly
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Eigentümer</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="std_eigentuemerid"
                      formControlName="std_eigentuemerid"
                      readonly
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Straße</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="std_adresse1_strasse1"
                      formControlName="std_adresse1_strasse1"
                      readonly
                    />
                  </mat-form-field>

                  <table class="full-width" cellspacing="0">
                    <tr>
                      <td>
                        <mat-form-field class="full-width">
                          <mat-label>Ort</mat-label>
                          <input
                            matInput
                            type="text"
                            class="form-control"
                            id="std_adresse1_ort"
                            formControlName="std_adresse1_ort"
                            readonly
                          />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field class="full-width">
                          <mat-label>Postleitzahl</mat-label>
                          <input
                            matInput
                            type="text"
                            class="form-control"
                            id="std_addresse1_postleitzahl"
                            formControlName="std_addresse1_postleitzahl"
                            maxlength="5"
                            readonly
                          />
                          <!-- <mat-hint align="end">{{code.value.length}} / 5</mat-hint> -->
                        </mat-form-field>
                      </td>
                    </tr>
                  </table>

                  <mat-form-field class="full-width">
                    <mat-label>Land</mat-label>
                    <input
                      matInput
                      type="text"
                      class="form-control"
                      id="std_adresse1_land"
                      formControlName="std_adresse1_land"
                      readonly
                    />
                  </mat-form-field>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="33%"></div>
          <div fxFlex="33%"></div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="!isNew" label="Projekte">
      <div class="mat-tab-wrapper">
        <app-project-list *ngIf="canLoadProjects" [buildingProject]="buildingProject"></app-project-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
