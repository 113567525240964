<mat-toolbar>
    <button mat-button color="primary" (click)="btnClickAddProjectPosition()"><mat-icon>add</mat-icon> Neu</button>
    <button mat-icon-button (click)="loadProjectPositionList()"><mat-icon>autorenew</mat-icon></button>
    <span class="spacer"></span>
    <button mat-button [disabled]="spsPosList.data.length === 0" (click)="btnClickCalcProjectTimePlan()"><mat-icon>update</mat-icon> Zeitplan berechnen</button>
    <button mat-button [disabled]="spsPosList.data.length === 0" (click)="btnClickUpdateProjectPlan()"><mat-icon>system_update_alt</mat-icon> PSP aktualisieren</button>
    <mat-spinner *ngIf="enableCalcProjectTimePlan" diameter="30"></mat-spinner>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>

<div *ngIf="!project" class="headline-wrapper"><h2>Alle Projekte</h2></div>
<table mat-table [dataSource]="spsPosList" multiTemplateDataRows matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="posNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">Positionnummer</th>
        <td mat-cell *matCellDef="let element">
            {{element.posNumber}}
            <!-- <a [routerLink]="['/sps/projectPosition/', element.id]">{{element.posNumber}}</a> -->
        </td>
    </ng-container>

    <!--
    <ng-container matColumnDef="posNumberAlt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Service-Position</th>
        <td mat-cell *matCellDef="let element"> {{element.posNumberAlt}} </td>
    </ng-container>
    -->

    <!-- Symbol Column -->
    <ng-container matColumnDef="idCDSIncidentTypeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> Produkt </th>
        <td mat-cell *matCellDef="let element"> {{element.idCDSIncidentTypeName || element.spsPosType.posTitle}} </td>
    </ng-container>

    <!-- *ngIf="element.workorderRequest === 1" -->
    <!-- Symbol Column -->
    <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Montage</th>
        <td mat-cell *matCellDef="let element" class="align-right">

            <span *ngIf="!!element.startDateMech">{{ element.startDateMech | date:'dd.MM.yyyy' }} - {{ element.endDateMech  | date:'dd.MM.yyyy'}}</span>
            <span *ngIf="!element.startDateMech">Zeitplan noch nicht berechnet</span>

        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="table-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="table-element-detail-body">
                <div class="table-element-detail-header">
                    <h3>Arbeitsaufträge</h3>
                    <span class="spacer"></span>
                    <button color="primary" [disabled]="!element.startDateMech || element.workorder.length >= 1" mat-button
                        (click)="btnClickGenerateWorkOrder(element)">Arbeitsauftrag erstellen</button>
                    <button mat-button (click)="btnClickDeleteProjectPosition(element)">Löschen</button>
                </div>
                <app-workorder-list *ngIf="!!element.workorder" [dataSource]="element.workorder" [position] = "element" [project] = "project"></app-workorder-list>
            </div>
            <div></div>
        </div>
        </td>
    </ng-container>

    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="table-element-row"
        [class.table-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [pageSize]="200" [pageSizeOptions]="[10, 25, 50, 100, 200]"></mat-paginator>
