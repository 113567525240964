import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSOpportunity } from '../../../models/cds/cds-opportunity';
import { CDSProjektbeteiligte } from '../../../models/cds/cds-projektbeteiligte';
import { CDSApiService } from '../../../service/cds-api.service';
import { List } from 'linqts';
import { tap, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit, OnDestroy {
  enableProgressSpinner: boolean = false;

  cdsOpportunity: CDSOpportunity;
  cdsProjektbeteiligteList: CDSProjektbeteiligte[];
  private subDestroy = new Subject();

  @Input() dataSource: string;
  @Input() std_std_projektbeteiligte_std_auftrag: CDSProjektbeteiligte[];

  constructor(private cdsApiService: CDSApiService) {
    this.cdsOpportunity = new CDSOpportunity();
    this.cdsProjektbeteiligteList = [];
  }

  ngOnInit(): void {
    this.loadOpportunityList(this.dataSource);
  }

  loadOpportunityList(id: string): void {
    this.enableProgressSpinner = true;

    this.cdsApiService
      .getEntityWithHeaders(
        'opportunities',
        id,
        `std_bauvorhabenid,std_projektleiter_stoebich,std_gebietid($select=name),opportunity_OpportunityCloses($filter=statecode%20eq%201),opportunity_quotes($filter=statecode%20eq%202;$expand=msdyn_paymentterms),std_vertriebsteamid($expand=std_benutzerid,std_vertriebsinnendienstid)`
      )
      .subscribe(
        (response: CDSOpportunity) => {
          this.cdsOpportunity = response;

          if (!!response.opportunity_quotes[0]) {
            //this.loadContacts(response.opportunity_quotes[0].quoteid);
            this.loadContacts('', response.opportunityid, response.opportunity_quotes[0].quoteid);
          } else {
            //this.loadContacts(this.dataSource);
            this.loadContacts(this.dataSource, '', '');
          }
        },
        (err: any) => console.error(err),
        () => (this.enableProgressSpinner = false)
      );
  }

  loadContacts(id: string, opportunityId: string, quoteid: string): void {
    this.enableProgressSpinner = true;
    let arr = new List<CDSProjektbeteiligte>(this.std_std_projektbeteiligte_std_auftrag);
    let ids = arr.Select((y) => y.std_projektbeteiligteid).ToArray();

    // // Resolve "beteiligte" via opportunityid or via quoteid"
    // this.cdsApiService.getAllEntity(`std_projektbeteiligtes?$filter=std_verkaufschanceid/opportunityid%20eq%20${opportunityId}%20or%20std_angebotid/quoteid%20eq%20${quoteid}&$expand=std_rolleid,std_kontaktid`)

    this.cdsApiService
      .getAllEntity(
        // `std_projektbeteiligtes?$filter=std_verkaufschanceid/opportunityid%20eq%20${opportunityId}%20and%20statecode%20eq%200&$expand=std_rolleid,std_kontaktid`
        `std_projektbeteiligtes?$filter=(std_verkaufschanceid/opportunityid eq ${opportunityId} and statecode eq 0) or (std_angebotid/quoteid eq ${quoteid} and statecode eq 0)&$expand=std_rolleid,std_kontaktid`
      )
      .pipe(
        take(1),
        takeUntil(this.subDestroy),
        mergeMap((data) => {
          if (data.value.length > 0) {
            return of(data);
          }
          //   else {
          //     return this.cdsApiService.getAllEntity(
          //       `std_projektbeteiligtes?$filter=std_angebotid/quoteid%20eq%20${quoteid}%20and%20statecode%20eq%200&$expand=std_rolleid,std_kontaktid`
          //     );
          //   }
        })
      )
      .subscribe({
        next: (res) => {
          console.log('CONTACTS', res.value);
          this.cdsProjektbeteiligteList = res.value;
        },
        error: (err) => {
          console.log(err);
        }
      });

    if (ids.length >= 0) {
      let counter = 0;
      let filter = '';

      ids.forEach((element) => {
        switch (counter) {
          case 0: {
            filter += `std_projektbeteiligteid%20eq%20${element}`;
            break;
          }
          default: {
            filter += `%20or%20std_projektbeteiligteid%20eq%20${element}`;
            break;
          }
        }
        counter += 1;
      });

      if (filter.length > 0) {
        filter = `$filter=${filter}&`;

        // Resolve "beteiligte" via std_projektbeteiligteid of std_auftrag=>std_projektbeteiligteid"
        this.cdsApiService.getAllEntity(`std_projektbeteiligtes?${filter}$expand=std_rolleid,std_kontaktid`).subscribe(
          (response: CDSApiResponse) => {
            response.value.forEach((cdsProjektbeteiligte) => {
              this.cdsProjektbeteiligteList.push(cdsProjektbeteiligte);
            });
          },
          (err: any) => console.error(err),
          () => (this.enableProgressSpinner = false)
        );
      }
    }
  }

  ngOnDestroy() {
    this.subDestroy.next(null);
    this.subDestroy.complete();
  }
}
