import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GraphApiService {

  HOST: string = 'https://graph.microsoft.com/v1.0';

  constructor(private http: HttpClient) {}

  getMe() : Observable<any> {
    return this.http.get<any>(this.HOST + "/me")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getMyPhoto() : Observable<any> {
    return this.http.get<any>(this.HOST + "/me/photo")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getUser(id: string) {    
    return this.http.get<any>(this.HOST + "/users/" + id)
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getMyPhotos(size: string) : Observable<any> {
    return this.http.get<any>(this.HOST + "/me/photos/" + size)
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getUsers() : Observable<any> {
    return this.http.get<any>(this.HOST + "/users")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  searchUser(value: string) : Observable<any> {
    console.log('#### search User');
    console.log(value);
    return this.http.get<any>(this.HOST + "/users?$filter=startswith(displayName,'" + value + "')")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      '[Error] Graph Api connection faild; please try again later.');
  };

}
