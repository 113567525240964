<mat-list>
  <!-- <div mat-subheader></div> -->
  <mat-list-item *ngIf="spsWorkorderServiceList.length > 0 && !asyncProblem">
    <span mat-list-icon>{{ spsWorkorderServiceList[0].std_positionsnummer }}</span>
    <div mat-line *ngIf="!spsWorkorderServiceList[0].std_default">
      <strong>{{ spsWorkorderServiceList[0].msdyn_name }}</strong>
    </div>
    <div mat-line *ngIf="!!spsWorkorderServiceList[0].std_default"><strong>Vorgabe</strong></div>
    <div mat-line>Erstellt am: {{ spsWorkorderServiceList[0].createdon | date: 'dd.MM.yyyy' }}</div>
    <div mat-line>
      <div class="icon-list">
        <div class="icon-list-element">
          <mat-icon mat-list-icon>access_time</mat-icon>
          <span>
            {{ fullTime }}
          </span>
        </div>
        <div class="icon-list-element">
          <mat-icon mat-list-icon>people</mat-icon>
          {{ spsWorkorderServiceList.length }}
        </div>
        <!-- <div class="icon-list-element" *ngIf="!spsWorkorderServiceList[0].std_default">
          <mat-icon mat-list-icon>assignment_turned_in</mat-icon>
          {{ spsWorkorderServiceList[0].std_prozentualer_fortschritt }} %
        </div> -->
      </div>
    </div>
  </mat-list-item>
</mat-list>
