import { HttpResponse } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CDSStdWorker } from "../../../models/cds/cds-stdWorker";
import { CDSApiService } from "../../../service/cds-api.service";
@Component({
    selector: 'cds-dialog-set-worker',
    templateUrl: 'cds-dialog-set-worker.html',
  })
  export class CdsDialogSetWorker {
  
    workerFormGroup: FormGroup;
    enableProgressSpinner: boolean;
    isChecked: boolean;
    isIndividualOnly: boolean;
    snackBarDuration: number = 2;
    workerList: CDSStdWorker[];
    cdsWorker: CDSStdWorker;
    title: string;
  
    constructor(
      public dialogRef: MatDialogRef<CdsDialogSetWorker>,
      private _formBuilder: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.workerFormGroup = this._formBuilder.group({
        workerIndividual: new FormControl(null, Validators.required),
        workerList: new FormControl(null, Validators.required),
      });

      this.enableProgressSpinner = false;
      
      this.title = (!!data.title) ? data.title : "Person zuordnen";
      
      if (!data.cdsWorkerList) {
        this.isIndividualOnly = true;
        this.isChecked = true;
        this.workerList = [];
      }
      else {
        this.isIndividualOnly = false;
        this.isChecked = false;
        this.workerList = data.cdsWorkerList;
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    btnClickSetWOrker(): void {
      if(this.isChecked) {
        this.dialogRef.close(this.workerFormGroup.get('workerIndividual').value);
      }
      else {
        this.dialogRef.close(this.workerFormGroup.get('workerList').value[0]);
      }
    }
  }