import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SpsBuildingProject } from '../../../models/sps-building-project';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSBuildingProject } from '../../../models/cds/cds-building-project';

@Component({
  selector: 'app-building-project-detail',
  templateUrl: './building-project-detail.component.html',
  styleUrls: ['./building-project-detail.component.scss']
})
export class BuildingProjectDetailComponent implements OnInit {
  buildingProject: SpsBuildingProject = new SpsBuildingProject();
  isNew: boolean = false;
  enableProgressBar: boolean = false;
  snackBarDuration: number = 3;
  canLoadProjects: boolean = false;
  buildingProjectFormGroup: FormGroup;
  buildingProjectExternalLink: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private cdsApiService: CDSApiService,
    private _formBuilder: FormBuilder
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.buildingProjectFormGroup = this._formBuilder.group({
      std_name: new UntypedFormControl({ value: '' }),
      std_eigentuemerid: new UntypedFormControl({ value: '' }),
      std_adresse1_strasse1: new UntypedFormControl({ value: '' }),
      std_adresse1_ort: new UntypedFormControl({ value: '' }),
      std_addresse1_postleitzahl: new UntypedFormControl({ value: '' }),
      std_adresse1_land: new UntypedFormControl({ value: '' })
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get('id') === 'new') this.newBuildingProject();
      else this.loadBuildingProject();
    });
  }

  newBuildingProject() {
    this.isNew = true;
  }

  btnClickNavigateBack() {
    this.location.back();
  }

  loadBuildingProject() {
    this.enableProgressBar = true;

    this.route.paramMap.subscribe((params) => {
      this.cdsApiService.getEntity('std_bauvorhabens', params.get('id')).subscribe(
        (buildingProject: CDSBuildingProject) => {
          this.buildingProject = buildingProject;

          this.buildingProjectFormGroup.patchValue({
            std_name: this.buildingProject.std_name,
            std_eigentuemerid: this.buildingProject.std_eigentuemerid,
            std_adresse1_strasse1: this.buildingProject.std_adresse1_strasse1,
            std_adresse1_ort: this.buildingProject.std_adresse1_ort,
            std_addresse1_postleitzahl: this.buildingProject.std_addresse1_postleitzahl,
            std_adresse1_land: this.buildingProject.std_adresse1_land
          });

          this.buildingProjectExternalLink = this.cdsApiService.getExternalLink(
            'std_bauvorhaben',
            this.buildingProject.std_bauvorhabenid
          );
        },
        (err: any) => console.error(err),
        () => {
          this.canLoadProjects = true;
          this.enableProgressBar = false;
        }
      );
    });
  }

  onBuildingProjectExternalLink(link: string) {
    window.open(link, '_blank');
  }
}
