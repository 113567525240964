import { HttpResponse } from '@angular/common/http';
// import { createOfflineCompileUrlResolver } from "@angular/compiler";
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSOpportunity } from '../../../models/cds/cds-opportunity';
import { CDSProject } from '../../../models/cds/cds-project';
import { CDSStdWorker } from '../../../models/cds/cds-stdWorker';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { CDSApiService } from '../../../service/cds-api.service';
import { ProjectApiService } from '../../../service/project-api.service';

@Component({
  selector: 'dialog-generate-project',
  templateUrl: 'dialog-generate-project.html'
})
export class DialogGenerateProject {
  projectFormGroup: FormGroup;
  enableProgressSpinner: boolean;
  snackBarDuration: number = 2;
  cdsProject: CDSProject;
  cdsOpportunity: CDSOpportunity;

  constructor(
    public dialogRef: MatDialogRef<DialogGenerateProject>,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private cdsApiService: CDSApiService,
    private projectApiService: ProjectApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.projectFormGroup = this._formBuilder.group({
      _std_worker_projektleiter_stoebichid_value: new FormControl(null, Validators.required),
      std_auftragsnummer: new FormControl('', Validators.required),
      generateProjectPlan: new FormControl(),
      startDate: new FormControl(new Date())
    });
    this.dateAdapter.setLocale('de');

    this.cdsProject = !!this.data.cdsProject ? this.data.cdsProject : new CDSProject();
    this.cdsOpportunity = !!this.data.cdsOpportunity ? this.data.cdsOpportunity : new CDSOpportunity();

    console.log('### init Dialog');
    console.log(this.cdsOpportunity);
    console.log(this.cdsOpportunity._std_projektleiter_stoebich_value);

    this.projectFormGroup.controls['_std_worker_projektleiter_stoebichid_value'].patchValue(
      this.cdsOpportunity._std_projektleiter_stoebich_value
    );
  }

  generateProject(): void {
    console.log('### generateProject');
    console.log(this.cdsProject);

    this.cdsProject = Object.assign(this.cdsProject, this.projectFormGroup.value);

    if (!this.cdsOpportunity._parentaccountid_value) throw new Error('Der Verkaufschance ist keine Firma zugeordnet');

    var requestData = {
      statecode: +0,
      statuscode: +1,
      std_auftragsnummer: this.cdsProject.std_auftragsnummer,
      std_bauvorhaben: this.cdsOpportunity.name.slice(0, 87),
      'std_companyid@odata.bind': `/cdm_companies(${this.cdsApiService.getDefault('cdm_companies')})`,
      'std_kundeid@odata.bind': `/accounts(${this.cdsOpportunity._parentaccountid_value})`,
      'std_worker_projektleiter_stoebichid@odata.bind': `/std_workers(${
        this.projectFormGroup.get('_std_worker_projektleiter_stoebichid_value').value
      })`
    };

    if (!!this.cdsOpportunity._std_bauvorhabenid_value)
      requestData[
        'std_bauvorhabenid@odata.bind'
      ] = `/std_bauvorhabens(${this.cdsOpportunity._std_bauvorhabenid_value})`;

    this.cdsApiService.createEntity('std_auftrags', requestData).subscribe(
      (response: HttpResponse<any>) => {
        this.cdsProject.std_auftragid = this.cdsApiService.getResponseIdByHeader(
          response.headers.get('odata-entityid')
        );

        this.cdsApiService
          .updateEntity('opportunities', this.cdsOpportunity.opportunityid, {
            'std_auftragid@odata.bind': `/std_auftrags(${this.cdsProject.std_auftragid})`
          })
          .subscribe(
            (cdsApiResponse: CDSApiResponse) => {
              this.dialogRef.close(this.cdsProject.std_auftragid);
            },
            (err: any) => {
              throw new Error(err.error.message);
            }
          );
      },
      (err: any) => {
        throw new Error(err.error.message);
      }
    );
  }

  btnGenerateProject(): void {
    try {
      this.enableProgressSpinner = true;

      this.generateProject();
    } catch (error) {
      this.enableProgressSpinner = false;

      console.error(error);

      this.snackBar.open(error, 'ok', {
        duration: this.snackBarDuration * 1000
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
