<mat-tree [dataSource]="productGroups" [treeControl]="treeControl" *ngIf="!shortView">

    <mat-tree-node *matTreeNodeDef="let product" matTreeNodePadding>
        <!-- <button mat-icon-button disabled></button> -->
        <!-- <pre>{{node | json}}</pre> -->

        <!-- <mat-checkbox [value]="product" [checked]="checkboxIsChecked(product.id)" (change)="checkboxChange($event)"> -->
            <button mat-button (click)="selectProduct(product.id)">{{product.title}}</button>
        <!-- </mat-checkbox> -->
    </mat-tree-node>
    
    <mat-tree-node *matTreeNodeDef="let product;when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + product.title">
        <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(product) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        </button>
        {{product.title}}
    </mat-tree-node>

</mat-tree>

<mat-form-field *ngIf="shortView">
    <mat-label>Produkt wählen</mat-label>
    <mat-select multiple>
        <mat-optgroup *ngFor="let productGroup of productGroups.data" [label]="productGroup.title">
            <mat-option *ngFor="let product of productGroup.details" [value]="product.data.id" (click)="selectChange(product.data.id)">
                {{product.data.title}}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>