<div class="app-content-wrapper">
  <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    <div fxFlex="66%">
      <section>
        <div class="grid">
          <mat-card shildClickable matRipple (click)="navigate(['sps/opportunity'])">
            <mat-icon color="accent">assignment_late</mat-icon>
            <span>Verkaufschancen</span>
          </mat-card>
          <mat-card shildClickable matRipple (click)="navigate(['sps/buildingProject'])">
            <mat-icon color="accent">construction</mat-icon>
            <span>Bauvorhaben</span>
          </mat-card>
          <mat-card shildClickable matRipple (click)="navigate(['sps/project'])">
            <mat-icon color="accent">inventory_2</mat-icon>
            <span>Projekte</span>
          </mat-card>
        </div>
      </section>
    </div>
    <!-- <div fxFlex="33%">    
            </div> -->
    <div class="text-right" fxFlex="33%">
      <div class="clock">{{ timeNow | date: 'HH:mm' }}</div>
      <div class="date">{{ timeNow | date: 'dd.MM.yyyy' }}</div>
    </div>
  </div>
</div>
