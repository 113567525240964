<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side">

    <app-navbar *ngIf="loggedIn" [navElements]="routes"></app-navbar>

  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="app-header">
        <button mat-icon-button (click)="sidenav.toggle()" *ngIf="loggedIn">
            <mat-icon>menu</mat-icon>
        </button>

         <span class="spacer"></span>

        <div class="sidenav-header-content">
          <img *ngIf="isProduction" src="assets/img/stoebich_group_logo_en.svg">
          <h1 *ngIf="!isProduction" class="logo">SANDKASTEN</h1>
        </div>

        <span class="spacer"></span>

        <!-- <button mat-icon-button routerLink="/postbox">
          <mat-icon [matBadgeHidden]="(postboxService.selectedProducts.length === 0)" matBadge="{{postboxService.selectedProducts.length}}" matBadgeColor="warn">inbox</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>language</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let language of languages; last as last">{{ language.short | uppercase }}</button>
        </mat-menu> -->

        <span class="account-name">{{account?.name}}</span>

        <button mat-icon-button *ngIf="loggedIn" [matMenuTriggerFor]="accountMenu"><mat-icon>person</mat-icon></button>
        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item (click)="btnClickLogout()">Abmelden</button>
        </mat-menu>
    </mat-toolbar>

    <router-outlet *ngIf="!isIframe"></router-outlet>

    <!-- <pre>{{roles | json}}</pre> -->

  </mat-sidenav-content>
</mat-sidenav-container>
