
import { Component, OnInit } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigDataHandlerService } from './service/config-data-handler.service';
import { CryptoUtils, Logger } from 'msal';
import { GraphApiService } from './service/graph-api.service';

import { environment } from './../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { routes } from './app-routing.module';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'shild-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  routes;
  isIframe = false;
  loggedIn = false;
  account: any;
  user: any;
  userPhoto: any;
  isProduction: boolean;
  roles: any;

  constructor(
    private configDataHandlerService: ConfigDataHandlerService,
    private _snackBar: MatSnackBar,
    private broadcastService: BroadcastService, 
    private authService: MsalService,
    private graphApiService: GraphApiService,
    private router: Router
    ) { }

  ngOnInit() {
    this.routes = routes;
    this.isIframe = window !== window.parent && !window.opener;
    this.isProduction = environment.production;

    if(window.navigator.userAgent.match(/(Trident|MSIE)\/(\d+.)*/) != null){
      this._snackBar.open("Der Internet Explorer wird nicht mehr unterstützt. Bitte Vernwenden Sie Google Chrome (Hinweis: Sie finden Google Chrome bei Citrix)","ok")
      return;
    }
    
    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  checkoutAccount() {
    if(!!this.authService.getAccount()) {
      this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {
          if (e.url === '/login') this.router.navigate(['/']);
      });

      if (this.router.url === '/login') this.router.navigate(['/']);
      
      this.loggedIn = true;
      this.account = this.authService.getAccount();
      this.roles = this.account;
      this.loadUser();
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  loadUser(): void {
    this.graphApiService.getMe()
    .subscribe(
      (user: any) => this.user = user,
      (err: any) => console.error(err),
      () => this.loadUserPhoto()
    );
  }

  loadUserPhoto(): void {
    this.graphApiService.getMyPhoto()
    .subscribe(
      (userPhoto: any) => this.userPhoto = userPhoto,
      (err: any) => console.error(err)
    );
  }
  
  btnClickLogin() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  btnClickLogout() {
    this.authService.logout();
  }

}
