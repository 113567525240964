<div style="display: flex">
  <mat-form-field class="full-width">
    <mat-label>
      <ng-content></ng-content>
    </mat-label>
    <input *ngIf="disabled" type="text" matInput [formControl]="pickerControl" readonly [matAutocomplete]="auto" />
    <input *ngIf="!disabled" type="text" matInput [formControl]="pickerControl" [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="setSelectdUser($event.option.value)"
      [displayWith]="displayFn"
    >
      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="30"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let user of userList" [value]="user">
          {{ user.std_suchbegriff }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <div class="graph-user-details" *ngIf="!!selectedUser && email" style="width: 100%">
    <mat-form-field class="full-width">
      <mat-label> Email </mat-label>
      <input *ngIf="selectedUser.std_email" type="text" matInput readonly [value]="selectedUser.std_email" />
      <input *ngIf="!selectedUser.std_email" type="text" matInput readonly placeholder="No email" />
      <button *ngIf="!selectedUser.std_email" matSuffix mat-icon-button aria-label="Clear" (click)="showHelpPopup()">
        <mat-icon color="primary" style="font-size:16px">help</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<mat-option *ngIf="userIsLoading" class="is-loading">
  <mat-spinner diameter="30"></mat-spinner>
</mat-option>

<div class="graph-user-details" *ngIf="!!selectedUser">
  <mat-list>
    <mat-list-item *ngIf="selectedUser.std_email"><mat-icon>email</mat-icon> <a href="mailto:{{selectedUser.std_email}}">{{selectedUser.std_email}}</a></mat-list-item>
    <mat-list-item *ngIf="selectedUser.std_telefon">
      <mat-icon>local_phone</mat-icon> <a href="tel:{{ selectedUser.std_telefon }}">{{ selectedUser.std_telefon }}</a>
    </mat-list-item>
    <!-- <mat-list-item *ngIf="selectedUser.mobilephone"><mat-icon>smartphone</mat-icon> <a href="tel:{{selectedUser.mobilephone}}">{{selectedUser.mobilephone}}</a></mat-list-item> -->
  </mat-list>
</div>
