<mat-divider></mat-divider>
<mat-toolbar>
    <button mat-icon-button (click)="btnClickNavigateBack()"><mat-icon>arrow_back</mat-icon></button>
    <button mat-icon-button color="primary" (click)="btnClickSavePosition()"><mat-icon>save</mat-icon></button>
    <button mat-icon-button [disabled]="isNew" (click)="btnClickDeleteProjectPosition()"><mat-icon>delete</mat-icon></button>
    <button mat-icon-button [disabled]="isNew" (click)="loadPosition()"><mat-icon>autorenew</mat-icon></button>
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #menu="matMenu">
        <a mat-menu-item target="_blank" href="{{positionExternalLink}}">in Field Service anzeigen</a>
    </mat-menu>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="wrapper">

    <div>
        <h2>{{ spsPos.posNumber }} <small>(<a [routerLink]="['/sps/project/', cdsProject.std_auftragid]">{{cdsProject.std_auftrag}}</a>)<br>
        Position</small></h2>
    </div>

    <mat-tab-group>
        <mat-tab label="Allgemein">
    
            <div class="mat-tab-wrapper">      
                    
                <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
                    <div fxFlex="33%">    
                        <mat-card>
                            <mat-card-content>
                                <form [formGroup]="posFormGroup">

                                    <mat-form-field class="full-width">
                                        <mat-label>Positionnummer</mat-label>
                                        <input matInput type="text" class="form-control" id="posNumber" [required]="true" formControlName="posNumber" [readonly]="!isNew">                                
                                    </mat-form-field>
                            
                                    <!-- 
                                    <mat-form-field class="full-width">
                                        <mat-label>Serviceposition</mat-label>
                                        <input matInput type="text" class="form-control" id="posNumberAlt" [required]="true" formControlName="posNumberAlt" [readonly]="!isNew">                                
                                    </mat-form-field>
                                    -->
                            
                                    <mat-form-field class="full-width">
                                        <mat-label>Standardarbeitsauftragstyp</mat-label>
                                        <mat-select [(value)]="spsPos.spsPosType" formControlName="spsPosType" [disabled]="!isNew">
                                            <mat-option *ngFor="let spsPosType of spsPosTypeList" [value]="spsPosType">
                                            {{spsPosType.posTitle}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                            
                                    <mat-form-field class="full-width">
                                        <mat-label>Produkttyp</mat-label>
                                        <mat-select [(value)]="spsPos.cdsIncidentType" formControlName="cdsIncidentType" [disabled]="!isNew">
                                            <mat-option *ngFor="let cdsIncidentType of cdsIncidentTypeList" [value]="cdsIncidentType">
                                            {{cdsIncidentType.msdyn_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                </form>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div fxFlex="66%">    </div>
                </div>
            </div>  
        </mat-tab>
    </mat-tab-group>
</div> 