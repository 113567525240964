import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ProjectApiService } from '../../../service/project-api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { SpsBuildingProject } from '../../../models/sps-building-project';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSProject } from '../../../models/cds/cds-project';
import { SPSProject } from '../../../models/sps-project';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { map, switchMap } from 'rxjs/operators';
//import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'orderNumber',
    'projektleiter',
    'konstrukteur_mechanik',
    'konstrukteur_elektrik',
    'montage_leiter',
    'kaufmaennisch',
    'creationDate'
  ];

  cdsProjectList = new MatTableDataSource<CDSProject>();

  selection = new SelectionModel<CDSProject>(true, []);

  snackBarDuration: number = 3;

  debugData: any = {};

  enableProgressBar: boolean = false;

  @Input() buildingProject: SpsBuildingProject;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  spsProjectList: SPSProject[];

  constructor(
    public dialog: MatDialog,
    public projectApiService: ProjectApiService,
    private cdsApiService: CDSApiService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.spsProjectList = [];
  }

  ngOnInit() {
    this.cdsProjectList.paginator = this.paginator;
    this.cdsProjectList.sort = this.sort;
    this.loadProjectList();
  }

  loadProjectList(): void {
    this.enableProgressBar = true;
    this.cdsProjectList.data = [];

    if (this.buildingProject != undefined)
      this.cdsApiService
        .getAllEntity(
          `std_auftrags?$filter=std_bauvorhabenid/std_bauvorhabenid%20eq%20${this.buildingProject.std_bauvorhabenid}&$expand=std_worker_projektleiter_stoebichid,std_worker_konstrukteur_mechid,std_worker_konstrukteur_elektid,std_worker_kaufmaennisch_stoebich`
        )
        .subscribe(
          (response: CDSApiResponse) => {
            console.log(response.value);
            this.cdsProjectList.data = response.value;
            this.cdsProjectList._updateChangeSubscription();
          },
          (err: any) => console.error(err),
          () => (this.enableProgressBar = false)
        );
    else {
      this.cdsApiService
        .getAllEntity(
          `std_auftrags?$filter=statecode%20eq%200&$expand=std_worker_projektleiter_stoebichid,std_worker_konstrukteur_mechid,std_worker_konstrukteur_elektid,std_worker_kaufmaennisch_stoebich`
        )

        // TODO JOVAN
        //.pipe(switchMap((response: CDSApiResponse )=> {

        //   var x = [];

        //   response.value.forEach(element => {
        //     x.push( this.projectApiService.getProjectByCDSId(element.std_auftragid));
        //   });

        //   return [x];

        // }),map((value) => {
        //   console.log(value);
        //     return value;
        //}))
        .subscribe(
          (response: CDSApiResponse) => {
            this.cdsProjectList.data = response.value;

            console.log(response.value);

            this.cdsProjectList._updateChangeSubscription();
          },
          (err: any) => console.error(err),
          () => (this.enableProgressBar = false)
        );
    }

    this.cdsProjectList.filterPredicate = function (data, filter: string): boolean {
      let result = false;

      if (filter == undefined || data == undefined) {
        return result;
      }

      result =
        data.std_auftragsnummer?.toLowerCase().includes(filter) ||
        data.std_auftragid?.toLowerCase().includes(filter) ||
        data.std_auftrag?.toLowerCase().includes(filter) ||
        data._std_kundeid_value?.toLowerCase().includes(filter) ||
        data._std_worker_projektleiter_stoebichid_value?.toLowerCase().includes(filter) ||
        data.std_worker_projektleiter_stoebichid?.std_suchbegriff?.toLowerCase().includes(filter) ||
        data._std_worker_konstrukteur_mechid_value?.toLowerCase().includes(filter) ||
        data.std_worker_konstrukteur_mechid?.std_suchbegriff?.toLowerCase().includes(filter) ||
        data._std_worker_konstrukteur_elektid_value?.toLowerCase().includes(filter) ||
        data.std_worker_konstrukteur_elektid?.std_suchbegriff?.toLowerCase().includes(filter) ||
        data._std_worker_kaufmaennisch_stoebich_value?.toLowerCase().includes(filter) ||
        data.std_worker_kaufmaennisch_stoebich?.std_suchbegriff?.toLowerCase().includes(filter) ||
        data._std_worker_montageleiter_stoebich_value?.toLowerCase().includes(filter) ||
        data.std_worker_montageleiter_stoebich?.std_suchbegriff?.toLowerCase().includes(filter) ||
        data.createdon?.toLowerCase().includes(filter);

      return result;
    };

    this.cdsProjectList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'orderNumber':
          return item.std_auftragsnummer;
        case 'name':
          return item.std_auftragid;
        case 'projektleiter':
          return item.std_worker_projektleiter_stoebichid?.std_suchbegriff;
        case 'konstrukteur_mechanik':
          return item.std_worker_konstrukteur_mechid?.std_suchbegriff;
        case 'konstrukteur_elektrik':
          return item.std_worker_konstrukteur_elektid?.std_suchbegriff;
        case 'kaufmaennisch':
          return item.std_worker_kaufmaennisch_stoebich?.std_suchbegriff;
        case 'montage_leiter':
          return item.std_worker_montageleiter_stoebich?.std_suchbegriff;
        case 'creationDate':
          return new Date(item.createdon);
        default:
          return item[property];
      }
    };
  }

  // getProjectByGraphResId(id: string) {
  //   if(!id)
  //     return null;

  //     return new Promise((resolve,reject) => {
  //       this.cdsApiService.getEntityByFilter('systemusers','azureactivedirectoryobjectid',id)
  //         .subscribe(
  //           (response: CDSApiResponse) => {
  //             if(!!response.value[0])
  //               this.cdsApiService.getEntityByFilter('std_workers','_std_benutzerid_value',response.value[0].systemuserid)
  //               .subscribe(
  //                 (response: CDSApiResponse) => resolve(response),
  //                 (err: any) => console.error(err),
  //                 () => this.enableProgressBar = false
  //               );
  //             else
  //                 resolve(null);
  //           },
  //           (err: any) => console.error(err),
  //           () => this.enableProgressBar = false
  //       );
  //     });
  // }

  applyFilter(filterValue) {
    this.cdsProjectList.filter = filterValue.value.trim().toLowerCase();

    this.cdsProjectList.filter = filterValue.value;

    if (this.cdsProjectList.paginator) {
      this.cdsProjectList.paginator.firstPage();
    }
  }
}
