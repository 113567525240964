// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   name: 'dev',
//   host: 'http://localhost:4200',
//   sps: {
//     host: "http://localhost:3000",
//     scope: ['api://b2f0bfcd-71f5-43aa-a462-b0c64e6a0217/BuildingProjects']
//   },
//   cds: {
//     host: 'https://stoebichgroup-dev.crm4.dynamics.com',
//     scope: ['https://stoebichgroup-dev.crm4.dynamics.com/user_impersonation'],
//     version: 'v9.2',
//     app: {
//       fieldservice: '10b18b83-f60d-ea11-a812-000d3abaab6d',
//     },
//     default: {
//       pricelevels: 'ab8b4e9a-dd15-ea11-a811-000d3ab2928c',
//       cdm_companies: 'd2d708d4-4509-eb11-a813-000d3ab29ddd',
//       uoms: '752F49E4-9B22-EB11-A813-0022489B07E5',
//       std_serviceaufgabes: '5D516F26-A329-EB11-A813-000D3AB29B65',
//       std_serviceobjekts: '17AF613E-085F-EB11-A812-000D3A207EDB',
//       msdyn_worklocation: 690970000
//     }
//   },
//   msal: {
//     auth: {
//       clientId: '8e408153-75eb-4e42-9a21-f7625c12645f',
//       authority: 'https://login.microsoftonline.com/7e7b05cf-3e9a-45d3-9320-3971fe6d59cd',
//       validateAuthority: true,
//       redirectUri: "http://localhost:4200",
//       postLogoutRedirectUri: "http://localhost:4200/login",
//       navigateToLoginRequestUrl: true,
//     }
//   },
//   ls: {
//     host: 'https://survey.stoebich.com/index.php/admin/remotecontrol',
//     user: 'integrationUser',
//     password: '536oi3171i56j'
//   }
// };

export const environment = {
  production: true,
  name: 'deploy',
  host: 'https://test.pit.stoebich.com',
  sps: {
    host: "https://api-test.projects.stoebich.com",
    scope: ['api://b2f0bfcd-71f5-43aa-a462-b0c64e6a0217/BuildingProjects']
  },
  cds: {
    host: 'https://operations-stoebich-test.crm4.dynamics.com',
    scope: ['https://operations-stoebich-test.crm4.dynamics.com/user_impersonation'],
    version: 'v9.2',
    app: {
      fieldservice: '64297dd5-fe2c-eb11-a813-000d3a23c23e',
    },
    default: {
      pricelevels: '193c00b3-122f-eb11-a813-000d3a2cbdf1',
      cdm_companies: '8c26963f-002f-eb11-a813-000d3a2cbdf1',
      uoms: '65477728-a3e7-4cd3-8b1e-046942ce7047',
      std_serviceaufgabes: '461cbb2f-e5a0-eb11-b1ac-000d3abf8b74',
      std_serviceobjekts: '17af613e-085f-eb11-a812-000d3a207edb',
      msdyn_worklocation: 690970000
    }
  },
  msal: {
    auth: {
      clientId: '8e408153-75eb-4e42-9a21-f7625c12645f',
      authority: 'https://login.microsoftonline.com/7e7b05cf-3e9a-45d3-9320-3971fe6d59cd',
      validateAuthority: true,
      redirectUri: "https://test.pit.stoebich.com",
      postLogoutRedirectUri: "https://test.pit.stoebich.com/login",
      navigateToLoginRequestUrl: true,
    }
  },
  ls: {
    host: 'https://survey.stoebich.com/index.php/admin/remotecontrol',
    user: 'integrationUser',
    password: '536oi3171i56j'
  },
  d3: {
    host: 'https://d3.stoebich.com'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
