import { Component, OnInit, Input } from '@angular/core';
import { NavElement } from '../../../models/nav-element';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
  @Input() dataSource: NavElement[] = [];

  constructor() { }

  ngOnInit() {
  }

}
