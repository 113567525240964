<mat-toolbar>
    <!-- <button mat-icon-button color="primary" (click)="btnClickCreateProject()"><mat-icon>add</mat-icon></button> -->
    <button mat-icon-button (click)="loadProjectList()"><mat-icon>autorenew</mat-icon></button>
    <span class="spacer"></span>
    <mat-form-field class="input-filter">
        <input matInput (keyup)="applyFilter($event.target)" placeholder="Suchen">
    </mat-form-field>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>
<div *ngIf="!buildingProject" class="headline-wrapper"><h2>Alle Projekte</h2></div>
<table mat-table [dataSource]="cdsProjectList" matSort>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/sps/project', element.std_auftragid]">{{element.std_auftrag}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Auftragsnummer </th>
        <td mat-cell *matCellDef="let element"> {{element.std_auftragsnummer}} </td>
    </ng-container>

    
    <!-- <ng-container matColumnDef="orderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PSP </th>
      <td mat-cell> Smartsheetlink </td>
    </ng-container> -->

    <ng-container matColumnDef="projektleiter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Projektleiter </th>
        <td mat-cell *matCellDef="let element"> {{element.std_worker_projektleiter_stoebichid?.std_suchbegriff}} </td>
    </ng-container>

    <ng-container matColumnDef="konstrukteur_mechanik">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Konstrukteur (mech) </th>
      <td mat-cell *matCellDef="let element"> {{element.std_worker_konstrukteur_mechid?.std_suchbegriff}} </td>
    </ng-container>

    <ng-container matColumnDef="konstrukteur_elektrik">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Konstrukteur (elekt) </th>
      <td mat-cell *matCellDef="let element"> {{element.std_worker_konstrukteur_elektid?.std_suchbegriff}} </td>
    </ng-container>

    <ng-container matColumnDef="montage_leiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Montageleiter Stöbich </th>
      <td mat-cell *matCellDef="let element"> {{element.std_worker_montageleiter_stoebich?.std_suchbegriff}} </td>
    </ng-container>

    <ng-container matColumnDef="kaufmaennisch">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kaufmännisch </th>
      <td mat-cell *matCellDef="let element"> {{element.std_worker_kaufmaennisch_stoebich?.std_suchbegriff}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Erstellt am </th>
        <td mat-cell *matCellDef="let element"> {{element.createdon | date:'dd.MM.yyyy HH:mm'}} Uhr</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100, 200]"></mat-paginator>
