import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router'
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MsalModule, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, MsalAngularConfiguration } from '@azure/msal-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from './../environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatasheetComponent } from './components/datasheet/datasheet.component';
import { DataboxProductPickerComponent } from './components/databox-product-picker/databox-product-picker.component';
import { SurveyComponent } from './components/survey/survey.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SafePipe } from './safe.pipe';
import { ItemListComponent } from './components/helper/item-list/item-list.component';
import { ProjectComponent} from './components/project/project.component';
import { ProjectListComponent} from './components/project/project-list/project-list.component';
import { BuildingProjectListComponent } from './components/project/building-project-list/building-project-list.component';
import { BuildingProjectDetailComponent } from './components/project/building-project-detail/building-project-detail.component';
import { ProjectDetailComponent} from './components/project/project-detail/project-detail.component';
import { PositionListComponent } from './components/project/position-list/position-list.component';
import { Configuration } from 'msal';
import { GraphUserPickerComponent } from './components/graph-user-picker/graph-user-picker.component';
import { CdsContactPickerComponent } from './components/cds/cds-contact-picker/cds-contact-picker.component';
import { ButtonConfirmComponent } from './components/helper/button-confirm/button-confirm.component';
import { LoginComponent } from './components/login/login.component';
import { CallbackPipe } from './callback.pipe';
import { DialogGenerateWorkOrder } from './components/project/position-list/dialog-generate-work-order.component';
import { DialogPositionDetail } from './components/project/position-list/dialog-position-detail.component';
import { PositionDetailComponent } from './components/project/position-detail/position-detail.component';
import { WorkorderListComponent } from './components/project/workorder-list/workorder-list.component';
import { WorkorderDetailComponent } from './components/project/workorder-detail/workorder-detail.component';
import { WorkorderServiceListComponent } from './components/project/workorder-service-list/workorder-service-list.component';
import { DialogResourceRequirement } from './components/project/workorder-list/dialog-generate-resource-requirement.component';
import { OpportunityListComponent } from './components/project/opportunity-list/opportunity-list.component';
import { DialogGenerateProject } from './components/project/opportunity-list/dialog-generate-project';
import { DialogGenerateProjectPlan } from './components/project/project-detail/dialog-generate-project-plan';
import { CdsOutputFieldComponent } from './components/cds/cds-output-field/cds-output-field.component';
import { CdsDialogSetWorker } from './components/cds/cds-dialog-set-worker/cds-dialog-set-worker';
import { CdsAccountPickerComponent } from './components/cds/cds-account-picker/cds-account-picker.component';
import { ProjectInfoComponent } from './components/project/project-info/project-info.component';
import { CdsContactListComponent } from './components/cds/cds-contact-list/cds-contact-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DialogViewResourceRequirementComponent } from './components/project/workorder-list/dialog-view-resource-requirement/dialog-view-resource-requirement.component';
import { ResourceRequirementListComponent } from './components/project/resource-requirement-list/resource-requirement-list.component';
import { HelpPopupComponent } from './components/project/project-detail/help-popup/help-popup.component';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0', ['user.read','User.ReadBasic.All']],
  [environment.cds.host, environment.cds.scope],
  [environment.sps.host, environment.sps.scope]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALConfigFactory(): Configuration {
  return {
    auth: environment.msal.auth,
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: [
      "user.read",
      "openid",
      "profile"
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}


@NgModule({
  declarations: [
    AppComponent,
    DatasheetComponent,
    DataboxProductPickerComponent,
    SurveyComponent,
    NavbarComponent,
    SafePipe,
    DashboardComponent,
    ItemListComponent,
    ProjectComponent,
    ProjectListComponent,
    BuildingProjectListComponent,
    BuildingProjectDetailComponent,
    ProjectDetailComponent,
    PositionListComponent,
    DialogGenerateWorkOrder,
    DialogResourceRequirement,
    DialogGenerateProjectPlan,
    DialogGenerateProject,
    DialogPositionDetail,
    GraphUserPickerComponent,
    CdsContactPickerComponent,
    ButtonConfirmComponent,
    LoginComponent,
    CallbackPipe,
    PositionDetailComponent,
    WorkorderListComponent,
    WorkorderDetailComponent,
    WorkorderServiceListComponent,
    OpportunityListComponent,
    CdsOutputFieldComponent,
    CdsDialogSetWorker,
    CdsAccountPickerComponent,
    ProjectInfoComponent,
    CdsContactListComponent,
    DialogViewResourceRequirementComponent,
    ResourceRequirementListComponent,
    HelpPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxExtendedPdfViewerModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatGridListModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatExpansionModule,
    MatGridListModule,
    MatFormFieldModule,
    MatMenuModule,
    MatCardModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatRadioModule,
    MatBadgeModule,
    MatTabsModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButtonModule,
    MatAutocompleteModule
  ],
  entryComponents: [
    DialogGenerateWorkOrder,
    DialogResourceRequirement,
    DialogPositionDetail,
    DialogGenerateProjectPlan,
    DialogGenerateProject,
    CdsDialogSetWorker,
    ButtonConfirmComponent
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {hasBackdrop: false}
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
