<div style="position: relative">
  <h1 mat-dialog-title>Ressourcenanforderung erstellen</h1>
  <mat-icon style="position: absolute; top: 0px; right: 10px; cursor: pointer" color="primary" (click)="closeDialog()"
    >close</mat-icon
  >
  <ng-container *ngIf="(st | async) as data">
    <div mat-dialog-content *ngIf="data.length > 0" style="position: relative; height: 100%">
      <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="resourceFormGroup">
          <ng-template matStepLabel>Ergänzende Informationen</ng-template>
          <!-- <h3>Bitte geben Sie eine Sollzeit der Montage an</h3> -->

          <form [formGroup]="resourceFormGroup">
            <!-- <mat-form-field *ngIf="workorder.workorderService.length === 0" class="full-width">
          <mat-label>Service</mat-label>
          <mat-select [(value)]="serviceProduct" formControlName="serviceProduct">
            <mat-option *ngFor="let cdsProduct of cdsProductList" [value]="cdsProduct">
              {{cdsProduct.name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

            <mat-form-field class="full-width">
              <mat-label>Startdatum</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                class="form-control"
                id="startDate"
                [required]="true"
                formControlName="startDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Enddatum</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                class="form-control"
                id="endDate"
                [required]="true"
                formControlName="endDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Anzahl Monteure</mat-label>
              <input matInput type="number" class="form-control" id="installers" formControlName="installers" />
              <span matSuffix>Personen</span>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Vorgabezeit (je Monteure)</mat-label>
              <input
                matInput
                type="number"
                min="0"
                class="form-control"
                id="installationTargetHours"
                formControlName="installationTargetHours"
                onkeydown="return event.keyCode !== 190"
              />
              <span matSuffix>Stunden</span>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Service Tasks</mat-label>
              <mat-select [(value)]="serviceTasks" multiple formControlName="serviceTasks">
                <mat-option *ngFor="let task of st | async" [value]="task.msdyn_workorderservicetaskid">
                  {{task.msdyn_name}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </form>

          <mat-divider></mat-divider>

          <div class="button-bar">
            <button
              mat-raised-button
              [disabled]="!resourceFormGroup.valid || (!(resourceFormGroup.get('installationTargetHours').value) && !(resourceFormGroup.get('installationTargetDays').value) ) || serviceTasks.length === 0"
              matStepperNext
            >
              Weiter
            </button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Absenden</ng-template>
          <!-- <pre>{{ data | json }}</pre> -->
          <table class="data-view-table">
            <tr>
              <td><strong>Bezeichnung</strong></td>
              <td>{{ workorder.msdyn_name }}</td>
            </tr>
            <tr>
              <td><strong>Montagezeit</strong></td>
              <td>
                {{ resourceFormGroup.get('startDate').value | date:'dd.MM.yyyy' }} - {{
                resourceFormGroup.get('endDate').value | date:'dd.MM.yyyy'}}
              </td>
            </tr>
            <tr>
              <td><strong>Monteure</strong></td>
              <td>{{ resourceFormGroup.get('installers').value | number }}</td>
            </tr>
            <tr>
              <td><strong>Vorgabezeit (je Monteur)</strong></td>
              <td>
                <span style="padding-right: 4px" *ngIf="(resourceFormGroup.get('installationTargetHours').value) > 0 "
                  >{{ resourceFormGroup.get('installationTargetHours').value | number }}h</span
                >
                <span *ngIf="resourceFormGroup.get('installationTargetDays').value > 0 "
                  >{{ resourceFormGroup.get('installationTargetDays').value | number }}Day(s)</span
                >
              </td>
            </tr>
          </table>
          <mat-divider></mat-divider>

          <div class="button-bar">
            <button mat-raised-button color="primary" (click)="generateResourceRequirement()">Jetzt erstellen</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </ng-container>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>
