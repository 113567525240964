import { CDSAccount } from './cds-account';
import { CDSBuildingProject } from './cds-building-project';
import { CDSEntity } from './cds-entity';
import { CDSProjektbeteiligte } from './cds-projektbeteiligte';
import { CDSStdWorker } from './cds-stdWorker';
import { CDSTerretory } from './cds-terretory';

export class CDSProject implements CDSEntity {
    createdon: string;
    std_auftragid: string;
    std_auftrag: string;
    std_auftragsnummer: string;

    _std_kundeid_value: string;
    _std_bauvorhabenid_value: string;
    _std_worker_projektleiter_stoebichid_value: string;
    _std_worker_konstrukteur_mechid_value: string;
    _std_worker_konstrukteur_elektid_value: string;
    _std_worker_montageleiter_stoebich_value: string;
    _std_worker_kaufmaennisch_stoebich_value: string;

    _std_gebietid_value: string;

    std_std_projektbeteiligte_std_auftrag: CDSProjektbeteiligte[];

    std_worker_projektleiter_stoebichid: CDSStdWorker;

    std_worker_konstrukteur_mechid: CDSStdWorker;
    std_worker_konstrukteur_elektid: CDSStdWorker;
    std_worker_montageleiter_stoebich: CDSStdWorker;
    std_worker_kaufmaennisch_stoebich: CDSStdWorker;

    std_bauvorhabenid: CDSBuildingProject;
    std_kundeid: CDSAccount;
    std_gebietid: CDSTerretory;

}
