import { Injectable } from '@angular/core';
import { SPSProject } from '../models/sps-project';
import { SpsPosType } from '../models/sps-pos-type';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, shareReplay } from 'rxjs/operators';
import { SpsApiResponse } from '../models/sps-api-response';
import { SpsPos } from '../models/sps-pos';
import { environment } from './../../environments/environment';
import { SpsWorkorder } from '../models/sps-workorder';

@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {
  HOST: string = environment.sps.host;

  constructor(private http: HttpClient) {}

  calcProjectTimePlan(id: number): Observable<SpsApiResponse> {
    return this.http.get<SpsApiResponse>(this.HOST + '/project/' + id + '/calcTimePlan').pipe(
      //retry(3),
      catchError(this.handleError)
    );
  }

  updateProjectPlan(id: number, data: object): Observable<SpsApiResponse> {
    return this.http
      .put<SpsApiResponse>(this.HOST + '/project/' + id + '/projectPlan', data)
      .pipe(retry(3), catchError(this.handleError));
  }

  getProjectPosition(): Observable<SpsApiResponse> {
    return this.http.get<SpsApiResponse>(this.HOST + '/projectPosition').pipe(retry(3), catchError(this.handleError));
  }

  getProjectPositionByProject(id: number): Observable<SpsApiResponse> {
    return this.http
      .get<SpsApiResponse>(this.HOST + '/projectPosition/byProject/' + id)
      .pipe(shareReplay(1), retry(3), catchError(this.handleError));
  }

  getProjectPositionById(id: string): Observable<SpsPos> {
    return this.http.get<SpsPos>(this.HOST + '/projectPosition/' + id).pipe(retry(3), catchError(this.handleError));
  }

  createNewProjectPosition(spsPos: SpsPos): Observable<SpsApiResponse> {

    return this.http.post<SpsApiResponse>(this.HOST + '/projectPosition', spsPos).pipe(catchError(this.handleError));

    /*var res = this.http.post<SpsApiResponse>(this.HOST + '/projectPosition', spsPos);

    var res1 = res.subscribe();
    console.log("===== API Call done");
    
    return res; //.pipe(catchError(this.handleError));
    */
  }

  updateProjectPosition(spsPos: SpsPos): Observable<SpsApiResponse> {
    return this.http
      .put<SpsApiResponse>(this.HOST + '/projectPosition', spsPos)
      .pipe(retry(3), catchError(this.handleError));
  }

  deleteProjectPosition(id: number): Observable<SpsApiResponse> {
    return this.http
      .delete<SpsApiResponse>(this.HOST + '/projectPosition/' + id)
      .pipe(retry(3), catchError(this.handleError));
  }

  getProjectPositionType(): Observable<SpsApiResponse> {
    return this.http
      .get<SpsApiResponse>(this.HOST + '/projectPositionType')
      .pipe(retry(3), catchError(this.handleError));
  }

  createNewProject(newProject: SPSProject): Observable<SpsApiResponse> {
    // newProject.startDate = new Date(newProject.startDate).toISOString;
    return this.http
      .post<SpsApiResponse>(this.HOST + '/project', newProject)
      .pipe(retry(3), catchError(this.handleError));
  }

  getProjects(): Observable<SpsApiResponse> {
    return this.http.get<SpsApiResponse>(this.HOST + '/project').pipe(retry(3), catchError(this.handleError));
  }

  getProjectById(id: number): Observable<SPSProject> {
    return this.http.get<SPSProject>(this.HOST + '/project/' + id).pipe(retry(3), catchError(this.handleError));
  }

  getProjectByCDSId(id: string): Observable<SPSProject> {
    return this.http.get<SPSProject>(this.HOST + '/project/cdsId/' + id).pipe(retry(3), catchError(this.handleError));
  }

  updateProject(project: SPSProject): Observable<SpsApiResponse> {
    return this.http.put<SpsApiResponse>(this.HOST + '/project', project).pipe(retry(3), catchError(this.handleError));
  }

  deleteProject(id: number): Observable<SpsApiResponse> {
    return this.http.delete<SpsApiResponse>(this.HOST + '/project/' + id).pipe(retry(3), catchError(this.handleError));
  }

  getPosTypes(): Observable<SpsApiResponse> {
    return this.http.get<SpsApiResponse>(this.HOST + '/projectPosition').pipe(retry(3), catchError(this.handleError));
  }

  createNewWorkorder(newWorkorder: SpsWorkorder): Observable<SpsApiResponse> {
    return this.http
      .post<SpsApiResponse>(this.HOST + '/workorder', newWorkorder)
      .pipe(retry(3), catchError(this.handleError));
  }

  getWorkorders(): Observable<SpsApiResponse> {
    return this.http.get<SpsApiResponse>(this.HOST + '/workorder').pipe(retry(3), catchError(this.handleError));
  }

  getWorkorderById(id: number): Observable<SpsWorkorder> {
    return this.http.get<SpsWorkorder>(this.HOST + '/workorder/' + id).pipe(retry(3), catchError(this.handleError));
  }

  deleteWorkorder(id: string) {
    //not allowed
    return this.http.delete(this.HOST + '/workorder/' + id).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(`[Error] ${error.error.error.message}`);
  }
}
