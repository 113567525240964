<header class="sidenav-header">

    <mat-divider></mat-divider>
</header>


<section class="sidenav-body">
    <section class="sidenav-body-conainer">
        <h2 mat-line>Apps</h2>
        <mat-list>
            <!-- <mat-list-item routerLink="{{navElement.data.altPath || navElement.path}}" *ngFor="let navElement of navElementsFilterd | callback: filterElements"> -->
            <mat-list-item (click)="onNavClick(navElement.data.altPath || navElement.path)" *ngFor="let navElement of navElements">
                <mat-icon mat-list-icon>{{navElement.data.icon}}</mat-icon>
                <div mat-line>{{navElement.data.title}}</div>
            </mat-list-item>
        </mat-list>
    </section>
</section>

<footer class="sidenav-footer">

    <mat-divider></mat-divider>

    <div class="sidenav-footer-content">
      Version 2.0.0
    </div>
</footer>
