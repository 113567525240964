import { CDSBuildingProject } from './cds-building-project';
import { CDSEntity } from './cds-entity';
import { CDSOpportunityClose } from './cds-opportunity-close';
import { CDSQuotes } from './cds-quotes';
import { CDSStdWorker } from './cds-stdWorker';
import { CDSTerretory } from './cds-terretory';

export class CDSOpportunity implements CDSEntity {
  actualclosedate: string;
  // actualvalue: null
  // actualvalue_base: null
  // budgetamount: null
  // budgetamount_base: null
  // budgetstatus: null
  captureproposalfeedback: boolean;
  // closeprobability: null
  completefinalproposal: boolean;
  completeinternalreview: boolean;
  confirminterest: boolean;
  createdon: string;
  currentsituation: string;
  // customerneed: null
  // customerpainpoints: null
  // decisionmaker: boolean
  // description: null
  developproposal: boolean;
  // discountamount: null
  // discountamount_base: null
  // discountpercentage: null
  // emailaddress: null
  // estimatedclosedate: null
  estimatedvalue: number;
  // estimatedvalue_base: null
  evaluatefit: boolean;
  // exchangerate: 1
  filedebrief: boolean;
  // finaldecisiondate: null
  // freightamount: null
  // freightamount_base: null
  identifycompetitors: boolean;
  identifycustomercontacts: boolean;
  identifypursuitteam: boolean;
  // importsequencenumber: null
  // initialcommunication: null
  isrevenuesystemcalculated: boolean;
  // lastonholdtime: null
  modifiedon: string;
  msdyn_forecastcategory: number;
  msdyn_ordertype: number;
  name: string;
  // need: null
  // onholdtime: null
  opportunityid: string;
  opportunityratingcode: number;
  // overriddencreatedon: null
  participatesinworkflow: boolean;
  presentfinalproposal: boolean;
  presentproposal: boolean;
  pricingerrorcode: number;
  prioritycode: number;
  // processid: null
  // proposedsolution: null
  // purchaseprocess: null
  // purchasetimeframe: null
  pursuitdecision: boolean;
  // qualificationcomments: null
  // quotecomments: null
  resolvefeedback: boolean;
  salesstage: number;
  salesstagecode: number;
  // schedulefollowup_prospect: null
  // schedulefollowup_qualify: null
  // scheduleproposalmeeting: null
  sendthankyounote: boolean;
  skippricecalculation: number;
  // stageid: null
  statecode: number;
  statuscode: number;
  // std_angabenzumbau_option: null
  std_angebotausschreibungfertig: boolean;
  std_ausschreibungsversanderfolgt: boolean;
  std_bestellungabgelegt: boolean;
  std_checkliste: boolean;
  // std_connectionstd: null
  std_datenfuerdebitorenanlage: string;
  // std_descriptiontabsummary: null
  std_enddatumausfuehrungprojekt_date: string;
  std_enddatumausfuhrungprojektdate: string;
  // std_erwarteterumsatz: null
  // std_erwarteterumsatz_base: null
  std_externeangebote: boolean;
  std_empfaenger_lieferung: string;
  std_gewonnenesangebotmarkiert: boolean;
  std_inbetriebnahme_date: string;
  std_infofuerdieprojektabwicklung_multiline: string;
  std_konfigurationabgeschlossen: boolean;
  // std_name_des_bauvorhabens: null
  std_positionenerfasst: boolean;
  std_registerabschlussfilled: boolean;
  std_startdatumausfhuerung_date: string;
  std_terminabnahme_date: string;
  std_terminelektroplanung: string;
  std_terminmassaufnahme_date: string;
  std_terminpoenale_date: string;
  std_terminwmplanung_date: string;
  std_terminschienevorgegeben: string;
  std_liefertermin: string;
  std_termin_endabnahme_kunde: string;
  std_verhandlungsprotokollabgelegt: boolean;
  std_verkaufschancen_kennung: string;
  std_versandausschreibungfertig: boolean;
  std_name: string;
  // std_wahrscheinlichkeit_ueberschreiben: null
  // stepid: null
  stepname: string;
  // teamsfollowed: null
  // timeline: null
  // timespentbymeonemailandmeetings: null
  timezoneruleversionnumber: number;
  totalamount: number;
  totalamount_base: number;
  totalamountlessfreight: number;
  totalamountlessfreight_base: number;
  totaldiscountamount: number;
  totaldiscountamount_base: number;
  totallineitemamount: number;
  totallineitemamount_base: number;
  totallineitemdiscountamount: number;
  totallineitemdiscountamount_base: number;
  totaltax: number;
  totaltax_base: number;
  // traversedpath: null
  // utcconversiontimezonecode: null
  versionnumber: number;
  // _accountid_value: null
  // _campaignid_value: null
  // _contactid_value: null
  _createdby_value: string;
  _createdonbehalfby_value: null;
  _customerid_value: string;
  _modifiedby_value: string;
  // _modifiedonbehalfby_value: null
  _msdyn_company_value: string;
  // _msdyn_workordertype_value: null
  _originatingleadid_value: string;
  _ownerid_value: string;
  _owningbusinessunit_value: string;
  // _owningteam_value: null
  _owninguser_value: string;
  _parentaccountid_value: string;
  _parentcontactid_value: string;
  // _pricelevelid_value: null
  // _slaid_value: null
  // _slainvokedid_value: null
  // _std_accountid_value: null
  // _std_auftragid_value: null
  _std_bauvorhabenid_value: string;
  _std_vertriebsteamid_value: string;
  _transactioncurrencyid_value: string;
  _std_projektleiter_stoebich_value: string;
  _std_gebietid_value: string;

  opportunity_quotes: CDSQuotes;
  opportunity_OpportunityCloses: CDSOpportunityClose;
  std_gebietid: CDSTerretory;
  std_bauvorhabenid: CDSBuildingProject;
  std_projektleiter_stoebich: CDSStdWorker;
  std_montagehilfedurchcode: string;
  std_art_der_montagehilfecode: number;
  std_abladung_durchcode: string;
  std_art_der_abladungcode: string;
  //todo: implement as new Class
  std_vertriebsteamid: any;
    std_nach_absprache_mit_dem_kundencode: string;
    // std_sanktionsprufung_durchgefuhrtcode: string;
    // std_bemerkungen: string
    // std_verwendbarkeitsnachweis: string
}
