import { Component, Input, OnInit } from '@angular/core';
import { CDSWorkorderService } from '../../../models/cds/cds-workorder-service';
import { CDSApiService } from '../../../service/cds-api.service';

@Component({
  selector: 'app-workorder-service-list',
  templateUrl: './workorder-service-list.component.html',
  styleUrls: ['./workorder-service-list.component.scss']
})
export class WorkorderServiceListComponent implements OnInit {
  spsWorkorderServiceList: CDSWorkorderService[];

  enableProgressSpinner: boolean;

  @Input() dataSource: CDSWorkorderService[];

  constructor(public cdsApiService: CDSApiService) {
    this.spsWorkorderServiceList = [];
  }

  ngOnInit(): void {
    this.initWorkorderList();
  }

  initWorkorderList(): void {
    this.enableProgressSpinner = true;
    this.dataSource.forEach((spsWorkorderService) => {
      this.cdsApiService.getEntity('msdyn_workorderservices', spsWorkorderService.msdyn_workorderserviceid).subscribe(
        (response: CDSWorkorderService) => {
          this.spsWorkorderServiceList.push(response);
        },
        (err: any) => console.error(err),
        () => (this.enableProgressSpinner = false)
      );
    });
  }
}
