import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SpsPos } from '../../../models/sps-pos';
import { ProjectApiService } from '../../../service/project-api.service';
import { SPSProject } from '../../../models/sps-project';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSIncidentType } from '../../../models/cds/cds-incidenttype';
import { SpsPosType } from '../../../models/sps-pos-type';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'dialog-position-detail',
  templateUrl: 'dialog-position-detail.html'
})
export class DialogPositionDetail implements OnInit {
  isNew: boolean;
  spsPos: SpsPos;
  posFormGroup: FormGroup;
  enableProgressSpinner: boolean;
  position: SpsPos;
  project: SPSProject;
  enableProgressBar: boolean;
  snackBarDuration: number = 2;
  spsPosTypeList: SpsPosType[];
  cdsIncidentTypeList: CDSIncidentType[];
  filteredOptions: Observable<CDSIncidentType[]>;
  filterValue: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogPositionDetail>,
    private snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private cdsApiService: CDSApiService,
    private projectApiService: ProjectApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.enableProgressSpinner = false;

    if (!!data.pos) {
      this.isNew = false;
      this.spsPos = data.pos;
    } else {
      this.isNew = true;
      this.spsPos = new SpsPos();

      this.spsPos.idSpsProject = data.project.id;
      this.spsPos.orderNumber = data.project.orderNumber;
    }

    this.posFormGroup = this._formBuilder.group({
      posNumber: new FormControl('', Validators.required),
      posNumberAlt: new FormControl(''),
      spsPosType: new FormControl('', Validators.required),
      cdsIncidentType: new FormControl('', Validators.required)
    });
    console.log(this.data);
  }

  ngOnInit(): void {
    this.loadProjectProductTypeList();
    this.loadProjectPositionTypeList();
    this.updateFilteredOptions();
  }

  btnClickAddProjectPosition(): void {
    this.spsPos = Object.assign(this.spsPos, this.posFormGroup.value);
    let duplicatedPositionNumber = this.data.positions.filter(
      (el) => el.posNumber === this.posFormGroup.controls['posNumber'].value
    );
    if (duplicatedPositionNumber.length > 0) {
      this.snackBar.open('Position must have unique number!', 'ok', {
        duration: this.snackBarDuration * 1000
      });
    } else {
      this.enableProgressSpinner = true;
      console.log('===== createNewProjectPosition');
      this.projectApiService.createNewProjectPosition(this.spsPos).subscribe(
        (res: SpsApiResponse) => {
          console.log(`===== createNewProjectPosition ${JSON.stringify(res)}`);
          this.dialogRef.close();

          /*
          this.projectApiService.calcProjectTimePlan(this.spsPos.idSpsProject).subscribe(
            (res: any) => {
              this.snackBar.open('Zeitplan erfolgreich berechnet', 'ok', {
                duration: this.snackBarDuration * 1000
              });
              //this.loadProjectPositionList();
            });
            */
        },
        (err: any) => {
          console.error(err);
          this.snackBar.open(err, 'ok', {
            duration: this.snackBarDuration * 1000
          });
        },
        () => (this.enableProgressSpinner = false)
      );
    }
  }

  loadProjectProductTypeList(): void {
    this.cdsApiService
      .getAllEntity(
        'msdyn_incidenttypes?$select=msdyn_name&$filter=statuscode%20eq%201%20and%20_std_parentincidenttypeid_value%20ne%20null&$orderby=msdyn_name%20asc'
      )
      .subscribe(
        (response: CDSApiResponse) => {
          this.cdsIncidentTypeList = response.value;
          this.updateFilteredOptions();
        },
        (err: any) => {
          console.error(err);
          this.snackBar.open(err, 'ok', {
            duration: this.snackBarDuration * 1000
          });
        },
        () => (this.enableProgressBar = false)
      );
  }

  loadProjectPositionTypeList(): void {
    this.projectApiService.getProjectPositionType().subscribe(
      (res: SpsApiResponse) => (this.spsPosTypeList = res.value),
      (err: any) => {
        console.error(err);
        this.snackBar.open(err, 'ok', {
          duration: this.snackBarDuration * 1000
        });
      },
      () => (this.enableProgressBar = false)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateFilteredOptions() {
    this.filteredOptions = this.posFormGroup.get('cdsIncidentType').valueChanges.pipe(
      startWith(''),
      map((value) => (value && typeof value === 'string' ? value : this.filterValue)),
      map((value) => this.filter(value || ''))
    );
  }

  filter(value: string): CDSIncidentType[] {
    const filterValue = value.toLowerCase();
    if (this.cdsIncidentTypeList) {
      return this.cdsIncidentTypeList.filter((option) => option.msdyn_name.toLowerCase().includes(filterValue));
    }
  }

  displayName(option: CDSIncidentType): string {
    return option ? option.msdyn_name : '';
  }
}
