<mat-form-field  class="full-width">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [formControl]="pickerControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='setSelectdUser($event.option.value)' [displayWith]="displayFn">
        <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>
        <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let user of userList" [value]="user">
                {{user.displayName}}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>

<mat-option *ngIf="userIsLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>

<div class="graph-user-details" *ngIf="!!selectedUser">
    <mat-list>
        <mat-list-item *ngIf="!!selectedUser.mail"><mat-icon>email</mat-icon> <a href="mailto:{{selectedUser.mail}}">{{selectedUser.mail}}</a></mat-list-item>
        <mat-list-item *ngFor="let phone of selectedUser.businessPhones"><mat-icon>local_phone</mat-icon> <a href="tel:{{phone}}">{{phone}}</a> </mat-list-item>
        <mat-list-item *ngIf="!!selectedUser.mobilePhone"><mat-icon>smartphone</mat-icon> <a href="tel:{{selectedUser.mobilePhone}}">{{selectedUser.mobilePhone}}</a></mat-list-item>
    </mat-list>
</div>