import { SpsPosType } from './sps-pos-type';
import { CDSIncidentType } from './cds/cds-incidenttype';
import { SpsWorkorder } from './sps-workorder';

export class SpsPos {
    id?: number;
    idSpsProject?: number;
    orderNumber?: string;
    spsPosType: SpsPosType;
    cdsIncidentType: CDSIncidentType;
    posNumber: string;
    //posNumberAlt: string;
    systeminfo?: string;
    startDateMech: string;
    endDateMech: string;
    workorder?: SpsWorkorder[];
}
