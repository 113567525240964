<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content *ngIf="!enableProgressSpinner">
    <form [formGroup]="workerFormGroup">  
        <div *ngIf="!isChecked">
            <p><em>Vorgeschlagene Ergebnisse</em></p>

            <mat-card>
                <mat-selection-list [multiple]="false" formControlName="workerList">
                    <mat-list-option *ngFor="let stdWorker of workerList" [value]="stdWorker">
                        {{ stdWorker.std_suchbegriff }}
                    </mat-list-option>
                </mat-selection-list>
            </mat-card>
            <br>
        </div>
        <div *ngIf="isChecked">
            <p><em>Individuelle Auswahl</em></p>
            
                <app-cds-contact-picker formControlName="workerIndividual">Person suchen ...</app-cds-contact-picker>
        </div>
    </form>

</div>
<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>


<div mat-dialog-actions>
    <mat-slide-toggle *ngIf="!isIndividualOnly" [(ngModel)]="isChecked">Manuelle Eingabe</mat-slide-toggle>

    <span class="spacer"></span>

    <button mat-button (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button (click)="btnClickSetWOrker()" color="primary" cdkFocusInitial>speichern</button>
</div>