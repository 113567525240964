<div mat-dialog-content *ngIf="!enableProgressSpinner">
    <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="posFormGroup">
            <ng-template matStepLabel>Ergänzende Informationen</ng-template>
            <!-- <h3>Bitte geben Sie eine Sollzeit der Montage an</h3> -->

            <form [formGroup]="posFormGroup">
                <!-- <mat-form-field class="full-width">
                    <mat-label>Service</mat-label>
                    <mat-select [(value)]="serviceProduct" formControlName="serviceProduct">
                        <mat-option *ngFor="let cdsProduct of cdsProductList" [value]="cdsProduct">
                            {{cdsProduct.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="full-width">
                    <mat-label>Startdatum</mat-label>
                    <input matInput [matDatepicker]="picker" class="form-control" id="startDateMech" [required]="true"
                        formControlName="startDateMech">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <mat-label>Enddatum</mat-label>
                    <input matInput [matDatepicker]="picker1" class="form-control" id="endDateMech" [required]="true"
                        formControlName="endDateMech">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Systeminfos</mat-label>
                    <input matInput type="text" placeholder="Beispiel: 3000x5000 mm" class="form-control"
                        id="systeminfo" formControlName="systeminfo">
                </mat-form-field>
            </form>

            <mat-divider></mat-divider>

            <div class="button-bar">
                <button mat-raised-button [disabled]="!posFormGroup.valid" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Absenden</ng-template>
            <!-- <pre>{{ data | json }}</pre> -->
            <table class="data-view-table">
                <tr>
                    <td><strong>Position</strong></td>
                    <td>{{ position.posNumber }}</td>
                </tr>
                <tr>
                    <td><strong>Montagezeit</strong></td>
                    <td>{{ position.startDateMech | date:'dd.MM.yyyy' }} - {{ position.endDateMech | date:'dd.MM.yyyy'}}
                    </td>
                <tr>
                    <td><strong>Produkttyp</strong></td>
                    <td>{{ position.cdsIncidentType.msdyn_name }}</td>
                </tr>
            </table>
            <mat-divider></mat-divider>

            <div class="button-bar">
                <button mat-button (click)="onNoClick()">Abbrechen</button>
                <button mat-raised-button color="primary" (click)="btnGenerateWorkOrder()">Jetzt erstellen</button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>

<mat-spinner *ngIf="enableProgressSpinner" diameter="30"></mat-spinner>