import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { DataboxApiClientService } from '../../service/databox-api-client.service';
import { PostboxService } from '../../service/postbox.service';
import { DataboxProduct } from '../../models/databox-product';
import { FormControl } from '@angular/forms';

interface Node {
  title: string;
  details?: Node[];
  data?: any;
}

interface FlatNode {
  expandable: boolean;
  title: string;
  level: number;
  id: number;
  product: DataboxProduct;
}

@Component({
  selector: 'app-databox-product-picker',
  templateUrl: './databox-product-picker.component.html',
  styleUrls: ['./databox-product-picker.component.scss']
})
export class DataboxProductPickerComponent implements OnInit, OnChanges {
  @Input() lang: string;
  @Input() shortView: boolean = false;
  @Output() change = new EventEmitter();

  productControl = new FormControl();

  private _transformer = (node: Node, level: number) => {
    return {
      expandable: !!node.details && node.details.length > 0,
      title: (level === 0) ? node.title : node.data.title,
      level: level,
      id: (level === 0) ? 0 : node.data.id,
      product: (level === 0) ? 0 : node.data,
    };
  }

  treeControl = new FlatTreeControl<any>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.details);

  productGroups = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  
  constructor(
    private databoxApiClientService: DataboxApiClientService,
    private postboxService: PostboxService) { }

  ngOnInit() {
    this.getProductGroup(this.lang);
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.getProductGroup(this.lang);
  }
 
  getProductGroup(lang: string) {
    this.databoxApiClientService.getProductGroup(this.lang)
      .subscribe(
        (productGroups: any) => this.transformList(productGroups.data)
      );
  }

  transformList(productGroupsRaw: Node[]) {
    productGroupsRaw.forEach(productGroup => {
      if(!!productGroup.details && productGroup.details.length > 0)
        productGroup.details.forEach(product => {
          if(!!product.data.details && product.data.details.length > 0)
            product.data.details.forEach(subproduct => {
              if(!productGroup.details.find(obj => obj.data.title === subproduct.data.title))
                productGroup.details.push(subproduct);
            });
        });
    });
    this.productGroups.data = productGroupsRaw;
  }

  checkboxChange(matCheckboxChange: MatCheckboxChange) {
    if(matCheckboxChange.checked)
      this.postboxService.push(matCheckboxChange.source.value);
    else
      this.postboxService.remove(matCheckboxChange.source.value);
  }

  selectChange(id: number) {
    if(this.postboxService.isSelected(id))
      this.postboxService.push(id);
    else
      this.postboxService.remove(id);

    this.selectProduct(id);
  }

  checkboxIsChecked(id: number){
    return this.postboxService.isSelected(id);
  }

  selectProduct(id: number) {
    this.change.emit(id);
  }
  
  hasChild = (_: number, node: FlatNode) => node.expandable;
}
