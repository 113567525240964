import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private _msalService: MsalService) { }

  getRoles(){
    return (this._msalService.getAccount() != null) ? (this._msalService.getAccount().idToken as any).roles : [];
  }

  canAccess(route: any):boolean {
    if(route.data === undefined){
      return false;
    }
    if(route.data["roles"] === undefined || route.data["roles"].length === 0){
      return true;
    }

    const userRoles = this.getRoles();
    const allowedRoles = route.data["roles"];
    const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
    return matchingRoles.length > 0;
  }
}
