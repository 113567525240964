import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PostboxService {
  selectedProducts: number[];

  constructor() { 
    if(localStorage.getItem('db.postbox') != undefined)
      this.selectedProducts = JSON.parse(localStorage.getItem('db.postbox'));
    else
      this.selectedProducts = [];
  }

  push(id: any){
    this.selectedProducts.push(id.id);
    localStorage.setItem('db.postbox', JSON.stringify(this.selectedProducts));
  }

  remove(id: any){
    this.selectedProducts.splice(this.selectedProducts.indexOf(id.id),1);
    localStorage.setItem('db.postbox', JSON.stringify(this.selectedProducts));
  }

  getAll(): number[] {
    return this.selectedProducts;
  }
  
  isSelected(id: number): boolean {
    return this.selectedProducts.indexOf(id) > -1;
  }

  count(): number {
    return this.selectedProducts.length;
  }
}
