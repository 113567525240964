<mat-toolbar>
  <!-- <button mat-icon-button color="primary" (click)="btnClickCreateProject()"><mat-icon>add</mat-icon></button> -->
  <button mat-icon-button (click)="loadOpportunityList(tableRadioFilter)"><mat-icon>autorenew</mat-icon></button>

  <span class="spacer"></span>
  <mat-radio-group aria-label="Select an filter" [(ngModel)]="tableRadioFilter">
    <mat-radio-button *ngFor="let filter of radioFilter" [value]="filter" (change)="onChangeTableRadioFilter(filter)">
      {{ filter.name }}
    </mat-radio-button>
  </mat-radio-group>
  <span class="spacer"></span>
  <mat-form-field class="input-filter">
    <input matInput (keyup)="applyFilter($event.target)" placeholder="Suchen" />
  </mat-form-field>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="headline-wrapper">
  <h2>{{ tableRadioFilter.title }}</h2>
  <mat-checkbox (change)="hideExport($event.checked)" [checked]="tbsChecked"> TBS ausblenden </mat-checkbox>
  <mat-checkbox (change)="hideNonDach($event.checked)" [checked]="nonDachChecked" style="padding-left:10px"> DACH ausblenden </mat-checkbox>
</div>

<table mat-table [dataSource]="cdsOpportunityList" multiTemplateDataRows matSort>
  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="std_bauvorhabenid.std_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>BV</th>
    <td mat-cell *matCellDef="let element">{{ element.std_bauvorhabenid?.std_name }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="offer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Angebot</th>
    <td mat-cell *matCellDef="let element">{{ element.opportunity_quotes[0]?.msdyn_quotenumber }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="vcnumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>VC-Nummer</th>
    <td mat-cell *matCellDef="let element">{{ element.std_verkaufschancen_kennung }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="salesTeam">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vertriebsteam</th>
    <td mat-cell *matCellDef="let element">{{ element.std_vertriebsteamid?.std_name }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="budget">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Volumen AE</th>
    <td mat-cell *matCellDef="let element">{{ element.opportunity_OpportunityCloses[0]?.actualrevenue }} €</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="closeDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Abgeschlossen am</th>
    <td mat-cell *matCellDef="let element">
      {{ element.opportunity_OpportunityCloses[0]?.actualend | date: 'dd.MM.yyyy' }}
    </td>
  </ng-container>

  <!-- Symbol Column std_bauvorhabenid-->
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Startdatum Montage</th>
    <td mat-cell *matCellDef="let element">{{ element.std_startdatumausfhuerung_date | date: 'dd.MM.yyyy' }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gebiet</th>
    <td mat-cell *matCellDef="let element">{{ element.std_gebietid?.name }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="projectManager">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Projektleiter</th>
    <td mat-cell *matCellDef="let element">{{ element.std_projektleiter_stoebich?.std_suchbegriff }}</td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let element" style="margin: 0px; padding: 0px; display: flex">
      <button class="tableButtons" mat-raised-button (click)="btnClickOpensetProjectManagerModal(element)">
        Projektleiter zuordnen
      </button>
      <button
        class="tableButtons"
        mat-raised-button
        (click)="btnClickOpenNewProjectModal(element)"
        color="primary"
        [disabled]="element.std_projektleiter_stoebich === null"
      >
        Projekt erstellen
      </button>
      <button
        class="tableButtons"
        mat-raised-button
        target="_blank"
        (click)="onOpenExternalLink(element.opportunityid)"
      >
        in D365 CE Sales anzeigen
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="table-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-element-detail-body">
          <app-project-info *ngIf="element === expandedElement" [dataSource]="element.opportunityid"></app-project-info>
        </div>
        <div></div>
      </div>
    </td>
  </ng-container>

  <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="table-element-row"
    [class.table-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100, 200]"></mat-paginator>
