import { SPSProject } from './sps-project';

export class SpsBuildingProject {
    std_addresse1_postleitzahl: string;
    std_adresse1_land: string;
    std_adresse1_ort: string;
    std_adresse1_strasse1: string;
    std_bauvorhabenid: string;
    std_eigentuemerid: string;
    std_name: string;
}
