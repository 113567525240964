import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ProjectApiService } from '../../../service/project-api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { DialogGenerateProject } from './dialog-generate-project';
import { CDSOpportunity } from '../../../models/cds/cds-opportunity';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CDSOpportunityClose } from '../../../models/cds/cds-opportunity-close';
import { CdsDialogSetWorker } from '../../cds/cds-dialog-set-worker/cds-dialog-set-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  providers: [DatePipe]
})
export class OpportunityListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'name',
    'vcnumber',
    'std_bauvorhabenid.std_name',
    'offer',
    'salesTeam',
    'budget',
    'closeDate',
    'startDate',
    'area',
    'projectManager',
    'buttons'
  ];
  radioFilter = [
    {
      name: 'Qualified',
      title: 'Alle qualifizierten',
      filter:
        'statecode%20eq%201%20and%20std_auftragid%20eq%20null%20and%20std_gebietid%20ne%20null%20and%20std_vertriebsteamid%20ne%20null'
    },
    {
      name: 'Alle',
      title: 'Alle Abgeschlossene Verkaufschancen',
      filter: 'statecode%20eq%201%20and%20std_auftragid%20eq%20null'
    },
    // {
    //   name: 'hide Export/TBS',
    //   title: 'hide Export/TBS',
    //   filter:
    //     "statecode eq 1 and (not contains(std_vertriebsteamid/std_name, 'TBS') and not contains(std_vertriebsteamid/std_name, 'Export')) and std_auftragid eq null"
    // },
    {
      name: 'Projektleiter FAA',
      title: 'Alle Abgeschlossene Verkaufschancen (für Projektleiter)',
      filter: "statecode%20eq%201%20and%20((contains(std_gebietid/name,'FAA')%20or%20contains(std_vertriebsteamid/std_name,'FAA'))%20and%20std_auftragid%20eq%20null)"
    },
    {
      name: 'Projektleiter FLEX',
      title: 'Alle Abgeschlossene Verkaufschancen (für Projektleiter)',
      filter: "statecode%20eq%201%20and%20((contains(std_gebietid/name,'Flex')%20or%20contains(std_vertriebsteamid/std_name,'Flex'))%20and%20std_auftragid%20eq%20null)"
      },
    {
      name: 'Kauf. Abwicklung',
      title: 'Alle Abgeschlossene Verkaufschancen (für die Kauf. Abwicklung)',
      filter:
        'statecode%20eq%201%20and%20std_auftragid%20eq%20null%20and%20_std_projektleiter_stoebich_value%20ne%20null'
    }
  ];

  expandedElement: CDSOpportunity | null;

  cdsOpportunityList = new MatTableDataSource<CDSOpportunity>();

  selection = new SelectionModel<CDSOpportunity>(true, []);

  snackBarDuration: number = 3;

  debugData: any = {};

  enableProgressBar: boolean = false;

  tableRadioFilter;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cdsOpportunity: CDSOpportunity[];
  nonDachChecked: boolean = true;
  tbsChecked: boolean = true;
  originalData: CDSOpportunity[];

  constructor(
    public dialog: MatDialog,
    public projectApiService: ProjectApiService,
    private cdsApiService: CDSApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dataPipe: DatePipe
  ) {
    this.cdsOpportunity = [];
    this.tableRadioFilter = this.radioFilter[0];
  }

  ngOnInit() {
    this.cdsOpportunityList.paginator = this.paginator;
    this.loadOpportunityList(this.tableRadioFilter);
  }

  ngAfterViewInit() {
    this.cdsOpportunityList.sort = this.sort;
  }

  onChangeTableRadioFilter(filter): void {
    this.loadOpportunityList(filter);
  }

  loadOpportunityList(filter): void {
    this.enableProgressBar = true;
    this.cdsApiService
      .getAllEntity(
          `opportunities?$filter=${filter.filter}&$select=name,_std_bauvorhabenid_value,_std_projektleiter_stoebich_value,_parentaccountid_value,std_verkaufschancen_kennung,std_startdatumausfhuerung_date&$expand=std_bauvorhabenid($expand=std_landid;$select=std_name),std_projektleiter_stoebich($select=std_suchbegriff),std_gebietid($select=name),opportunity_OpportunityCloses($filter=statecode%20eq%201;$select=actualend,actualrevenue),opportunity_quotes($top=1;$filter=statecode%20eq%202;$select=msdyn_quotenumber,std_beauftragte_produktgruppecode),std_vertriebsteamid($expand=std_benutzerid,std_vertriebsinnendienstid;$select=std_name)`
      )
      .subscribe(
          (response: CDSApiResponse) => {
          this.originalData = response.value;
          this.hideData();
          this.cdsOpportunityList._updateChangeSubscription();
        },
        (err: any) => console.error(err),
        () => (this.enableProgressBar = false)
      );

    this.cdsOpportunityList.filterPredicate = function (data, filter: string): boolean {
      let result = false;

      if (filter == undefined || data == undefined) {
        return result;
      }
      //const currentDate = new Date();

      //const options1 = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

      // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
      // https://devhints.io/wip/intl-datetime
      //new Intl.DateTimeFormat('de-DE', { dateStyle: 'full', timeStyle: 'long' }).format(date));

      // new Intl.DateTimeFormat('de-DE', {
      //   year: '2-digit',
      //   month: '2-digit',
      //   day: '2-digit'
      // }).format(date));

      // new Intl.DateTimeFormat('de-DE', {
      //   year: '2-digit',
      //   month: '2-digit',
      //   day: '2-digit'
      // }).format(new Date(data.std_startdatumausfhuerung_date))).toLowerCase().includes(filter)

      result =
        data.name?.toLowerCase().includes(filter) ||
        data.std_bauvorhabenid?.std_name?.toLowerCase().includes(filter) ||
        data.opportunity_quotes[0]?.msdyn_quotenumber?.toLowerCase().includes(filter) ||
        data.std_verkaufschancen_kennung?.toLowerCase().includes(filter) ||
        data.std_vertriebsteamid?.std_name?.toLowerCase().includes(filter) ||
        data.opportunity_OpportunityCloses[0]?.actualrevenue?.toString().toLowerCase().includes(filter) ||
        // Date Filter does not work yet "'Missing locale data for the locale "de-DE""
        //new DatePipe('de-DE').transform(data.opportunity_OpportunityCloses[0]?.actualend,"dd.MM.yyyy").toLowerCase().includes(filter) ||
        //this.datePipe.transform(data.std_startdatumausfhuerung_date, "dd.MM.yyyy").toLowerCase().includes(filter) ||
        data.opportunity_OpportunityCloses[0]?.actualend?.toLowerCase().includes(filter) ||
        data.std_startdatumausfhuerung_date?.toLowerCase().includes(filter) ||
        // new Intl.DateTimeFormat('de-DE', {
        //   year: '2-digit',
        //   month: '2-digit',
        //   day: '2-digit'
        // }).format(new Date(data.std_startdatumausfhuerung_date))).toLowerCase().includes(filter)
        data.std_gebietid?.name?.toLowerCase().includes(filter) ||
        data.std_projektleiter_stoebich?.std_suchbegriff?.toLowerCase().includes(filter);
      return result;
    };

    this.cdsOpportunityList.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        case 'std_bauvorhabenid.std_name':
          return item.std_bauvorhabenid?.std_name;
        case 'offer':
          return item.opportunity_quotes[0]?.msdyn_quotenumber;
        case 'vcnumber':
          return item.std_verkaufschancen_kennung;
        case 'salesTeam':
          return item.std_vertriebsteamid?.std_name;
        case 'budget':
          return item.opportunity_OpportunityCloses[0]?.actualrevenue;
        case 'closeDate':
          return new Date(item.opportunity_OpportunityCloses[0]?.actualend);
        case 'startDate':
          return new Date(item.std_startdatumausfhuerung_date);
        case 'area':
          return item.std_gebietid?.name;
        case 'projectManager':
          return item.std_projektleiter_stoebich?.std_suchbegriff;
        default:
          return item[property];
      }
    };
  }

  getExternalLink(opportunityId): string {
    return this.cdsApiService.getExternalLink('opportunity', opportunityId);
  }

  getStdWorkerByTerretorryId(terretoryid: string): Promise<CDSOpportunityClose> {
    if (!terretoryid) return null;

    return new Promise((resolve, reject) => {
      this.cdsApiService
        .getEntityByFilter('territories', 'territoryid', terretoryid, 'std_territory_std_worker')
        .subscribe(
          (response: CDSApiResponse) => {
            console.log(response.value[0].std_territory_std_worker);
            resolve(response.value[0].std_territory_std_worker);
          },
          (err: any) => reject(err)
        );
    });
  }

  // addEvent(filterValue: string, event) {
  //   debugger;

  //   if (event.value != undefined) {
  //     filterValue = this.dataPipe.transform(filterValue, 'dd.MM.yyyy');
  //     console.log(filterValue);
  //   }
  //   this.dataSource.filter = filterValue.trim();
  // }

  applyFilter(filterValue) {
    this.cdsOpportunityList.filter = filterValue.value.trim().toLowerCase();
    if (this.cdsOpportunityList.paginator) {
      this.cdsOpportunityList.paginator.firstPage();
    }
  }

  btnClickOpensetProjectManagerModal(cdsOpportunity: CDSOpportunity): void {
    const _self = this;

    Promise.all([this.getStdWorkerByTerretorryId(cdsOpportunity.std_gebietid?.territoryid)]).then(function (results) {
      const dialogRef = _self.dialog.open(CdsDialogSetWorker, {
        width: '500px',
        data: {
          cdsWorkerList: results[0],
          title: 'Projektleiter zuordnen'
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (!!result)
          _self.cdsApiService
            .updateEntity('opportunities', cdsOpportunity.opportunityid, {
              'std_projektleiter_stoebich@odata.bind': `/std_workers(${result.std_workerid})`
            })
            .subscribe(
              (cdsApiResponse: CDSApiResponse) => {
                _self.snackBar.open(result.std_suchbegriff + ' als Projektleiter zugerodnet', 'ok', {
                  duration: _self.snackBarDuration * 1000
                });
                _self.loadOpportunityList(_self.tableRadioFilter);
              },
              (err: any) => console.error(err)
            );
      });
    });
  }

  btnClickOpenNewProjectModal(cdsOpportunity: CDSOpportunity): void {
    const dialogRef = this.dialog.open(DialogGenerateProject, {
      width: '800px',
      data: {
        cdsOpportunity: cdsOpportunity
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) this.router.navigate([`/sps/project/${result}`]);
    });
  }

  onOpenExternalLink(link: string) {
    window.open(this.getExternalLink(link), '_blank');
  }

  hideData() {
      let filteredData = [...this.originalData];
    if (this.tbsChecked) {
        filteredData = filteredData.filter((data: CDSOpportunity) => {
        return !data?.std_gebietid?.name.includes('Export') && !data?.std_gebietid?.name.includes('TBS') && !data?.std_vertriebsteamid?.std_name?.includes('TBS') ;
      });
    }
    if (this.nonDachChecked) {
      filteredData = filteredData.filter((data: any) => {
        const iso = data?.std_bauvorhabenid?.std_landid?.std_iso;
        return iso === 'DE' || iso === 'CH' || iso === 'AT';
      });
    }
    this.cdsOpportunityList.data = filteredData;
  }

  hideExport(el: boolean) {
    this.tbsChecked = el;
    this.hideData();
  }

  hideNonDach(checked: boolean) {
    this.nonDachChecked = checked;
    this.hideData();
  }
}
