import { Component, OnInit } from '@angular/core';
import { ConfigDataHandlerService } from '../../service/config-data-handler.service';
import { NavElement } from '../../models/nav-element';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  timeNow: Date = new Date();
  navElements: NavElement[];
  routes: any;

  constructor(
    private configDataHandlerService: ConfigDataHandlerService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.timeNow = new Date();
    }, 1);
  }

  navigate(path: string[]) {
    this.router.navigate(path);
  }
}
