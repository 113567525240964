import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TicketNode } from '../models/ticket-node';
import { retry, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { TicketDepartment } from '../models/ticket-department';
import { NavElement } from '../models/nav-element';
import { LinkList } from '../models/link-list';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataHandlerService {

  constructor(private http: HttpClient) { }

  getTicketNodes() : Observable<TicketNode[]>{
    return this.http.get<TicketNode[]>("./assets/config.ticketnode.json")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getTicketDepartments() : Observable<TicketDepartment[]>{
    return this.http.get<TicketDepartment[]>("./assets/config.ticketqueue.json")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getNavElements() : Observable<NavElement[]>{
    return this.http.get<NavElement[]>("./assets/config.navelements.json")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getLinkList() : Observable<LinkList[]>{
    return this.http.get<LinkList[]>("./assets/config.linklist.json")
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      '[Error] Config data connection faild; please try again later.');
  };
}
