import { Component, OnInit} from '@angular/core';
import { DataboxApiClientService } from '../../service/databox-api-client.service';
import { DataboxLanguage } from '../../models/databox-language';
import { DataboxApiConfig } from '../../models/databox-api-config';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.scss']
})
export class DatasheetComponent implements OnInit {
  
  currentPdf: string;
  languages: DataboxLanguage[];
  isLoaded: boolean;
  currentProduct: number;
  enableProgressBar: boolean = false;

  constructor(
    public databoxApiClientService: DataboxApiClientService,
    private _snackBar: MatSnackBar
  ) { 
    this.isLoaded = false;
    this.enableProgressBar = false;
  }

  ngOnInit() {

    this.databoxApiClientService.getToken()
    .subscribe(
      (data: DataboxApiConfig) => {
        this.databoxApiClientService.setConfig(data);
        this.getLanguages();
        this.isLoaded = true;
      },       
      (err) => this._snackBar.open(err,"schließen"),
      () => {

      }
    );
  }


  reloadPDF(id?: number): void {
    if(id === undefined)
      return;
    
    this.enableProgressBar = true;
    this.currentProduct = id;
    this.currentPdf = this.databoxApiClientService.getPDF(id);
  }
  
  getLanguages() {
    this.databoxApiClientService.getLanguages()
      .subscribe(
        (data: any) => this.languages = data.data,       
        (err) => console.error(err),
        () => this.isLoaded = true
      );
  }
}
