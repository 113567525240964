import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CDSApiService } from '../../../service/cds-api.service';
import { CDSResourceRequirement } from '../../../models/cds/cds-resourcerequirement';
import { CDSWorkorder } from '../../../models/cds/cds-workorder';
import { forkJoin, map, Observable, retryWhen, tap, timer, delayWhen } from 'rxjs';
import { CDSApiResponse } from '../../../models/cds/cds-api-response';
import { CDSProduct } from '../../../models/cds/cds-product';
import { SpsPos } from '../../../models/sps-pos';
import { SPSProject } from '../../../models/sps-project';

@Component({
  selector: 'dialog-generate-resource-requirement',
  templateUrl: 'dialog-generate-resource-requirement.html'
})
export class DialogResourceRequirement implements OnInit {
  resourceFormGroup: FormGroup;
  enableProgressSpinner: boolean;
  resourceReq: CDSResourceRequirement;
  workorder: CDSWorkorder;
  snackBarDuration: number = 2;
  serviceProduct: CDSProduct;
  cdsProductList: CDSProduct[];
  serviceTasks: any[] = [];
  position: SpsPos;
  project: SPSProject;
  st: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<DialogResourceRequirement>,
    private _formBuilder: FormBuilder,
    private cdsApiService: CDSApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.resourceFormGroup = this._formBuilder.group({
      serviceProduct: new FormControl(''),
      serviceTasks: new FormControl(''),
      installers: new FormControl('', Validators.required),
      installationTargetHours: new FormControl(null),
      installationTargetDays: new FormControl(null),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required)
    });
    this.workorder = this.data.workorder;
    this.position = this.data.position;
    this.project = this.data.project;
    let retryAttempt = 0;
    this.st = this.cdsApiService
      .getWorkorderServiceTasks(
        'msdyn_workorderservicetasks',
        `?$filter=(_msdyn_workorder_value%20eq%20${this.workorder.msdyn_workorderid})&$top=50`
      )
      .pipe(
        map((res) => {
          if (Object.keys(res.value).length === 0 && retryAttempt < 7) {
            //error will be picked up by retryWhen
            retryAttempt++;
            throw res;
          } else if (retryAttempt === 7) {
            this.snackBar.open('There is some problem with obtaining data, please try again later!', 'ok', {
              duration: this.snackBarDuration * 1000
            });
            this.dialogRef.close();
          }
          this.enableProgressSpinner = false;
          return res.value;
        }),
        retryWhen((errors) =>
          errors.pipe(
            //log error message
            tap((val) => console.log(`${val} length was  0!`)),
            //restart in 2 seconds
            delayWhen((val) => timer(2000))
          )
        )
      );
  }

  ngOnInit(): void {
    this.resourceFormGroup.patchValue({
      startDate: this.position.startDateMech,
      endDate: this.position.endDateMech
    });
    this.enableProgressSpinner = true;
    this.loadProductList();
  }

  generateResourceRequirement(): void {
    let numberOfResourceRequirements: number;
    this.enableProgressSpinner = true;
    const observablesList: Observable<any>[] = [];
    const observablesListTable: Observable<any>[] = [];
    let installerCount = 0;
    let durationSum = 0;
    for (let index = 0; index < +this.resourceFormGroup.get('installers').value; index++) {
      installerCount++;
      let time = this.resourceFormGroup.get('installationTargetHours').value;
      observablesList.push(
        this.cdsApiService.createEntityNew('msdyn_resourcerequirements', {
          statecode: +0,
          statuscode: +1,
          msdyn_name: this.workorder.msdyn_name,
          std_dauer_stunden: time,
          msdyn_effort: 1,
          msdyn_fromdate: this.resourceFormGroup.get('startDate').value,
          msdyn_todate: this.resourceFormGroup.get('endDate').value,
          msdyn_worklocation: this.cdsApiService.getDefault('msdyn_worklocation'),
          'msdyn_WorkOrder@odata.bind': `/msdyn_workorders(${this.workorder.msdyn_workorderid})`
        })
      );
    }
    //   this.workorder.workorderService.forEach((wo, i) => {
    //     this.cdsApiService
    //       .updateEntity('msdyn_workorderservices', wo.msdyn_workorderserviceid, {
    //         std_anzahl_der_ressourcen: wo.std_anzahl_der_ressourcen + installerCount,
    //         std_dauer: wo.std_dauer + durationSum
    //       })
    //       .subscribe(
    //         (response: CDSApiResponse) => {
    //           console.log(response);
    //         },
    //         (err: any) => {
    //           console.error(err);
    //           this.snackBar.open(err.error.message, 'ok', {
    //             duration: this.snackBarDuration * 1000
    //           });
    //           this.dialogRef.close();
    //         }
    //       );
    //   });
    forkJoin(observablesList).subscribe((res) => {
      numberOfResourceRequirements = res.length;
      for (let i = 0; i < res.length; i++) {
        this.serviceTasks.forEach((el) => {
          let obj = {
            '@odata.id': `https://stoebichgroup.crm4.dynamics.com/api/data/v9.2//msdyn_resourcerequirements(${res[i].body.msdyn_resourcerequirementid})`
          };
          let entity = `msdyn_workorderservicetasks(${el})/std_msdyn_workorderservicetask_msdyn_resource/$ref`;
          observablesListTable.push(this.cdsApiService.createRelationTable(entity, obj));
        });
      }
      forkJoin(observablesListTable).subscribe((res) => {
        this.dialogRef.close();
        this.snackBar.open(numberOfResourceRequirements + ' Anforderungen erstellt', 'ok', {
          duration: this.snackBarDuration * 1000
        });
        this.enableProgressSpinner = false;
        this.dialogRef.close();
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  loadProductList(): void {
    this.enableProgressSpinner = true;
    this.cdsApiService
      .getAllEntity(
        'products?$select=name,productid,productnumber&$filter=std_pit_relevant%20eq%20true%20and%20msdyn_fieldserviceproducttype%20eq%20690970002'
      )
      .subscribe(
        (response: CDSApiResponse) => {
          this.cdsProductList = response.value;
        },
        (err: any) => console.error(err),
        () => console.log('product list loaded')
      );
  }

  //   generateWorkOrderService(idCDSWorkorder: string, workOrderName: string): void {
  //     this.enableProgressSpinner = true;
  //     const observablesList: Observable<any>[] = [];
  //     const observablesListTable: Observable<any>[] = [];
  //     let installerCount = 0;
  //     let durationSum = 0;
  //     for (let index = 0; index < +this.resourceFormGroup.get('installers').value; index++) {
  //       installerCount++;
  //       let time = this.resourceFormGroup.get('installationTargetHours').value;
  //       durationSum += time;
  //       observablesList.push(
  //         this.cdsApiService.createEntityNew('msdyn_resourcerequirements', {
  //           statecode: +0,
  //           statuscode: +1,
  //           msdyn_name: this.workorder.msdyn_name,
  //           msdyn_duration: time,
  //           msdyn_effort: 1,
  //           msdyn_fromdate: this.resourceFormGroup.get('startDate').value,
  //           msdyn_todate: this.resourceFormGroup.get('endDate').value,
  //           msdyn_worklocation: this.cdsApiService.getDefault('msdyn_worklocation'),
  //           'msdyn_WorkOrder@odata.bind': `/msdyn_workorders(${this.workorder.msdyn_workorderid})`
  //         })
  //       );
  //     }
  //     let hours = this.resourceFormGroup.get('installationTargetHours').value;
  //     let days = this.resourceFormGroup.get('installationTargetDays').value * 24;
  //     let time = hours + days;
  //     this.cdsApiService
  //       .createEntity('std_field_service_aktivitaets', {
  //         statecode: +0,
  //         statuscode: +1,
  //         std_aktivitaetsnummer: 'act-' + workOrderName,
  //         std_name: 'Aktivität ' + workOrderName,
  //         std_startdatum: this.position.startDateMech,
  //         std_faelligkeitsdatum: this.position.endDateMech
  //       })
  //       .subscribe(
  //         (response: HttpResponse<any>) => {
  //           const activityId = this.cdsApiService.getResponseIdByHeader(response.headers.get('odata-entityid'));
  //           this.cdsApiService
  //             .createEntity('msdyn_workorderservices', {
  //               statecode: +0,
  //               statuscode: +1,
  //               std_default: true,
  //               msdyn_description: this.position.systeminfo,
  //               std_anzahl_der_ressourcen: +this.resourceFormGroup.get('installers').value,
  //               std_dauer: time,
  //               'msdyn_service@odata.bind': `/products(${this.serviceProduct.productid})`,
  //               'msdyn_workorder@odata.bind': `/msdyn_workorders(${idCDSWorkorder})`,
  //               'msdyn_unit@odata.bind': `/uoms(${this.cdsApiService.getDefault('uoms')})`,
  //               'std_companyid@odata.bind': `/cdm_companies(${this.cdsApiService.getDefault('cdm_companies')})`,
  //               'std_serviceaufgabe@odata.bind': `/std_serviceaufgabes(${this.cdsApiService.getDefault(
  //                 'std_serviceaufgabes'
  //               )})`,
  //               'std_serviceobjektid@odata.bind': `/std_serviceobjekts(${this.cdsApiService.getDefault(
  //                 'std_serviceobjekts'
  //               )})`,
  //               'std_field_service_activitaetid@odata.bind': `/msdyn_workorders(${activityId})`
  //             })
  //             .subscribe(
  //               (response: HttpResponse<any>) => {
  //                 // this.snackBar.open('Arbeitsauftrag erstellt', 'ok', {
  //                 //   duration: this.snackBarDuration * 1000
  //                 // });
  //                 forkJoin(observablesList).subscribe((res) => {
  //                   for (let i = 0; i < res.length; i++) {
  //                     this.serviceTasks.forEach((el) => {
  //                       let obj = {
  //                         '@odata.id': `https://stoebichgroup.crm4.dynamics.com/api/data/v9.2//msdyn_resourcerequirements(${res[i].body.msdyn_resourcerequirementid})`
  //                       };
  //                       let entity = `msdyn_workorderservicetasks(${el})/std_msdyn_workorderservicetask_msdyn_resource/$ref`;
  //                       observablesListTable.push(this.cdsApiService.createRelationTable(entity, obj));
  //                     });
  //                   }
  //                   forkJoin(observablesListTable).subscribe((res) => {
  //                     this.dialogRef.close();
  //                     this.snackBar.open(res.length + ' Anforderungen erstellt', 'ok', {
  //                       duration: this.snackBarDuration * 1000
  //                     });
  //                     this.enableProgressSpinner = false;
  //                     this.dialogRef.close();
  //                   });
  //                 });
  //               },
  //               (err: any) => {
  //                 console.error(err);
  //                 this.snackBar.open(err.error.message, 'ok', {
  //                   duration: this.snackBarDuration * 1000
  //                 });
  //                 this.dialogRef.close();
  //               }
  //             );
  //         },
  //         (err: any) => {
  //           console.error(err);
  //           this.snackBar.open(err.error.message, 'ok', {
  //             duration: this.snackBarDuration * 1000
  //           });
  //           this.dialogRef.close();
  //         }
  //       );
  //   }
    
    closeDialog() {
        this.dialogRef.close();
    }
}
