<mat-divider></mat-divider>
<mat-toolbar>
    <!-- <button mat-icon-button color="primary" (click)="btnClickCreateBuildingProject()"><mat-icon>add</mat-icon></button> -->
    <button mat-icon-button (click)="loadBuildingProjecttList()"><mat-icon>autorenew</mat-icon></button>
    <span class="spacer"></span>
    <mat-form-field class="input-filter">
        <input matInput (keyup)="applyFilter($event.target)" placeholder="Suchen">
    </mat-form-field>
</mat-toolbar>
<mat-progress-bar *ngIf="enableProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="headline-wrapper"><h2>Alle Bauvorhaben</h2></div>
<table mat-table [dataSource]="buildingProjectList" matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="std_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> <a [routerLink]="['/sps/buildingProject', element.std_bauvorhabenid]">{{element.std_name}}</a> </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="createdon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Erstellt am </th>
        <td mat-cell *matCellDef="let element"> {{element.createdon | date:'dd.MM.yyyy HH:mm'}} Uhr</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100, 200]"></mat-paginator>
