<!-- <pre>{{ response | json }}</pre> -->

<div class="wrapper">

<mat-vertical-stepper [linear]="isLinear" #stepper *ngIf="!surveyFinished">
    <mat-step >
        <ng-template matStepLabel>Login</ng-template>

            <h2 class="text-center">Initialisierung ...</h2>
            
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Umfrage auswählen</ng-template>

        <h2 class="text-center">Bitte wählen Sie nun die Gewünschte Umfrage</h2>

        <mat-selection-list #selectedSurvey>
            <mat-list-option *ngFor="let survey of surveyList" [value]="survey"><!--disabled="{{survey.surveyls_title.active}} === 'N'"-->
              {{survey.surveyls_title}} ({{survey.sid}})
              <mat-divider></mat-divider>
            </mat-list-option>
        </mat-selection-list>

        <div class="button-bar">
            <button mat-button matStepperPrevious>Zurück</button>
            <button mat-raised-button matStepperNext [disabled]="surveySelected">Weiter</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Kontaktdaten eingeben</ng-template>

        <h2 class="text-center">Bitte geben Sie die Kontaktdaten des Empfängers ein</h2>

        <form [formGroup]="contactFormGroup" class="login-form">
            <mat-form-field>
                <input matInput placeholder="Vorname" formControlName="firstname" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Nachname" formControlName="lastname" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="email" placeholder="E-Mail" formControlName="email" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Auftragsnummer" formControlName="ordernumber" required>
            </mat-form-field>
            
            <hr>
            <div class="button-bar">
                <button mat-raised-button (click)="btnLookUpProjectClick()">Projektleiter suchen</button>
            </div>
            <hr>

            <mat-form-field>
                <input matInput placeholder="Projektleiter" formControlName="plName" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="email" placeholder="E-Mail" formControlName="plMail" required>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Telefon" formControlName="plPhone" required>
            </mat-form-field>
        </form>


        <div class="button-bar">
            <button mat-raised-button (click)="btnAddParticipantClick()" [disabled]="!contactFormGroup.valid">Hinzufügen</button>
        </div>

        <mat-divider></mat-divider>

        <div class="text-center">
            <mat-list>
                <div mat-subheader>Empfänger</div>
                <mat-list-item *ngFor="let participant of newParticipants">
                <div mat-line>{{participant.firstname}} {{participant.lastname}}</div>
                <div mat-line> {{participant.email}} </div>
                </mat-list-item>
            </mat-list>
        </div>

        <mat-divider></mat-divider>

        <div class="button-bar">
            <button mat-button matStepperPrevious>Zurück</button>
            <button mat-raised-button (click)="btnNewParticipantsClick()" [disabled]="newParticipants.length === 0">Weiter</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Absenden</ng-template>
        
        <h2 class="text-center">Bitte Kontrollieren Sie den eingegebenen Kontakt und klicken Sie auf absenden</h2>

        <table *ngFor="let participant of participants" class="dom-center">
            <tr><td><strong>ID</strong></td><td>{{participant.tid}}</td></tr>
            <tr><td><strong>Vorname</strong></td><td>{{participant.firstname}}</td></tr>
            <tr><td><strong>Nachname</strong></td><td>{{participant.lastname}}</td></tr>
            <tr><td><strong>E-Mail</strong></td><td>{{participant.email}}</td></tr>
            <tr><td><strong>Auftragsnummer</strong></td><td>{{participant.attribute_1}}</td></tr>
            <tr><td><strong>Zuordnungstoken</strong></td><td>{{participant.token}}</td></tr>
            <tr><td><strong>E-Mail Status</strong></td><td>{{participant.emailstatus}}</td></tr>
        </table>

        <mat-divider></mat-divider>

        <div class="button-bar">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" (click)="btnSendSurveyClick()">absenden</button>
        </div>
    </mat-step>
    </mat-vertical-stepper>

    
    <mat-card *ngIf="surveyFinished">
        <div class="button-bar">
            <h2 class="text-center">Erfolgreich eingeladen!</h2>
            <mat-nav-list class="login-form">
                <mat-list-item *ngFor="let participant of participants">
                    <mat-icon mat-list-icon>{{(invitedParticipants[participant.tid].status === "OK") ? 'done' : 'close'}}</mat-icon>
                    <h4 mat-line>{{invitedParticipants[participant.tid].name}}</h4>
                    <p mat-line> {{invitedParticipants[participant.tid].email}} </p>
                </mat-list-item>
            </mat-nav-list>
        </div>
    </mat-card>
</div>