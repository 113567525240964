import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { RoleService } from '../../service/role.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent  {

  private _navElements: any[];
  @Input() set navElements(value: Route[]) {
    this._navElements = value.filter(r => this.roleService.canAccess(r)).filter(r => r['data'] !== null && r['data'] !== undefined);
  }
  get navElements() {
    return this._navElements;
  }

  constructor(public roleService: RoleService, private router: Router) {}

  onNavClick(url: string) {
    this.router.navigateByUrl(url);
  }

}
