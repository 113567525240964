import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { LimesurveyParticipant } from '../models/limesurvey-participant';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LimesurveyApiClientService {

  HOST: string = environment.ls.host;
  header: { headers: HttpHeaders; };
  sessionKey: string;

  constructor(private http: HttpClient) {
    this.setHeader();
  }
  
  getSessionKey(username:string,password:string) {
    return this.query(
      "get_session_key",
      {
        username:username,
        password:password
      }
    );
  }  

  setSessionKey(sessionKey: string) {
    this.sessionKey = sessionKey;
  }

  getSurveyList(sessionKey?: string) {
    return this.query(
      "list_surveys",
      {
        sSessionKey: (!!sessionKey) ? sessionKey : this.sessionKey,
        sUsername:null
      }
    );
  }

  addParticipants(surveyID:string,participants: object[],sessionKey?: string) {
    return this.query(
      "add_participants",
      {
        sSessionKey: (!!sessionKey) ? sessionKey : this.sessionKey,
        iSurveyID: surveyID,
        aParticipantData: participants
      }
    );
  }
  
  inviteParticipants(surveyID: any, aTokenIds: String[], bEmail: boolean,sessionKey?: string) {
    return this.query(
      "invite_participants",
      {
        sSessionKey: (!!sessionKey) ? sessionKey : this.sessionKey,
        iSurveyID: surveyID,
        aTokenIds: aTokenIds,
        bEmail: bEmail
      }
    );
  }

  private query(method: string,params: any) {
    return this.http.post<any>(
      this.HOST,
      {
        method:method,
        params:params,
        id:1
      }
      , 
      this.header)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  setHeader(): void {
    this.header = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      '[Error] Limesurvey connection faild; please try again later.');
  };
}
