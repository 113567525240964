import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpsBuildingProject } from '../../../models/sps-building-project';
import { SelectionModel } from '@angular/cdk/collections';
import { ProjectApiService } from '../../../service/project-api.service';
import { Router } from '@angular/router';
import { SpsApiResponse } from '../../../models/sps-api-response';
import { CDSApiService } from '../../../service/cds-api.service';

@Component({
  selector: 'app-building-project-list',
  templateUrl: './building-project-list.component.html',
  styleUrls: ['./building-project-list.component.scss']
})
export class BuildingProjectListComponent implements OnInit {

  displayedColumns: string[] = ['std_name', 'createdon'];

  buildingProjectList = new MatTableDataSource<SpsBuildingProject>();

  selection = new SelectionModel<SpsBuildingProject>(true, []);

  snackBarDuration: number = 3;

  debugData: any = {};

  enableProgressBar: boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  constructor(
    public dialog: MatDialog, 
    private router: Router,
    public projectApiService: ProjectApiService,
    private cdsApiService: CDSApiService,
    private snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.buildingProjectList.paginator = this.paginator;
    this.buildingProjectList.sort = this.sort;

    this.loadBuildingProjecttList();
  }

  loadBuildingProjecttList(): void {
    this.enableProgressBar = true;
    
    this.cdsApiService.getAllEntity("std_bauvorhabens")
    .subscribe(
      (response: SpsApiResponse) => this.buildingProjectList.data = response.value,
      (err: any) => console.error(err),
      () => this.enableProgressBar = false
    );
  }

  btnClickCreateBuildingProject(): void {
    this.router.navigate(['/sps/buildingProject/new']);
  }

  applyFilter(filterValue) {
    this.buildingProjectList.filter = filterValue.value.trim().toLowerCase();

    if (this.buildingProjectList.paginator) {
      this.buildingProjectList.paginator.firstPage();
    }
  }
}
